<template>
  <div class="calendar-p">
    <Calendar backgroundText class-name="multi-mode" selectMode="multi" :select-date="multiModeDate" @next="next"
      :remarks="remarksObj" @prev="prev" />
  </div>
</template>

<script>
import { ref } from "vue";
import Calendar from "mpvue-calendar";
import axios from "axios";

export default {
  components: {
    Calendar
  },
  // data() {
  //   return {
  //     // multiModeDate: ref([`2023-4-1`, `2023-4-2`, `2023-4-3`])
  //     multiModeDate: ref([`2023-4-1`, `2023-4-2`, `2023-4-3`])
  //   };
  // },

  setup() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const memberId = localStorage.getItem("memberId");

    const getRandom = () => Math.floor(Math.random() * (28 - 1 + 1)) + 1;
    const arrayDate = [];

    const year = 2020;
    const month = 5;

    const multiModeDate = ref();

    const remarksObj = {
      text: `${currentYear}-${currentMonth}-${currentDay}`
    };

    getValue(currentYear, currentMonth);

    // const multiModeDate = ref([`2023-4-1`, `2023-4-2`, `2023-4-3`]);
    function next(y, m) {
      getValue(y, m);
    }
    function prev(y, m) {
      getValue(y, m);
    }

    function getValue(y, m) {
      let year = y;
      let month = m;

      let req = new FormData();
      req.append("years", year);
      req.append("month", month);

      axios
        .post(process.env.VUE_APP_GATEWAY + `/dashboard/upload-activity/${memberId}`, req, {
          headers: {
            "Content-Type": "application/json",
            APPTOKEN: "klaklikapptoken"
          }
        })
        .then((res) => {
          for (let i = 0; i < res.data.DATA.length; i++) {
            if (res.data.DATA[i].status == 1) {
              arrayDate.push(year.toString() + "-" + month.toString() + "-" + res.data.DATA[i].date);
            }
          }
          multiModeDate.value = arrayDate;
        })
        .catch((err) => { });

      let year2 = 0;
      let month2 = 0;
      if (m - 1 < 1) {
        month2 = 12;
        year2 = y - 1;
      } else {
        year2 = year;
        month2 = m - 1;
      }

      let req2 = new FormData();
      req2.append("years", year2);
      req2.append("month", month2);

      axios
        .post(process.env.VUE_APP_GATEWAY + `/dashboard/upload-activity/${memberId}`, req2, {
          headers: {
            "Content-Type": "application/json",
            APPTOKEN: "klaklikapptoken"
          }
        })
        .then((res) => {
          for (let i = 0; i < res.data.DATA.length; i++) {
            if (res.data.DATA[i].status == 1) {
              arrayDate.push(year2.toString() + "-" + month2.toString() + "-" + res.data.DATA[i].date);
            }
          }
          multiModeDate.value = arrayDate;
        })
        .catch((err) => { });
    }

    return {
      multiModeDate,
      next,
      prev,
      remarksObj
    };
  }
  // methods: {
  //   ...mapActions({
  //     fetchResDate: "summary/fetchResDate"
  //   }),
  //   ...mapMutations({
  //     setDate: "summary/setDate"
  //   }),
  //   prev(y, m) {
  //     this.getvalue(y, m);
  //   },
  //   next(y, m) {
  //     this.getvalue(y, m);
  //   },
  //   getvalue(y, m) {
  //     const year = y;
  //     const month = m;
  //     const arrayDate = [];

  //     let req = new FormData();
  //     req.append("years", year);
  //     req.append("month", month);

  //     axios
  //       .post(`https://another-subs-api-link-production.klaklik.com/gateway/dashboard/upload-activity/8`, req, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           APPTOKEN: "klaklikapptoken"
  //         }
  //       })
  //       .then((res) => {
  //         for (let i = 0; i < res.data.DATA.length; i++) {
  //           if (res.data.DATA[i].status == 1) {
  //             arrayDate.push(year.toString() + "-" + month.toString() + "-" + res.data.DATA[i].date);
  //           }
  //         }
  //         this.multiModeDate = ref(arrayDate);
  //       })
  //       .catch((err) => {});
  //   },
  //   mum() {
  //     return `2023-4-1`;
  //   }
  // },
  // computed: {
  //   ...mapState({
  //     res_date: (state) => state.summary.res_date
  //   }),
  //   ...mapGetters("summary", {
  //     res_date: "get_res_date"
  //   })
  // },
  // async created() {
  //   const data_req = { member_id: 8, years: 2020, month: 5 };
  //   const res = await this.fetchResDate(data_req);
  // }
};
</script>

<style>
@import url("./css/style.css");
</style>

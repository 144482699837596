<template>
  <div>
    <div class="nav-back-section">
      <div class="nav-create-chapter">
        <router-link :to="{ name: 'detail-novel', params: { id: novelid } }">
          <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
        </router-link>
        <p>Content</p>
        <div class="divide">/</div>
        <p>List Karya</p>
        <div class="divide">/</div>
        <p>Novel</p>
        <div class="divide">/</div>
        <p>Detail and Chapters</p>
      </div>
    </div>
    <div class="create-chapter-section">
      <h1>{{ noveltitle }}</h1>
      <form>
        <div class="form-input-chapter">
          <label for="judulChapter">Judul Chapter</label>
          <input type="text" v-model="judulChapter" id="judulChapter" placeholder="masukkan judul chapter" />
        </div>
        <div class="form-input-chapter">
          <label for="isiChapter">Isi Chapter</label>
          <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="isiChapter" placeholder="masukkan teks ..." />
        </div>
        <div class="terms-section">
          <label>
            <input type="checkbox" v-model="isChecked" />
            Saya Menyetujui <span class="span-terms" @click="redirectTerms">Terms and Conditions</span> Klaklik
          </label>
        </div>
        <button type="submit" @click.prevent="openCheckModal" class="btn-chapter-publish">Simpan</button>
        <!-- <button type="submit" @click.prevent="simpanDraft(3)" class="btn-chapter-draft">Simpan ke draft</button> -->
      </form>
      <ScheduleNovel
        :showSchedule="showSchedule"
        @close="closeModal"
        :title="noveltitle"
        :chapter="judulChapter"
        :simpanChapter="simpanChapter"
        :status="status_approve"
      >
      </ScheduleNovel>
      <CheckModal :simpanChapter="simpanChapter" :showCheckModal="showCheckModal" @close="closeModal"></CheckModal>
      <ChapterModal :errorMsg="errorMsg" :show-modal="showModal" @close="closeModal"></ChapterModal>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ChapterModal from "@/components/novel/ChapterModal.vue";
import CheckModal from "@/components/novel/CheckModal.vue";
import storage from "@/controllers/Localstorage";
import ScheduleNovel from "@/components/novel/ScheduleNovel.vue";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Create Chapter",
    };
  },
  data() {
    return {
      novelid: 0,
      noveltitle: "",
      judulChapter: "",
      isiChapter: "",
      isChecked: false,
      showModal: false,
      showSchedule: false,
      showCheckModal: false,
      errorMsg: "",
      memberId: 0,
      session: {},
      chapterlength: 0,
      status_approve: 0,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.session = storage.getLocalStorage();
    const { data } = await contentController.DetailNovel(id);
    this.memberId = data.DATA.novel_detail.member_id;

    if (parseInt(this.memberId) !== parseInt(this.session.memberId)) {
      this.$router.push("/");
    } else {
      this.noveltitle = data.DATA.novel_detail.title;
      this.novelid = data.DATA.novel_detail.id;
      this.getChapterLength();
      this.status_approve = data.DATA.novel_detail.approve;
    }

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_CREATE_CHAPTER_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Create Chapter Novel",
      page_path: "/create-chapter",
      firebase_screen_class: "CreateChapter",
      screen_class: "CreateChapter",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_CREATE_CHAPTER_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Create Chapter Novel",
      page_path: "/create-chapter",
      firebase_screen_class: "CreateChapter",
      screen_class: "CreateChapter",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async simpanChapter(approve, response) {
      if (this.status_approve == 8 || this.status_approve == 7) {
        const { data } = await contentController.StoreChapter(this.judulChapter, this.isiChapter, this.novelid, approve, response);
        if (data.STATUS == 201) {
          await contentController.ActivationNovel(this.novelid);
          this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
        }
      } else {
        await contentController.StoreChapter(this.judulChapter, this.isiChapter, this.novelid, approve, response);
        this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
      }
    },
    async setSchedule(approve) {
      await contentController.StoreChapter(this.judulChapter, this.isiChapter, this.novelid, approve);
      this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
    },
    // async simpanDraft(approve) {
    //   if (this.judulChapter == "" || this.isiChapter == "") {
    //     this.errorMsg = "Judul chapter atau Deskripsi tidak boleh kosong";
    //     this.showModal = true;
    //   } else {
    //     if (this.status_approve == 8) {
    //       const { data } = await contentController.StoreChapter(this.judulChapter, this.isiChapter, this.novelid, approve);
    //       if (data.STATUS == 200) {
    //         await contentController.ActivationNovel(this.novelid);
    //         this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
    //       }
    //     } else {
    //       await contentController.StoreChapter(this.judulChapter, this.isiChapter, this.novelid, approve);
    //     }
    //   }
    // },
    async getChapterLength() {
      const id = this.$route.params.id;
      const { data } = await contentController.ListChapter(id);
      this.chapterlength = data.DATA.chapter.length + 1;
    },
    openCheckModal() {
      if (this.judulChapter == "" || this.isiChapter == "") {
        this.errorMsg = "Judul chapter atau Deskripsi tidak boleh kosong";
        this.showModal = true;
      } else if (this.isiChapter.length < 250) {
        this.errorMsg = "Deskripsi kurang dari 250 karakter";
        this.showModal = true;
      } else if (!this.isChecked) {
        this.errorMsg = "Anda harus menyetujui syarat dan ketentuan kami untuk melanjutkan.";
        this.showModal = true;
      } else {
        this.showSchedule = true;
      }
    },
    closeModal() {
      this.showModal = false;
      this.showCheckModal = false;
      this.showSchedule = false;
    },
    redirectTerms() {
      window.open(process.env.VUE_APP_TERMSOFUSE, "_blank");
    },
  },
  components: {
    ChapterModal,
    QuillEditor,
    CheckModal,
    ScheduleNovel,
  },
};
</script>

<style>
@import url("../css/create-chapter.css");
</style>

<template>
  <div class="navdetailcs">
    <img src="../../../assets/arrow-back.png" alt="" class="nav-arrow-cs" @click="$router.go(-1)" />
    <label for="" class="nhc-titledetail">{{ chatstorydetail.title }}</label>
    <div style="width: 5%"></div>
  </div>
  <div class="detail-comic-nav">
    <div class="nav-detail-comic">
      <router-link to="/list-karya-chatstory">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>ChatStory</p>
      <div class="divide">/</div>
      <p>Detail and Chapter</p>
    </div>
  </div>
  <div class="detail-comic-section" style="margin: 0px auto 20px">
    <div class="left-detail-comic">
      <img :src="chatstorydetail.thumbnail" alt="thumbnail" />
      <p class="detail-title">{{ chatstorydetail.title }}</p>
      <router-link :to="{ name: 'edit-chat-story', params: { id: chatstoryid } }" class="detail-edit-btn">Edit Konten</router-link>
      <!-- <router-link :to="{ name: 'summary' }" class="detail-edit-btn">Edit Konten</router-link> -->
    </div>
    <div class="right-detail-comic">
      <div class="head-right">
        <p class="right-detail-title">List Chapter</p>
        <div class="right-comic-line"></div>
        <div @click="redirectStoreChapter" class="create-chapter-btn">+ Tambah Chapter Baru</div>
      </div>
      <!-- If chapter empty -->
      <div class="detail-comic-empty" v-if="listChapterStory.length < 1">
        <img src="@/assets/content-null.png" alt="" />
        <h4>Kamu belum ada Chapter nih</h4>
        <p>Upload Chapter untuk karya kamu!</p>
      </div>
      <!-- Else -->
      <div v-else class="chapterlist-cs-section">
        <div v-for="(listchapter, index) in listChapterStory" :key="listchapter.id" class="box-chapter-chatstory">
          <!-- <div class="box-imgchapter-cs">
            <img src="" alt="" />
          </div> -->
          <div class="body-box-chapter-cs">
            <div class="bcs-title">{{ listchapter.title }}</div>
            <div class="bcs-summary">
              <div class="bcs-postdate">{{ formattedDate(listchapter.created_at) }}</div>
              <div class="bcs-views">- {{ listchapter.total_views }} Views</div>
            </div>
            <div class="bcs-list-icon">
              <img src="@/assets/edit-icon.png" alt="edit" @click="redirectEdit(listchapter.id, listchapter.title)" />
              <img src="@/assets/delete-icon-chapter.png" alt="" @click="deleteEps(listchapter.id)" />
              <label for="">{{ listchapter.approve == 1 ? "Published" : listchapter.approve == 3 ? "Draft" : "Warning" }}</label>
            </div>
          </div>
          <div class="number-chapter-cs">{{ index + 1 }}</div>
        </div>
      </div>
      <div class="show-more-cs" v-if="listChapterStory.length < totalCs">
        <span @click="showMoreCS">Show More</span>
      </div>
    </div>
    <create-eps :showStoreEpsCS="showStoreEpsCS" :chatstoryid="chatstoryid" @close="closeModal"></create-eps>
  </div>
</template>

<script>
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import chatStoryController from "@/controllers/chatstory";
import CreateEps from "./components/CreateEps.vue";
import listChar from "./listChar";

export default {
  data() {
    return {
      chatstorydetail: {},
      chatstoryid: 0,
      chapterid: 0,
      listChapterStory: [],
      warnMsg: "",
      sliceEps: 3,

      limit: 3,
      offset: 0,
      totalCs: 0,

      showStoreEpsCS: false,
    };
  },
  metaInfo() {
    return {
      title: this.chatstorydetail.title ? `${this.chatstorydetail.title} | Detail ChatStory` : "Dashboard",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.chatstoryid = id;
    this.fetchDetailChatStory();
    this.fetchListEpisode();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_DETAIL_CHATSTORY,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail ChatStory",
      page_path: "/detail-chat-story",
      firebase_screen_class: "DetailChatStory",
      screen_class: "DetailChatStory",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_DETAIL_CHATSTORY,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail ChatStory",
      page_path: "/detail-chat-story",
      firebase_screen_class: "DetailChatStory",
      screen_class: "DetailChatStory",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchDetailChatStory() {
      const { data } = await chatStoryController.DetailChatStory(this.chatstoryid);
      this.chatstorydetail = data.DATA;
    },

    formattedDate(targetdate) {
      const date = new Date(targetdate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async fetchListEpisode() {
      const { data } = await chatStoryController.ListChapterChatStory(this.chatstoryid, this.limit, this.offset);
      this.listChapterStory = data.DATA;
      this.totalCs = data.TOTAL;
    },
    async fetchMoreEps() {
      this.sliceEps = this.sliceEps + 3;
      this.fetchListEpisode();
    },
    async handleDelete(id) {
      await contentController.DeleteEpisode(id);
      this.offset = 0;
      this.fetchListEpisode();
      this.showDeleteChapter = false;
    },
    openPreChapter() {
      this.showPreChapter = true;
    },
    openDeleteChapter(id) {
      this.chapterid = id;
      this.showDeleteChapter = true;
      this.warnMsg = "Apakah kamu yakin ingin menghapus episode?";
    },
    closeModal() {
      this.showPreChapter = false;
      this.showDeleteChapter = false;
      this.showStoreEpsCS = false;
    },
    redirectEdit(id, title) {
      this.$router.push("/edit-chapter-chatstory/" + this.chatstoryid + "-" + id + "-" + title);
    },
    redirectStoreChapter() {
      this.showStoreEpsCS = true;
    },
    redirectPanelEps(id) {
      this.chapterid = id;
      this.$router.push({
        name: "list-panel-eps",
        params: { id: this.chapterid },
      });
    },
    async deleteEps(id) {
      const { data } = await chatStoryController.DeleteEpisode(id);
      if (data.STATUS == 200) {
        this.fetchListEpisode();
      }
    },

    async showMoreCS() {
      this.offset = this.offset + this.limit;
      const { data } = await chatStoryController.ListChapterChatStory(this.chatstoryid, this.limit, this.offset);
      this.listChapterStory = this.listChapterStory.concat(data.DATA);
    },
  },
  components: {
    CreateEps,
  },
};
</script>

<style>
@import url("../css/detail-comic.css");

.chapterlist-cs-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 350px;
  max-height: 400px;
  margin-top: 10px;
  overflow: scroll;
}

.box-chapter-chatstory {
  width: 100%;
  border: 2px solid #8eb5db;
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.box-imgchapter-cs {
  width: 25%;
  height: 80px;
}

.box-imgchapter-cs > img {
  width: 100%;
  height: 100%;
}

.number-chapter-cs {
  background: #6c96d4;
  width: 95px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
}

.body-box-chapter-cs {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.bcs-title {
  font-size: 15px;
  color: #000;
  font-weight: 600;
}

.bcs-summary {
  display: flex;
  align-content: center;
  gap: 5px;
  font-size: 13px;
  color: #666666;
}

.bcs-list-icon {
  display: flex;
  align-content: center;
  gap: 5px;
}

.bcs-list-icon > img {
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.bcs-list-icon > label {
  background: #8eb5db;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
}

.show-more-cs {
  display: flex;
  justify-content: center;
}

.show-more-cs > span {
  color: #000;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}
</style>

<template>
  <div class="list-content-section">
    <div class="c__head">
      <div class="c__head-title">
        <h4>{{ res_summary.total_views_content }}</h4>
        <p>Total Viewers</p>
      </div>
      <div class="c__head-line"></div>
      <p class="c__head-desc">{{ dateTime }}</p>
    </div>
    <div class="data-tables-section">
      <div class="data__title">
        <p>List Karya Comic</p>
      </div>
      <div class="head-table-section">
        <div class="lc_date-filter">
          <form @submit.prevent="">
            <p class="label-date">Urutkan berdasarkan</p>
            <select v-model="rangeDay">
              <option value="1">Terbaru</option>
              <option value="0">Terlama</option>
            </select>
          </form>
        </div>
        <div class="input-search-table">
          <p class="label-search">Cari</p>
          <input type="text" placeholder="search here.." v-model="searchInput" />
        </div>
      </div>
      <div class="lc-table-section">
        <div class="cc_table-body">
          <table>
            <thead>
              <tr>
                <th>Last Update</th>
                <th>Thumbnail</th>
                <th>Judul</th>
                <th>Episode</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody v-if="filteredComicList == 0">
              <tr>
                <td colspan="6" style="text-align: center">No records found.</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="items in filteredComicList" :key="items.id">
                <td>{{ items.created_at }}</td>
                <td><img :src="items.thumbnail" alt="thumbnail" /></td>
                <td>{{ items.title }}</td>
                <td>{{ items.total_chapter }}</td>
                <td style="text-transform: capitalize">{{ items.approve }}</td>
                <td>
                  <router-link :to="{ name: 'detail-comic', params: { id: items.id } }" class="detail-content"> Detail </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pages-section" v-if="listcomic !== 0">
          <p class="pages">{{ curentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="curentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="curentPage === 1">&lt; Sebelumnya</button>
            <button :class="curentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="curentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import storage from "@/controllers/Localstorage";
import contentController from "@/controllers/content";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({ title: "List Karya Comic" });
  },
  data() {
    return {
      session: {},
      rangeDay: 1,
      listcomic: [],
      searchInput: "",
      offset: 0,
      curentPage: 1,
      totalData: 0,
    };
  },
  async created() {
    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_LIST_CONTENT_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard ListContent Comic",
      page_path: "/table-comic",
      firebase_screen_class: "KaryaComic",
      screen_class: "KaryaComic",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_LIST_CONTENT_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard ListContent Comic",
      page_path: "/table-comic",
      firebase_screen_class: "KaryaComic",
      screen_class: "KaryaComic",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      await this.fetchSummary({
        member_id: this.session.memberId,
        usertoken: this.session.usertoken,
        // range_day: this.rangeDay,
      });

      this.res_summary;
    }

    this.fetchComic();
  },
  methods: {
    async fetchComic() {
      const { data } = await contentController.ListComic(this.session.memberId, this.rangeDay, this.offset);
      this.listcomic = data.DATA;
      this.totalData = data.TOTAL;
    },

    async searchContent() {
      if (this.searchInput === "") {
        this.curentPage = 1;
        await this.fetchComic();
      } else {
        this.offset = 0;
        const { data } = await contentController.ListComicSearch(this.session.memberId, this.searchInput, 5, 0);
        this.listcomic = data.DATA;
        this.totalData = data.TOTAL;

        this.curentPage = 1;
      }
    },

    async nextPage() {
      if (this.searchInput != "") {
        this.offset += 5;
        const { data } = await contentController.ListComicSearch(this.session.memberId, this.searchInput, 5, this.offset);
        this.listcomic = data.DATA;
        this.totalData = data.TOTAL;
        this.curentPage++;
      } else {
        this.offset += 5;
        await this.fetchComic();

        this.curentPage++;
      }
    },

    async prevPage() {
      this.offset -= 5;
      if (this.searchInput != "") {
        const { data } = await contentController.ListComicSearch(this.session.memberId, this.searchInput, 5, this.offset);
        this.listcomic = data.DATA;
        this.totalData = data.TOTAL;

        this.curentPage--;
      } else {
        await this.fetchComic();
        this.curentPage--;
      }
    },

    ...mapActions({
      fetchSummary: "summary/fetchSummary",
    }),
  },
  computed: {
    filteredComicList() {
      return this.listcomic.filter((item) => item.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
    totalPages() {
      return Math.ceil(this.totalData / 5);
    },
    ...mapState({
      summary: (state) => state.summary.res_summary,
      rangeDay: (state) => state.rangeDay,
    }),
    ...mapGetters("summary", {
      res_summary: "get_res_summary",
    }),
    ...mapGetters("user", {
      user: "get_user",
    }),

    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
  },
  watch: {
    searchInput() {
      this.searchContent();
    },
    rangeDay(newVal, oldVal) {
      this.fetchComic();
      this.curentPage = 1;
    },
  },
};
</script>

<style scoped>
.cc_table-body {
  padding: 10px;
  font-size: 14px;
  background-color: #f7fafc;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #8eb5db;
  color: #ffffff;
}

th {
  padding: 10px;
}

td {
  border-bottom: 2px solid #e4e4e4;
}

th,
td {
  text-align: center;
  padding: 10px;
}

td > img {
  width: 40px;
}

@media (min-width: 360px) and (max-width: 413px) {
  .cc_table-body {
    width: 100%;
    overflow-x: scroll;
  }
}
@import url("../css/list-content.css");
</style>

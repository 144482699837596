import Vuex from "vuex";
import summary from "./modules/summary";
import earning from "./modules/earning";
import history from "./modules/earning";
import followers from "./modules/followers";
import user from "./modules/usertoken";
import chatstory from "./modules/chatstory";

export default new Vuex.Store({
  modules: {
    summary,
    earning,
    followers,
    history,
    user,
    chatstory,
  },
  state: {
    // = data
  },

  getters: {
    // = computed properties
  },

  actions: {
    // action
  },

  mutations: {},
});

import summaryController from "@/controllers/summary";

export default {
  namespaced: true,
  state: {
    res_summary: {},
    res_date: {},
    rangeDay: 1,
  },

  getters: {
    get_res_summary: (state) => state.res_summary,
    get_res_date: (state) => state.res_date,
  },

  mutations: {
    setSummary(state, res_summary) {
      state.res_summary = res_summary;
    },
    setDate(state, res_date) {
      state.res_date = res_date;
    },
  },
  actions: {
    async fetchSummary({ commit }, data_req) {
      const res = await summaryController.getSummary(
        data_req.member_id,
        data_req.usertoken,
        data_req.range_day
      );
      commit("setSummary", res);
    },

    async fetchResDate({ commit }, data_req) {
      const res = await summaryController.getCalendar(
        data_req.member_id,
        "ltmbymoibhfd284937e8dac37ab2d7daead66",
        data_req.years,
        data_req.month
      );
      commit("setDate", res);
    },
  },
};

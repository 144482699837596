<template>
  <div class="nav-back-section">
    <div class="nav-create-chapter">
      <router-link :to="{ name: 'update-episode', params: { id: episodeid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Comic</p>
      <div class="divide">/</div>
      <p>Preview Panel</p>
    </div>
  </div>
  <div class="preview-episode-section">
    <div v-for="(item, index) in listPanel" :key="index" class="preview-panel-item">
      <img :src="item.file" alt="" />
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import storage from "@/controllers/Localstorage";

export default {
  metaInfo() {
    return {
      title: "Preview Panel",
    };
  },
  data() {
    return {
      episodeid: 0,
      session: {},
      listPanel: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.episodeid = id;
    this.session = storage.getLocalStorage();
    this.getListPanel();
  },
  methods: {
    async getListPanel() {
      const { data } = await contentController.GetListPanel(this.episodeid, this.session.usertoken);
      this.listPanel = data;
    },
  },
};
</script>

<style>
@import url("./css/list-panel-eps.css");
</style>

<template>
  <div class="detail-comic-nav">
    <div class="nav-detail-comic">
      <router-link to="/table-comic">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Comic</p>
      <div class="divide">/</div>
      <p>Detail and Episode</p>
    </div>
  </div>
  <div class="detail-comic-section">
    <div class="left-detail-comic">
      <img :src="comicdetail.thumbnail" alt="thumbnail" />
      <p class="detail-title">{{ comicdetail.title }}</p>
      <router-link :to="{ name: 'edit-comic', params: { id: comicid } }" class="detail-edit-btn">Edit Konten</router-link>
      <div class="analytics-direct">
        <img src="@/assets/stats-symbol.png" alt="" />
        <p>Lihat Analytics Title</p>
        <router-link :to="{ name: 'analytic-comic', params: { id: comicid } }" class="btn-analytics-comic">Lihat Detail</router-link>
      </div>
    </div>
    <div class="right-detail-comic">
      <div class="head-right">
        <p class="right-detail-title">List Episode</p>
        <div class="right-comic-line"></div>
        <div @click="redirectStoreChapter" class="create-chapter-btn">+ Tambah Episode Baru</div>
      </div>
      <!-- If chapter empty -->
      <div class="detail-comic-empty" v-if="list_episode.length < 1">
        <img src="@/assets/content-null.png" alt="" />
        <h4>Kamu belum ada Episode nih</h4>
        <p>Upload Episode untuk karya kamu!</p>
      </div>
      <!-- Else -->
      <div class="list-detaileps-section" v-else>
        <div v-for="episode in list_episode.slice(0, sliceEps)" :key="episode.id" class="box-chapter-comic">
          <div class="box-left-chapter-c">
            <img :src="episode.thumbnail" alt="" />
          </div>
          <div class="box-right-chapter-c">
            <p>{{ episode.title }}</p>
            <div class="update-views">
              <p>{{ episode.price }} Coin</p>
              -
              <span>{{ episode.total_views }} Views</span>
            </div>
            <div class="box-line-c"></div>
            <div class="box-right-action-c">
              <p :style="episode.approve === 0 ? 'background-color: #EAAD51' : episode.approve === 3 ? '#FF1239' : ''">
                {{ episode.approve == 0 ? "pending" : episode.approve == 1 ? "publish" : episode.approve == 2 ? "reject" : "pending" }}
              </p>
              <div class="right-action-edit-c" @click="redirectEdit(episode.id)">
                <img src="@/assets/pencil-white.png" alt="" />
                <label for="">Edit & Tambah Konten</label>
              </div>
              <img @click="openDeleteChapter(episode.id)" src="@/assets/trash-comic.png" alt="" />
            </div>
          </div>
        </div>
        <div class="load-more-eps" v-if="list_episode.length > 3 && sliceEps < list_episode.length" @click="fetchMoreEps()">Show More</div>
      </div>
    </div>
    <PreChapterModal :redirectStoreChapter="redirectStoreChapter" :showPreChapter="showPreChapter" @close="closeModal"> </PreChapterModal>
    <ModalDeleteChapter
      :handleDelete="handleDelete"
      :chapterid="chapterid"
      :showDeleteChapter="showDeleteChapter"
      @close="closeModal"
      :warnMsg="warnMsg"
    ></ModalDeleteChapter>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import PreChapterModal from "@/components/novel/PreChapterModal.vue";
import ModalDeleteChapter from "@/components/ModalDeleteChapter.vue";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  data() {
    return {
      comicdetail: {},
      comicid: 0,
      chapterid: 0,
      list_episode: [],
      showPreChapter: false,
      showDeleteChapter: false,
      warnMsg: "",
      sliceEps: 3,
    };
  },
  metaInfo() {
    return {
      title: this.comicdetail.title ? `${this.comicdetail.title} | Detail Comic` : "Dashboard",
    };
  },
  async mounted() {
    const id = this.$route.params.id;

    const { data } = await contentController.DetailComic(id);
    this.comicid = data.DATA.id;
    this.comicdetail = data.DATA;
    this.fetchListEpisode();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_DETAIL_CONTENT_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Comic",
      page_path: "/detail-comic",
      firebase_screen_class: "DetailComic",
      screen_class: "DetailComic",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_DETAIL_CONTENT_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Comic",
      page_path: "/detail-comic",
      firebase_screen_class: "DetailComic",
      screen_class: "DetailComic",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchMoreEps() {
      this.sliceEps = this.sliceEps + 3;
      this.fetchListEpisode();
    },
    async fetchListEpisode() {
      const { data } = await contentController.ListEpisode(this.comicid);
      this.list_episode = data;
    },
    async handleDelete(id) {
      await contentController.DeleteEpisode(id);
      this.offset = 0;
      this.fetchListEpisode();
      this.showDeleteChapter = false;
    },
    openPreChapter() {
      this.showPreChapter = true;
    },
    openDeleteChapter(id) {
      this.chapterid = id;
      this.showDeleteChapter = true;
      this.warnMsg = "Apakah kamu yakin ingin menghapus episode?";
    },
    closeModal() {
      this.showPreChapter = false;
      this.showDeleteChapter = false;
    },
    redirectEdit(id) {
      this.$router.push({ name: "update-episode", params: { id: id } });
    },
    redirectStoreChapter() {
      this.$router.push({
        name: "store-episode",
        params: { id: this.comicid },
      });
    },
    redirectPanelEps(id) {
      this.chapterid = id;
      this.$router.push({
        name: "list-panel-eps",
        params: { id: this.chapterid },
      });
    },
  },
  components: {
    PreChapterModal,
    ModalDeleteChapter,
  },
};
</script>

<style>
@import url("../css/detail-comic.css");
</style>

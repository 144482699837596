<template>
    <div class="modal" v-if="showDeleteChapter">
        <div class="modal-delete-chapter">
            <div class="modal-delete-head">
                <p>{{ warnMsg }}</p>
            </div>
            <div class="modal-delete-body">
                <button @click="$emit('close')">Batal</button>
                <button @click="handleDelete(chapterid)">Hapus</button>
                <!-- <button @click="handleDelete(chapterid)">Hapus</button> -->
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    name: "show-barcode",
    props: {
        showDeleteChapter: {
            type: Boolean,
            default: false
        },
        warnMsg: {
            type: String,
            default: ''
        },
        handleDelete: {
            type: Function,
            required: true
        },
        chapterid: {
            type: Number,
            required: true
        }
    },
}
</script>
    
<style>
@import url('./css/modal-delete-chapter.css');
</style>
  
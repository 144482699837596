import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueCookies from "vue-cookies";
import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";

const metaManager = createMetaManager();

const app = createApp(App);
app.use(store).use(router);
app.use(Toastify);
app.use(metaManager);
app.use(vueMetaPlugin, { keyName: "metaInfo" });
app.use(VueCookies, { expire: "1y" });
app.mount("#app");

<template>
  <div class="not-affiliate" v-if="checkAgent.member_is_agent === false">
    <div class="img-not-affiliate">
      <img src="@/assets/not-affiliate.png" alt="" />
    </div>
    <div class="desc-not-affiliate">
      <div class="head-desc-na">
        <h4>Hi, {{ user.USERNAME }}</h4>
        <h4>Kamu belum terdaftar sebagai affiliated creator.</h4>
      </div>
      <div class="body-desc-na">
        <p>Kamu bisa hubungi admin untuk informasi lebih detail mengenai Klaklik affiliated creator dengan email ke alamat berikut :.</p>
        <p style="font-weight: 500">klaklik.developer@gmail.com</p>
        <p style="font-weight: 500">klaklik.id@gmail.com</p>
      </div>
      <!-- <div class="btn-desc-na">
                <button>Hubungi Admin <img src="@/assets/phone.png" alt=""></button>
            </div> -->
    </div>
  </div>
  <!-- If was an agent -->
  <div class="affiliate" v-else>
    <div class="af-left">
      <div class="af-left-profile">
        <div class="af-left-profile-top">
          <div class="profile-img">
            <img :src="user.MEMBER_IMAGE" alt="" />
          </div>
          <div class="profile-name">
            <h4>{{ user.FIRSTNAME }} {{ user.LASTNAME }}</h4>
            <div class="line"></div>
            <p>Community</p>
          </div>
        </div>
        <div class="af-left-profile-bot">
          <h4>Estimasi Pendapatan</h4>
          <span>{{ res_earning.estimasi_earning }}</span>
          <div class="line"></div>
          <p>{{ dateTime }}</p>
        </div>
      </div>
      <div class="af-left-wallet">
        <h4>Pendapatan yang bisa ditarik</h4>
        <h2>{{ nominal }}</h2>
        <p>Penarikan dapat dilakukan dengan minimal pendapatan Rp.200.000</p>
        <div class="line"></div>
        <div class="btn-af-left-wallet" @click="handleWithdraw">
          <span>Tarik Pendapatan</span>
          <img src="@/assets/w__tarik-icon.png" alt="" />
        </div>
        <div class="af-left-withdraw" @click="handleWithdraw">Lihat History Penarikan</div>
      </div>
      <WalletWarnModal :show-modal="showModal" @close="closeModal"></WalletWarnModal>
    </div>
    <div class="af-right">
      <div class="af-right-member">
        <h4>List Member</h4>
        <div class="af-right-memberlist">
          <div class="af-right-memberlist-item" v-for="agent in listAgent" :key="agent.id">
            <div class="af-member-item-img">
              <img :src="agent.member_image" alt="" />
            </div>
            <div class="af-member-item-desc">
              <h4>{{ agent.name }}</h4>
              <p>{{ agent.total_content_earning }} Karya</p>
              <span>{{ agent.total_earning }}</span>
            </div>
          </div>
        </div>
        <div class="af-right-memberlist-btn" @click="redirectListMember">
          <span>Lihat Seluruh List</span>
          <img src="@/assets/user-list.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/controllers/Localstorage";
import { mapGetters } from "vuex";
import walletController from "@/controllers/wallet";
import WalletWarnModal from "@/components/wallet/WalletWarnModal.vue";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Klaklik | Affiliate",
    };
  },
  data() {
    return {
      session: {},
      listAgent: {},
      username: "",
      checkAgent: "",
      nominal: 0,
      myWallet: false,
      affiliate: true,
      showModal: false,
      memberid: 92434,
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    this.username = this.session.username;
    const { data } = await walletController.CheckMemberAgent(this.session.memberId);
    this.checkAgent = data.DATA;
    this.ListMemberAgent();
    this.fetchNominal();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Affiliate",
      page_path: "/wallet",
      firebase_screen_class: "Affiliate",
      screen_class: "Affiliate",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Affiliate",
      page_path: "/wallet",
      firebase_screen_class: "Affiliate",
      screen_class: "Affiliate",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchNominal() {
      const { data } = await walletController.getNominalWithdraw(this.session.memberId, this.session.usertoken);
      this.nominal = data.DATA.toLocaleString("id-ID", { style: "currency", currency: "IDR" });
    },
    async ListMemberAgent() {
      const { data } = await walletController.ListMemberAgent(this.session.memberId, 5, 0);
      this.listAgent = data.DATA;
    },
    redirectListMember() {
      this.$router.push({ name: "list-member-affiliate" });
    },
    handleWithdraw() {
      // let earning = parseInt(this.res_earning.estimasi_earning.replace(/[^\d]/g, ''));
      // if (earning <= 200000) {
      //     this.showModal = true
      // } else {
      //     window.location.href = process.env.VUE_APP_WITHDRAW
      // }
      window.open(process.env.VUE_APP_WITHDRAW, "_blank");
    },
    closeModal() {
      this.showModal = false;
    },
  },
  computed: {
    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric", timeZoneName: "short" };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
    ...mapGetters("earning", {
      res_earning: "get_res_earning",
    }),
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
  components: {
    WalletWarnModal,
  },
};
</script>

<style>
@import url("./css/affiliate.css");
</style>

<template>
  <div class="preview-chapter-section">
    <div class="nav-back-section">
      <div class="nav-edit-chapter">
        <div @click="$router.go(-1)" style="cursor: pointer">
          <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
        </div>
        <p>Content</p>
        <div class="divide">/</div>
        <p>List Karya</p>
        <div class="divide">/</div>
        <p>Novel</p>
        <div class="divide">/</div>
        <p>Preview Chapter</p>
      </div>
    </div>
    <div class="preview-chapter-content">
      <div class="copy-preview-chapter" @click="copyText">
        <span>Salin</span>
        <img src="@/assets/copy-text.svg" alt="" />

        <div v-if="isCopied" class="copy-popup">Tersalin</div>
      </div>
      <div ref="previewContent">
        <div class="preview-chapter-head">
          <div class="preview-chapter-title">
            {{ detailChapter.title }}
          </div>
        </div>

        <div class="preview-chapter-desc">
          <p v-html="detailChapter.desc"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";

export default {
  data() {
    return {
      detailChapter: [],
      novelid: 0,
      isCopied: false,
    };
  },
  metaInfo() {
    return {
      title: this.detailChapter.title ? `${this.detailChapter.title} | Preview Chapter` : "Dasbboard",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    const { data } = await contentController.DetailChapter(id);
    this.detailChapter = data.DATA.novel_content;
    this.novelid = data.DATA.novel_content.novel_id;
  },
  methods: {
    copyText() {
      const previewContent = this.$refs.previewContent;
      const range = document.createRange();
      range.selectNodeContents(previewContent);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();

      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },
  },
};
</script>

<style>
.preview-chapter-section {
  width: 100%;
}
.preview-chapter-content {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 64px;
  margin-bottom: 100px;
  border-radius: 60px 60px 40px 40px;
  background: #fff;
  box-shadow: 6px -3px 14px 0px rgba(0, 0, 0, 0.25);
  min-height: 800px;
  position: relative;
}

.preview-chapter-head {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.preview-chapter-title {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 70%;
}

.copy-preview-chapter {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #8eb5db;
  border-radius: 5px;
  background: #8eb5db;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 99;
}

.copy-preview-chapter > img {
  width: 20px;
}

.copy-preview-chapter > span {
  font-size: 15px;
  color: #fff;
}

.copy-popup {
  position: absolute;
  top: 40px;
  right: 0px;
  color: #fff;
  background-color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 720px) {
  .preview-chapter-section {
    padding-top: 10px;
  }

  .preview-chapter-content {
    width: 100%;
    padding: 20px;
    border-radius: 30px 30px 0px 0px;
    margin-bottom: 10px;
  }

  .copy-preview-chapter {
    right: 20px;
    z-index: 99;
  }

  .copy-preview-chapter > span {
    display: none;
  }

  .copy-preview-chapter > img {
    width: 18px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .preview-chapter-content {
    width: 90%;
  }

  .nav-back-section {
    width: 90%;
  }
}
</style>

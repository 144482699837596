<template>
  <div class="wrapper-mcs">
    <div class="naveditsectioncs">
      <div class="navdetailcsedit">
        <img src="../../../assets/arrow-back.png" alt="" class="nav-arrow-cs" @click="$router.go(-1)" />
        <label for="" class="nhc-titledetail">{{ epsTitle }}</label>
        <div style="width: 5%"></div>
      </div>
      <div class="list-char-mb-cs ecs">
        <button class="ecs-hamburger" @click="toggleNav" :class="{ 'is-active': isOpen }">
          <template v-if="isOpen"> ✕ </template>
          <template v-else> ☰ </template>
        </button>

        <div class="m-selected-char" v-if="!naratorSelected">
          <div class="left-m-char">
            <div class="m-char-img">
              <img :src="sel.avatar" />
              <img src="@/assets/chatstory/orange-edit.png" alt="" style="cursor: pointer" @click="openModalChar('edit', sel.id)" />
            </div>
            <div class="m-char-descinfo">
              <div class="m-name-descinfo" style="color: #fff; font-weight: 600">{{ sel.nama_karakter }}</div>
              <div class="m-desc-descinfo" style="color: #fff; font-weight: 400">{{ sel.status_receiver == 0 ? "Pengirim" : "Penerima" }}</div>
            </div>
          </div>
          <img src="@/assets/chatstory/switch-icon.png" alt="" class="char-switch" @click="openModalSwitch(sel.id, sel.status_receiver)"/>
        </div>
        <div class="m-selected-char" v-else>
          <div class="left-m-char">
            <div class="mchar-nara-profile">
              <img src="@/assets/chatstory/narator.png" alt="" />
              <div class="mchar-nara-name" style="color: #fff; font-weight: 600">Narator</div>
              <div class="mchar-nara-desc" style="color: #fff; font-weight: 400">Narasi</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mcs__nav" :class="{ 'is-open': isOpen }">
      <div class="list-char-nav">
        <div class="m-char-nav" v-for="(item, index) in filteredCharacters" :key="item.id">
          <div class="left-m-char">
            <div class="m-char-img">
              <img :src="item.avatar" />
              <img src="@/assets/chatstory/orange-edit.png" alt="" style="cursor: pointer" @click="openModalChar('edit', item.id)" />
            </div>
            <div class="m-char-descinfo" @click="toggleItem(item, index)">
              <div class="m-name-descinfo">{{ item.nama_karakter }}</div>
              <div class="m-desc-descinfo">{{ item.status_receiver == 0 ? "Pengirim" : "Penerima" }}</div>
            </div>
          </div>
          <img src="@/assets/chatstory/switch-icon.png" alt="" class="char-switch" @click="openModalSwitch(item.id, item.status_receiver)"  />
        </div>
        <div class="mchar-nara" @click="addNarator" v-if="!naratorSelected">
          <div class="mchar-nara-profile">
            <img src="@/assets/chatstory/narator.png" alt="" />
            <div class="mchar-nara-name">Narator</div>
            <div class="mchar-nara-desc">Narasi</div>
          </div>
        </div>
      </div>
      <button class="btn-add-cs" @click="openModalChar('add')"><img src="@/assets/chatstory/plus-white.png" alt="" />Tambah Karakter</button>
    </div>
    <div class="chat-editsection" ref="boxChat" @scroll="debouncedScrollMobile">
      <div class="scs-boxchat">
        <div class="cs-item" v-for="item in listChatContent" :key="item.id">
          <div
            :class="item.status_receiver == '0' ? 'right' : 'left'"
            v-if="(item.status_receiver != '0' && item.character_id != 0) || (item.status_receiver != '1' && item.character_id != 0)"
          >
            <div class="chat-profileimg">
              <img :src="item.avatar" alt="" class="chat-profileimg" />
            </div>
            <div class="cs-item-wrap">
              <label
                for=""
                class="cs-item-name"
                :style="item.status_receiver == '0' ? 'align-self: self-end; padding-right: 6px' : 'padding-left: 6px'"
                >{{ item.nama_karakter }}</label
              >
              <div :class="item.status_receiver == '0' ? 'icon-right' : 'icon-left'">
                <p :class="item.status_receiver == '0' ? 'cs-item-sender' : 'cs-item-other'" v-if="item.image == ''">{{ item.desc }}</p>
                <div v-else :class="item.status_receiver == '0' ? 'cs-item-sender' : 'cs-item-other'">
                  <img :src="item.image" alt="" class="cs-item-img" />
                  <label for="">{{ item.desc }}</label>
                </div>
                <div class="cs-options">
                  <img src="@/assets/pencil-comic-icon.png" alt="" @click="openModalChar('editMsg', item.id)" />
                  <img src="@/assets/chatstory/trash-red.png" alt="" @click="deleteMessage(item.id)" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="cs-item-narator">
            {{ item.desc }}
            <img src="@/assets/pencil-comic-icon.png" alt="" class="cs-icon-narator" />
            <img src="@/assets/chatstory/trash-red.png" alt="" @click="deleteMessage(item.id)" class="cs-icon-delnar" />
          </div>
        </div>
        <div class="loader-chat" style="margin: auto" v-if="isLoading">Loading ...</div>
      </div>
    </div>
    <div class="mcs-footer-section">
      <div class="mcs-footer-wrap">
        <div class="mcs-footer-inputwrp">
          <input type="text" class="mcs-footer-input" v-model="message" placeholder="Tulis Pesan" />
          <img src="@/assets/chatstory/plus-upload.png" alt="" @click="openModalChar('addImg')" />
          <img src="@/assets/chatstory/send-btn-mbl.png" alt="" @click="sendMessage" />
        </div>
        <!-- <div class="mcs-bubble-section">
          <div class="list-bubble-mood">
            <div
              class="bubble-item"
              v-for="(bubble, index) in listBubble"
              :key="bubble.id"
              :class="bubble.isSelected ? '' : ''"
              @click="toggleMood(bubble, index)"
            >
              <img src="@/assets/chatstory/selected.png" alt="" v-if="bubble.isSelected" class="selected-iconbubble" />
              <img :src="bubble.image" alt="" class="img-bubble" />
              <label for="" :style="bubble.isSelected ? 'color: #63D5CE; font-weight: bold' : ''">{{ bubble.nama }}</label>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="scs-chapter-price">
        <div class="scs-list-price">
          <span class="wrapper-price">
            <img src="@/assets/coin.png" alt="" class="KPoin-img" />
            <input class="kpoin-price-input" type="text" id="price" name="price-koin"
          /></span>
          <span class="wrapper-free">
            <p>Gratiskan Chapter</p>
            <input class="kpoin-free-check" type="checkbox" id="free" name="free-chapter" value="Gratis" @change="disableInputPrice" />
          </span>
        </div>
      </div> -->
      <div class="scs-list-submit">
        <button @click="submitEpisode('publish')">
          <img src="@/assets/chatstory/send-white.png" alt="" class="send-img" /> <span>Publikasikan</span>
        </button>
        <button @click="submitEpisode('draft')">
          <img src="@/assets/chatstory/draft-icon.png" alt="" class="draft-img" /> <span>Simpan Draft</span>
        </button>
      </div>
    </div>
    <add-char :showAddChar="showAddChar" @close="closeModal" :chatstoryid="chatstoryid" :getListChar="getListChar"></add-char>
    <edit-char
      :showEditChar="showEditChar"
      @close="closeModal"
      :charid="charid"
      :getListChar="getListChar"
      :charData="charData"
      :chatstoryid="chatstoryid"
    ></edit-char>
    <add-img :showAddImg="showAddImg" @close="closeModal" :sendImageMessage="sendImageMessage"></add-img>
    <edit-msg :showEditMsg="showEditMsg" @close="closeModal" :detailContentChat="detailContentChat"></edit-msg>
  </div>
</template>

<script>
import chatstoryController from "@/controllers/chatstory";
import listBubble from "./listBubble";
import AddChar from "./components/AddChar.vue";
import EditChar from "./components/EditChar.vue";
import AddImg from "./components/AddImg.vue";
import EditMsg from "./components/EditMsg.vue";
import { debounce } from "lodash";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      //AUX STATE ECS
      isOpen: false,

      //Modal State
      showAddChar: false,
      showEditChar: false,
      showEditMsg: false,
      showAddImg: false,
      showSwitchModal: false,

      //Data
      chatstoryid: 0,
      epsid: 0,
      charid: 0,
      session: {},
      character: [],
      listChar: [],
      charData: {},
      listBubble: [],
      message: "",
      epsTitle: "",
      chat_image: "",
      detailContentChat: {},
      isLoading: false,
      isMobile: false,

      //Character Selected
      selectedItems: null,
      selectedItemIndex: null,
      naratorSelected: false,

      // Mood Bubble Selected
      selectedMood: null,
      selectedMoodIndex: null,
      sel: {},
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.chatstoryid = parseInt(id.split("-")[0], 10);
    this.epsid = parseInt(id.split("-")[1], 10);

    this.getListChar();
    this.getListChatContent();
    this.getDetailEps();

    // Mood Section
    const newListBubble = listBubble.map((item) => ({
      ...item,
      isSelected: this.selectedMoodIndex === item.id,
    }));
    this.listBubble.push(...newListBubble);
    this.selectedMood = this.listBubble[0];
    this.selectedMood.isSelected = true;
  },

  methods: {
    disableInputPrice() {
      if (document.getElementById("free").checked) {
        document.getElementById("price").classList.add("disable_section");
      } else {
        document.getElementById("price").classList.remove("disable_section");
      }
    },
    // FUNCTION CHAT START
    async getListChatContent() {
      let data_req = {
        eps_id: this.epsid,
        limit: this.limit,
        offset: 0,
      };
      await this.fetchListChat(data_req);
    },
    openModalSwitch(id, status_receiver) {
      console.log(id);
      console.log(status_receiver);
      this.changeReceiver(id, status_receiver);
      // this.showSwitchModal = true;
    },

    async changeReceiver(id,status_receiver) {
      const { data } = await chatstoryController.EditSender(id,status_receiver);
      if (data.STATUS == 200) {
        this.getListChar();
      }
    },

    async handleStoreUnscrolled() {
      let data_req = {
        eps_id: this.epsid,
        limit: 0,
        offset: 0,
      };
      const res = await this.handleFetchChatUnscrolled(data_req);
      if (res.data.STATUS == 200) {
        this.message = "";

        this.$nextTick(() => {
          const boxChat = this.$refs.boxChat;
          boxChat.scrollTo({
            top: boxChat.scrollHeight,
            behavior: "smooth",
          });
        });
      }
    },
    async getDetailEps() {
      const { data } = await chatstoryController.DetailEpisode(this.epsid);
      this.epsTitle = data.DATA.title;
    },

    async loadMoreData() {
      if (this.listChatContent.length == this.totalData) {
        return;
      } else {
        try {
          this.isLoading = true;
          const newOffset = this.offset + this.limit;
          this.updateOffset(newOffset);
          let data_req = {
            eps_id: this.epsid,
            limit: this.limit,
            offset: newOffset,
          };
          this.loadMoreChat(data_req);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.error("Error fetching more data:", error);
        }
      }
    },
    debouncedScrollMobile: debounce(function () {
      const boxChat = this.$refs.boxChat;
      const scrollTop = boxChat.scrollTop;
      const scrollHeight = boxChat.scrollHeight;
      const clientHeight = boxChat.clientHeight;

      const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrolledPercentage >= 95 && this.offset < this.totalData) {
        this.loadMoreData();
      } else {
        return;
      }
    }, 200),

    async sendMessage() {
      if (this.naratorSelected) {
        if (this.listChatContent.length < this.totalData) {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            0,
            this.selectedMood.id,
            this.message,
            0,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
              character_id: data.DATA.character_id,
            };

            this.handleStoreUnscrolled(newMessage);
          }
        } else {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            0,
            this.selectedMood.id,
            this.message,
            0,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
              character_id: data.DATA.character_id,
            };

            this.$store.commit("chatstory/addMessage", newMessage);
            this.message = "";
            this.$nextTick(() => {
              const boxChat = this.$refs.boxChat;
              boxChat.scrollTo({
                top: boxChat.scrollHeight,
                behavior: "smooth",
              });
            });
          }
        }
      } else {
        if (this.listChatContent.length < this.totalData) {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            this.selectedItems.id,
            this.selectedMood.id,
            this.message,
            this.selectedItems.status_receiver,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
            };

            this.handleStoreUnscrolled(newMessage);
          }
        } else {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            this.selectedItems.id,
            this.selectedMood.id,
            this.message,
            this.selectedItems.status_receiver,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
            };

            this.$store.commit("chatstory/addMessage", newMessage);
            this.message = "";
            this.$nextTick(() => {
              const boxChat = this.$refs.boxChat;
              boxChat.scrollTo({
                top: boxChat.scrollHeight,
                behavior: "smooth",
              });
            });
          }
        }
      }
    },

    async sendImageMessage(item) {
      const { data } = await chatstoryController.StoreChatCS(
        this.chatstoryid,
        this.epsid,
        this.selectedItems.id,
        this.selectedMood.id,
        item.captionMsg,
        this.selectedItems.status_receiver,
        item.msgImage //image
      );
      if (data.STATUS == 201) {
        this.getListChatContent();
      }
    },
    async deleteMessage(msgid) {
      const { data } = await chatstoryController.DeleteChatCS(msgid);
      if (data.STATUS == 200) {
        this.$store.commit("chatstory/removeMessage", msgid);
      }
    },

    // FUNCTION CHAT END

    toggleNav() {
      this.isOpen = !this.isOpen;
    },

    async getListChar() {
      const { data } = await chatstoryController.ListChar(this.chatstoryid);
      this.listChar = data.DATA;

      if (this.listChar.length > 0) {
        const newListChar = this.listChar.map((item) => ({
          ...item,
          isSelected: this.selectedItemIndex === item.id,
        }));
        this.character = [];
        this.character.push(...newListChar);
        this.selectedItems = this.character[0];
        this.selectedItems.isSelected = true;
        this.sel = this.character[0];
      }
    },
    async getDetailChar() {
      const { data } = await chatstoryController.DetailChar(this.charid);
      if (data.STATUS == 200) {
        this.charData = data.DATA;
      }
    },
    toggleItem(item, index) {
      this.selectedItemIndex = index;
      this.selectedItems = item;
      this.naratorSelected = false;
      this.sel = item;

      this.isOpen = false;
      this.character.forEach((listItem, i) => {
        listItem.isSelected = this.selectedItemIndex === i;
      });
    },
    closeModal() {
      this.showAddChar = false;
      this.showEditChar = false;
      this.showEditMsg = false;
      this.showAddImg = false;
      this.showSwitchModal = false;
      this.isOpen = false;
    },
    async openModalChar(type, id) {
      if (type == "add") {
        this.showAddChar = true;
      } else if (type == "edit") {
        this.charid = id;
        this.getDetailChar();
        this.showEditChar = true;
      } else if (type == "editMsg") {
        const { data } = await chatstoryController.DetailChatCS(id);
        if (data.STATUS == 200) {
          this.detailContentChat = data.DATA;
          this.showEditMsg = true;
        }
      } else if (type == "addImg") {
        this.showAddImg = true;
      }
    },

    addNarator() {
      this.naratorSelected = true;
      this.selectedItemIndex = null;
      this.selectedItems = null;
      this.isOpen = false;

      this.character.forEach((listItem, i) => {
        listItem.isSelected = this.selectedItemIndex === i;
      });
    },
    toggleMood(item, index) {
      this.selectedMoodIndex = index;
      this.selectedMood = item;
      // this.naratorSelected = false;

      this.listBubble.forEach((listItem, i) => {
        listItem.isSelected = this.selectedMoodIndex === i;
      });
    },

    async submitEpisode(type) {
      if (type == "publish") {
        const { data } = await chatstoryController.EditEpisode(this.chatstoryid, this.epsid, 1, this.epsTitle);
        if (data.STATUS == 200) {
          this.$router.push("/detail-chat-story/" + this.chatstoryid);
        }
      } else {
        const { data } = await chatstoryController.EditEpisode(this.chatstoryid, this.epsid, 3, this.epsTitle);
        if (data.STATUS == 200) {
          this.$router.push("/detail-chat-story/" + this.chatstoryid);
        }
      }
    },

    ...mapActions({
      fetchListChat: "chatstory/fetchListChat",
      handleFetchChatUnscrolled: "chatstory/handleFetchChatUnscrolled",
      loadMoreChat: "chatstory/loadMoreChat",
      resetOffset: "chatstory/resetOffset",
    }),

    ...mapActions("chatstory", ["updateLimit", "updateOffset"]),
  },
  computed: {
    filteredCharacters() {
      if (this.naratorSelected) {
        return this.character;
      } else {
        return this.character.filter((item) => item.id !== this.sel.id);
      }
    },

    ...mapGetters("chatstory", {
      listChatContent: "get_res_chatstory",
      totalData: "get_totalData",
    }),
    ...mapState("chatstory", ["limit", "offset"]),
  },
  destroyed() {
    // Menghapus event listener pada saat komponen dihancurkan
    window.removeEventListener("scroll", this.debouncedScrollMobile);
  },
  components: {
    AddChar,
    EditChar,
    AddImg,
    EditMsg,
  },
};
</script>

<style>
@import url("./css/edit-cs-mobile.css");
</style>

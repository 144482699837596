<template>
  <div class="modal" v-if="showAboutHiatus">
    <div class="about-hiatus-section">
      <div class="abh-head">
        <div class="abh-title">Apa itu Tandai Judul ?</div>
        <div @click="$emit('close')" class="abh-close">X</div>
      </div>
      <div class="abh-divide"></div>

      <div class="abh-body" style="text-align: justify; padding-right: 14px">
        <span>Kamu dapat menandakan karya kamu dengan: </span>
        <span>Hiatus atau Tandai Selesai.</span>
        <span>Hiatus adalah berhenti mengupload sementara karya kamu Jika karya kamu sudah selesai kamu dapat Tandai Selesai Novel.</span>
        <ol type="1">
          <li>1. Novel yang sudah lebih dari 2(dua) bulan tidak memperbarui karya nya akan otomatis di hiatuskan.</li>
          <li>2. Kamu memiliki waktu 6 (enam) bulan untuk memperbarui karya kamu.</li>
          <li>3. Jika kamu lebih dari 6(enam) bulan tidak memperbarui karya, maka karya kamu akan otomatis di arsipkan dan tidak bisa dibaca oleh pembaca klaklik lainnya.</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAboutHiatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
@import url("./css/about-hiatus.css");
</style>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal-delete-content">
      <div class="modal-delete-head">
        <p>Apakah kamu yakin ingin menghapus konten?</p>
      </div>
      <div class="modal-delete-body">
        <button @click="$emit('close')">Batal</button>
        <button @click="deleteComic">Hapus</button>
      </div>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";

export default {
  name: "show-barcode",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    comicid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    async deleteComic() {
      const { data } = await contentController.DeleteComic(this.comicid);
      this.$router.push("/table-comic");
    },
  },
};
</script>

<style>
@import url("./css/modalcomic.css");
</style>

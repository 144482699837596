<template>
  <header class="header" :style="{ display: hideNav ? 'none' : 'flex' }">
    <img @click="redirectLogo" src="@/assets/logoklaklik1.png" alt="" class="logo-analytics" />
    <div class="divide-headers"></div>
    <div class="h__nav" :class="{ 'is-open': isOpen }">
      <Navbar :closeNav="closeNav" :redirectNovel="redirectNovel" :redirectComic="redirectComic"></Navbar>
    </div>
    <div class="upload-section">
      <div @click="toggleUpload" class="h__btn-right">
        <img src="@/assets/upload-karya-icon.png" alt="" />
        <p>Upload Karya</p>
      </div>

      <div class="dropdown-section" v-show="showUpload">
        <div @click="redirectNovel" class="dropdown-novel"><img src="@/assets/new-novel-icon.png" alt="" /> Novel</div>
        <div @click="redirectComic" class="dropdown-novel"><img src="@/assets/new-comic-icon.png" alt="" /> Comic</div>
        <div @click="redirectChatStory" class="dropdown-comic"><img src="@/assets/chatstory.png" alt="" /> ChatStory</div>
      </div>
    </div>
    <div class="user-section">
      <div class="h__btn-user" @click="toggleDropdown">
        <img :src="user.MEMBER_IMAGE" class="user-icon" />
        <!-- <img src="@/assets/user-icon.png" class="user-icon"> -->
        <p>{{ user.USERNAME }}</p>
      </div>

      <div class="dropdown-logout" v-show="showDropdown">
        <img src="@/assets/logout.png" alt="logout-icon" class="logout" @click="handleLogout" />
      </div>
    </div>
    <button class="hamburger" @click="toggleNav" :class="{ 'is-active': isOpen }">
      <template v-if="isOpen"> ✕ </template>
      <template v-else> ☰ </template>
    </button>
  </header>
</template>

<script>
import Navbar from "./Navbar.vue";
import login from "@/controllers/login";
import storage from "@/controllers/Localstorage";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import usertoken from "@/store/modules/usertoken";

export default {
  name: "headers",
  components: {
    Navbar,
  },
  data() {
    return {
      isOpen: false,
      username: "",
      showDropdown: false,
      showUpload: false,
      session: {},
      hideNav: false,
    };
  },
  async created() {
    this.usertoken = this.$route.params.usertoken;
    if (this.usertoken) {
      this.getDataUser();
    }
  },
  async mounted() {
    document.body.addEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      const isNavClicked = event.target.closest(".header");

      if (!isNavClicked) {
        this.showUpload = false;
        this.showDropdown = false;
      }
    },
    redirectLogo() {
      this.$router.push("/summary");
    },
    toggleNav() {
      this.isOpen = !this.isOpen;
      document.body.classList.toggle("no-scroll");
    },
    closeNav() {
      this.isOpen = false;
      document.body.classList.toggle("no-scroll");
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    async getDataUser() {
      const res = await login.getUser(this.usertoken);
      this.username = res.data.DATA.USERNAME;
    },
    redirectNovel() {
      this.$router.push("/store-novel");
      this.isOpen = false;
      document.body.classList.remove("no-scroll");
      // this.isOpen = false
      // this.showDropdown = false
      this.showUpload = false;
    },
    redirectComic() {
      // window.open(process.env.VUE_APP_LISTCOMIC, '_blank')
      // this.isOpen = false
      this.$router.push("/store-comic");
      this.isOpen = false;
      this.showUpload = false;
      document.body.classList.remove("no-scroll");
      // this.showUpload = !this.showUpload
    },
    redirectChatStory() {
      this.$router.push("/store-chat-story");
      this.isOpen = false;
      document.body.classList.remove("no-scroll");
      // this.isOpen = false
      // this.showDropdown = false
      this.showUpload = false;
    },
    handleLogout() {
      this.session = storage.getLocalStorage();
      var datarequest = {
        memberId: this.session.memberId,
        memberEmail: this.session.memberEmail,
        firstname: this.session.firstname,
        lastname: this.session.lastname,
        aboutMe: this.session.aboutMe,
        memberAddress: this.session.memberAddress,
        memberBod: this.session.memberBod,
        memberPhone: this.session.memberPhone,
        memberImage: this.session.memberImage,
        keyActived: this.session.keyActived,
        keyMember: this.session.keyMember,
        token: this.session.token,
        usertoken: this.session.usertoken,
      };
      storage.RemoveLocalStorage(datarequest);
      localStorage.removeItem("typeContent");
      localStorage.removeItem("logoutTime");
      window.location.href = "/";
    },
  },
  computed: {
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    $route(name, from) {
      const route = this.$route.name;

      if (route == "login" || route == "check-login") {
        this.hideNav = true;
      } else if (
        route == "list-karya-chatstory" ||
        route == "store-chat-story" ||
        route == "detail-chat-story" ||
        route == "edit-chapter-chatstory"
      ) {
        if (screen.width <= 720) {
          this.hideNav = true;
        } else {
          this.hideNav = false;
        }
      } else {
        this.hideNav = false;
      }
    },
  },
};
</script>

<style>
.hamburger {
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background: none;
  font-weight: 500;
  transition: transform 0.3s ease-in-out;
  color: #577a95;
}

.hamburger.is-active {
  transform: rotate(-90deg);
}

/* .no-scroll {
  overflow: hidden;
} */
</style>

<template>
    <div class="modal" v-if="showEditModal">
        <div class="modal-delete-novel">
            <div class="modal-delete-head">
                <p>Apakah kamu yakin datanya sudah benar?</p>
            </div>
            <div class="modal-delete-body">
                <button @click="$emit('close')">Batal</button>
                <button @click="handleUpdate(approve)">Simpan</button>
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    name: "show-barcode",
    props: {
        showEditModal: {
            type: Boolean,
            default: false
        },
        handleUpdate: {
            type: Function,
            required: true
        },
        approve: {
            type: Number,
            default: 0
        }
    },
    async mounted() {
    }
}
</script>
    
<style>
@import url('./css/modalnovel.css');
</style>
  
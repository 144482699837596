import getUser from "@/controllers/login";

export default {
  namespaced: true,
  state: {
    user: {},
  },

  getters: {
    get_user: (state) => state.user,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async fetchUser({ commit }, datarequest) {
      const res = await getUser.getUser(datarequest);
      commit("setUser", res.data.DATA);
      return res;
    },
  },
};

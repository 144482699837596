const listChar = [
  {
    id: 1,
    nama: "Julian Castamatas",
    desc: "Pengirim",
    pos: "Kanan",
    avatar: "",
  },
  {
    id: 2,
    nama: "Robert Davies Chaniago",
    desc: "Penerima",
    pos: "Kiri",
    avatar: "",
  },
  {
    id: 3,
    nama: "Natalia",
    desc: "Penerima",
    pos: "Kiri",
    avatar: "",
  },
  {
    id: 4,
    nama: "Jenny",
    desc: "Pengirim",
    pos: "Kiri",
    avatar: "",
  },
];

export default listChar;

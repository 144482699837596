<template>
  <div class="n__store-nav">
    <div class="nav-store-novel">
      <router-link to="/table-content">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Tambah Novel Baru</p>
    </div>
  </div>
  <div class="store-novel-section">
    <div class="store-left-novel">
      <div class="store-left-wrapper">
        <h4>Thumbnail</h4>
        <div class="img-novel-store" v-if="thumbnailUrl === ''">
          <img src="@/assets/default-store.png" />
        </div>
        <div class="img-novel-store" v-else>
          <img :src="thumbnailUrl" />
        </div>
        <div class="novel-upload-img">
          <input type="file" ref="fileInput" @change="uploadThumbnail" />
        </div>
      </div>
    </div>
    <div class="store-right">
      <form @submit.prevent="openModal" class="form-store-novel">
        <div class="input-store-novel">
          <label>Judul</label>
          <input type="text" placeholder="Masukkan judul" v-model="title" />
        </div>
        <div class="input-store-novel">
          <label>Synopsis</label>
          <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="desc" placeholder="masukkan teks ..." />
        </div>
        <div class="input-store-novel">
          <label>Genre</label>
          <select v-model="selectedGenre">
            <option value="">Pilih Genre</option>
            <option v-for="genre in genresList" :value="genre.id" :key="genre.id">
              {{ genre.name }}
            </option>
          </select>
        </div>
        <div class="input-store-novel">
          <label>Event (Optional)</label>
          <select v-model="selectedEvent">
            <option value="">Pilih Event</option>
            <option v-for="event in eventList" :value="event.id" :key="event.id">
              {{ event.title }}
            </option>
          </select>
        </div>
        <div class="terms-store-novel">
          <label>
            <input type="checkbox" v-model="isChecked" />
            Saya Menyetujui
            <span class="span-terms" @click="redirectTerms">Terms and Conditions</span>
            Klaklik
          </label>
        </div>
        <div class="btn-store-novel">
          <button>Simpan Perubahan</button>
        </div>
        <PreStoreNovel :showPreStoreNovel="showPreStoreNovel" @close="closePreStoreNovel"></PreStoreNovel>
        <WarnTerms :errorMsg="errorMsg" :showWarnTerms="showWarnTerms" @close="closeWarnTerms"></WarnTerms>
        <StoreModal :handleStore="handleStore" :show-modal="showModal" @close="closeModal" :isPost="isPost"></StoreModal>
      </form>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import imageController from "@/controllers/images";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import StoreModal from "@/components/novel/StoreModal.vue";
import WarnTerms from "@/components/novel/WarnTerms.vue";
import PreStoreNovel from "@/components/novel/PreStoreNovel.vue";
import { toast } from "vue3-toastify";
import storage from "@/controllers/Localstorage";

export default {
  data() {
    return {
      thumbnailUrl: "",
      title: "",
      desc: "",
      selectedGenre: "",
      selectedEvent: "",
      switchValue: false,
      genresList: [],
      eventList: [],
      showPreStoreNovel: false,
      showModal: false,
      showWarnTerms: false,
      errorMsg: "",
      file: "",
      isChecked: false,
      session: {},
      isPost: false,
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      this.fetchGenre();
      this.fetchEvent();
    } else {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async fetchGenre() {
      const { data } = await contentController.ListGenre();
      this.genresList = data.DATA;
    },

    async fetchEvent() {
      const { data } = await contentController.ListEvent();
      this.eventList = data.DATA;
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        this.openWarnTerms();
      }
    },
    async handleStore(approve) {
      this.isPost = true;
      const res_image = await this.StoreImage();

      if (res_image.STATUS == 200) {
        try {
          const { data } = await contentController.StoreNovel(this.session.memberId, this.title, this.desc, res_image.DATA, this.selectedGenre, this.selectedEvent, approve);
          const novelId = data.DATA.DATA.id;

          if (data.STATUS == 201) {
            this.$router.push({ name: "detail-novel", params: { id: novelId } });
            toast.success("Novel Berhasil Dibuat!", {
              autoClose: 2000,
              pauseOnHover: false,
              position: toast.POSITION.TOP_RIGHT,
              transition: toast.TRANSITIONS.FLIP,
            });
            this.isPost = false;
          } else {
            alert("session:" + this.session.memberId + ", " + "title:" + this.title + ", " + this.desc + ", " + res_image.DATA + ", " + this.selectedGenre + ", " + this.selectedEvent + ", " + approve);
            alert(data.MESSAGE);
            this.isPost = false;
          }
        } catch (error) {
          if (error) {
            console.log("data", error.response.data);
            console.log("sttus", error.response.status);
            console.log("header", error.response.headers);
          } else if (error.request) {
            console.log("request", error.request);
          } else {
            console.log("Error", error.message);
          }
          this.isPost = false;
        }
      } else {
        this.isPost = false;

        alert("add your thumbnail!!");
      }
    },
    openModal() {
      if (this.isChecked) {
        if (this.title && this.desc && this.selectedGenre && this.file) {
          if (this.desc.length < 250) {
            this.errorMsg = "Deskripsi kurang dari 250 karakter";
            this.openWarnTerms();
          } else {
            this.showModal = true;
          }
        } else {
          this.errorMsg = "Input form belum lengkap!";
          this.openWarnTerms();
        }
      } else {
        this.errorMsg = "Anda harus menyetujui terms dan condition Klaklik terlebih dahulu untuk melanjutkan";
        this.openWarnTerms();
      }
    },
    openWarnTerms() {
      this.showWarnTerms = true;
    },
    closePreStoreNovel() {
      this.showPreStoreNovel = false;
    },
    closeModal() {
      this.showModal = false;
    },
    closeWarnTerms() {
      this.showWarnTerms = false;
    },
    redirectTerms() {
      window.open(process.env.VUE_APP_TERMSOFUSE, "_blank");
    },
  },
  components: {
    QuillEditor,
    StoreModal,
    WarnTerms,
    PreStoreNovel,
  },
};
</script>

<style>
@import url("./css/store-novel.css");
</style>

import headers from "@/router/headers";
import axios from "axios";

export async function getSummary(memberid, usertoken, rangeDay) {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);

  headers["USERTOKEN"] = usertoken;

  if (usertoken !== undefined) {
    let response = await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/summary/" + memberid, data, { headers: headers });
    let res = response.data.DATA;
    return res;
  }
}

export async function getCalendar(memberid, usertoken, years, month) {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("years", years);
  data.append("month", month);

  headers["USERTOKEN"] = usertoken;

  if (usertoken !== undefined) {
    let response = await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/upload-activity/" + memberid, data, { headers: headers });
    let res = response.data.DATA;
    return res;
  }
}

export default {
  getSummary,
  getCalendar
};

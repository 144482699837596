<template>
    <div class="modal" v-if="showModal">
        <div class="modal-novel">
            <div class="modal-novel-head">
                <img src="@/assets/warn-modal.png" alt="">
                <p>Warning</p>
                <span class="closing" @click="$emit('close')">&times;</span>
            </div>
            <div class="modal-novel-body">
                <p>{{ errorMsg }}</p>
                <!-- <p>Please give a check to a terms and conditions</p> -->
                <!-- <p>Maaf kamu belum menyetujui terms dan conditions</p>
                <h4>Hubungi Admin!</h4> -->
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "show-barcode",
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        errorMsg: {
            type: String,
            default: ""
        }
    },
    data() {
        return {

        }
    },
}
</script>
    
<style>
@import url('./css/modalnovel.css');
</style>
  
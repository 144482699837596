<template>
  <div class="n__store-nav">
    <div class="nav-store-novel">
      <router-link to="/table-content">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Tambah Novel Baru</p>
    </div>
  </div>
  <div class="store-novel-section">
    <div class="store-left-novel">
      <div class="store-left-wrapper">
        <h4>Thumbnail</h4>
        <div class="img-novel-store" v-if="thumbnailUrl === ''">
          <img src="@/assets/default-store.png" />
        </div>
        <div class="img-novel-store" v-else>
          <img :src="thumbnailUrl" />
        </div>
        <div class="novel-upload-img">
          <input type="file" ref="fileInput" @change="uploadThumbnail" />
        </div>
      </div>
    </div>
    <div class="store-right">
      <div class="form-store-novel">
        <div class="input-store-novel">
          <label>Judul</label>
          <input type="text" placeholder="Masukkan judul" v-model="title" />
        </div>
        <div class="input-store-novel">
          <label>Synopsis</label>
          <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="desc" placeholder="masukkan teks ..." />
        </div>
        <div class="ms-input-section">
          <label>Genre</label>
          <multiselect
            v-model="selectedGenre"
            label="name"
            track-by="id"
            :options="genresList"
            :multiple="true"
            :taggable="true"
            :searchable="true"
            @newGenre="multipleGenre"
            class="multiselect-input"
          ></multiselect>
        </div>
        <div class="input-store-novel">
          <label>Event (Optional)</label>
          <select v-model="selectedEvent">
            <option value="">Pilih Event</option>
            <option v-for="event in eventList" :value="event.id" :key="event.id">
              {{ event.title }}
            </option>
          </select>
        </div>
        <div class="input-store-novel">
          <label for="">Hastag (Optional)</label>
          <p class="tags-text">Tekan tombol spasi untuk menambahkan tag.</p>
          <ul>
            <li v-for="(tag, index) in tags" :key="index">
              <span>{{ tag }}</span>
              <img src="../../../assets/hapus-tag.svg" alt="hapus-tag" class="remove-tag" @click="removeTag(index)" />
            </li>
            <input type="text" spellcheck="false" v-model="tagInput" id="tagsInput" :placeholder="remainingTags == 10 ? 'Ketik disini ...' : ''" />
          </ul>
          <div class="tags-detail">
            <p class="tags-text">
              <span>{{ remainingTags }}</span> tag tersisa
            </p>
            <button @click="removeAllTags">Remove All</button>
          </div>
        </div>
        <div class="terms-store-novel">
          <label>
            <input type="checkbox" v-model="isChecked" />
            Saya Menyetujui
            <span class="span-terms" @click.prevent="redirectTerms">Terms and Conditions</span>
            Klaklik
          </label>
        </div>
        <div class="btn-store-novel">
          <button @click.prevent="openModal">Simpan Perubahan</button>
        </div>
        <PreStoreNovel :showPreStoreNovel="showPreStoreNovel" @close="closePreStoreNovel"></PreStoreNovel>
        <WarnTerms :errorMsg="errorMsg" :showWarnTerms="showWarnTerms" @close="closeWarnTerms"></WarnTerms>
        <StoreModal :handleStore="handleStore" :show-modal="showModal" @close="closeModal" :isPost="isPost"></StoreModal>
      </div>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import imageController from "@/controllers/images";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import StoreModal from "@/components/novel/StoreModal.vue";
import WarnTerms from "@/components/novel/WarnTerms.vue";
import PreStoreNovel from "@/components/novel/PreStoreNovel.vue";
import { toast } from "vue3-toastify";
import storage from "@/controllers/Localstorage";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      thumbnailUrl: "",
      title: "",
      desc: "",
      selectedGenre: [],
      selectedEvent: "",
      switchValue: false,
      genresList: [],
      eventList: [],
      showPreStoreNovel: false,
      showModal: false,
      showWarnTerms: false,
      errorMsg: "",
      file: "",
      isChecked: false,
      session: {},
      isPost: false,

      // tag
      maxTags: 10,
      tags: [],
      tagInput: "",
    };
  },
  metaInfo() {
    return {
      title: "Klaklik | Store Novel",
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      this.fetchGenre();
      this.fetchEvent();
    } else {
      this.$router.push({ path: "/" });
    }

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_STORE_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Store Novel",
      page_path: "/store-novel",
      firebase_screen_class: "StoreNovel",
      screen_class: "StoreNovel",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_STORE_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Store Novel",
      page_path: "/store-novel",
      firebase_screen_class: "StoreNovel",
      screen_class: "StoreNovel",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async fetchGenre() {
      const { data } = await contentController.ListGenre();
      this.genresList = data.DATA;
    },

    async fetchEvent() {
      const { data } = await contentController.ListEvent();
      this.eventList = data.DATA;
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        this.openWarnTerms();
      }
    },
    async handleStore(approve) {
      this.isPost = true;
      const res_image = await this.StoreImage();

      if (res_image.STATUS == 200) {
        const { data } = await contentController.StoreNovel(
          localStorage.getItem("memberId"),
          this.title,
          this.desc,
          res_image.DATA,
          this.selectedGenre,
          this.selectedEvent,
          approve,
          this.tags
        );
        const novelId = data.DATA.DATA.id;

        if (data.STATUS == 201) {
          this.$router.push({ name: "detail-novel", params: { id: novelId } });
          toast.success("Novel Berhasil Dibuat!", {
            autoClose: 2000,
            pauseOnHover: false,
            position: toast.POSITION.TOP_RIGHT,
            transition: toast.TRANSITIONS.FLIP,
          });
          this.isPost = false;
        } else {
          // change alert wiht something
          this.isPost = false;
          alert(data.MESSAGE);
        }
      } else {
        this.isPost = false;

        // change alert wiht something
        alert("add thumbnail your novel first");
      }
    },
    openModal() {
      if (this.isChecked) {
        if (this.title && this.desc && this.selectedGenre && this.file) {
          if (this.desc.length < 250) {
            this.errorMsg = "Deskripsi kurang dari 250 karakter";
            this.openWarnTerms();
          } else {
            this.showModal = true;
          }
        } else {
          this.errorMsg = "Input form belum lengkap!";
          this.openWarnTerms();
        }
      } else {
        this.errorMsg = "Anda harus menyetujui terms dan condition Klaklik terlebih dahulu untuk melanjutkan";
        this.openWarnTerms();
      }
    },
    openWarnTerms() {
      this.showWarnTerms = true;
    },
    closePreStoreNovel() {
      this.showPreStoreNovel = false;
    },
    closeModal() {
      this.showModal = false;
    },
    closeWarnTerms() {
      this.showWarnTerms = false;
    },
    redirectTerms() {
      window.open(process.env.VUE_APP_TERMSOFUSE, "_blank");
    },

    // tag Function
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addTag() {
      let newTags = this.tagInput.split(",").map((tag) => tag.trim());
      newTags.forEach((newTag) => {
        if (newTag.length > 1 && !this.tags.includes(newTag) && this.tags.length < this.maxTags) {
          this.tags.push(newTag);
        }
      });
      this.tagInput = "";
    },
    removeAllTags() {
      this.tags = [];
    },
    multipleGenre(newGenreName) {
      const newGenre = {
        name: newGenreName,
        id: newGenreName.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.genresList.push(newGenre);
      this.selectedGenre.push(newGenre);
    },
  },
  components: {
    QuillEditor,
    Multiselect,
    StoreModal,
    WarnTerms,
    PreStoreNovel,
  },
  computed: {
    remainingTags() {
      return this.maxTags - this.tags.length;
    },
  },
  watch: {
    tagInput(value) {
      if (value.includes(" ")) {
        this.addTag();
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
@import url("./css/store-novel.css");
</style>

<template>
  <div class="app" id="app">
    <!-- <Loader :isLoading="isLoading" /> -->
    <!-- <main v-if="!isLoading"> -->
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content}` : `Dashboard` }}</template>
    </metainfo>
    <main>
      <div class="content-app">
        <Headers />
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Headers from "@/components/Headers.vue";
import Loader from "./components/Loader.vue";
import login from "@/components/login.vue";
import storage from "./controllers/Localstorage";
import { useMeta } from "vue-meta";

export default {
  name: "App",
  components: {
    Headers,
    Loader,
    login,
  },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
  data() {
    return {
      // isLoading: true,
      session: {},
      usertoken: "",
      redirectUrl: "",
    };
  },
  async mounted() {
    const url = window.location.pathname;
    localStorage.setItem("redirectUrl", url);
    this.usertoken = this.$route.params.usertoken;
    this.session = storage.getLocalStorage();

    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 3000);
    // this.setSessionTimeout(60)
  },
  methods: {
    removeSession() {
      this.session = storage.getLocalStorage();
      var datarequest = {
        memberId: this.session.memberId,
        memberEmail: this.session.memberEmail,
        firstname: this.session.firstname,
        lastname: this.session.lastname,
        aboutMe: this.session.aboutMe,
        memberAddress: this.session.memberAddress,
        memberBod: this.session.memberBod,
        memberPhone: this.session.memberPhone,
        memberImage: this.session.memberImage,
        keyActived: this.session.keyActived,
        keyMember: this.session.keyMember,
        token: this.session.token,
        usertoken: this.session.usertoken,
      };
      storage.RemoveLocalStorage(datarequest);
    },
    // setSessionTimeout(minutes) {
    //   const milliseconds = minutes * 60 * 1000
    //   setTimeout(() => {
    //     this.removeSession()
    //   }, milliseconds)
    // }
  },
};
</script>

<style>
@import url("./main.css");
</style>

<template>
  <div class="analytic-nav-section">
    <div class="nav-analytic-novel">
      <router-link :to="{ name: 'detail-novel', params: { id: novelid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Novel</p>
      <div class="divide">/</div>
      <p>Detail and Chapters</p>
      <div class="divide">/</div>
      <p>Title Analytics</p>
    </div>
  </div>
  <div class="analytic-novel-section">
    <div class="left-analytic-novel">
      <img :src="analytics.thumbnail" alt="analytics-thumbnail" />
      <p class="n-analytic-title">{{ analytics.title }}</p>
      <!-- <button class="export-analytic-novel">Export <img src="@/assets/export.png" alt=""></button> -->
    </div>
    <div class="right-analytic-novel">
      <div class="box-desc-novel">
        <label>Total Views</label>
        <h3>{{ analytics.total_views }}</h3>
      </div>
      <div class="box-desc-novel">
        <label>Total Comments</label>
        <h3>{{ analytics.total_comment }}</h3>
      </div>
      <div class="box-desc-novel">
        <label>Jumlah Chapter</label>
        <h3>{{ analytics.total_chapter }}</h3>
      </div>
      <div class="box-desc-novel">
        <label>Total Pendapatan Karya</label>
        <h3>{{ analytics.total_earning }}</h3>
      </div>
      <div class="box-desc-novel">
        <label>Disimpan ke library</label>
        <h3>{{ analytics.total_bookmark }}</h3>
      </div>
      <div class="box-desc-novel">
        <label>Upload Chapter Terakhir</label>
        <h3>{{ analytics.updated_at }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import storage from "@/controllers/Localstorage";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  data() {
    return {
      novelid: 0,
      analytics: {},
      memberId: 0,
      session: {},
    };
  },
  metaInfo() {
    return {
      title: this.analytics.title ? `${this.analytics.title} | Analytic` : "Dasbboard",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.novelid = id;
    const resNovel = await contentController.DetailNovel(this.novelid);
    this.memberId = resNovel.data.DATA.novel_detail.member_id;
    this.session = storage.getLocalStorage();
    const { data } = await contentController.AnalyticNovel(id);
    this.analytics = data.DATA;

    if (parseInt(this.memberId) !== parseInt(this.session.memberId)) {
      this.$router.push("/");
    }

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_ANALYTICS_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Analytics Novel",
      page_path: "/analytic-novel",
      firebase_screen_class: "AnalyticNovel",
      screen_class: "AnalyticNovel",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_ANALYTICS_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Analytics Novel",
      page_path: "/analytic-novel",
      firebase_screen_class: "AnalyticNovel",
      screen_class: "AnalyticNovel",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
};
</script>

<style>
@import url("../css/analytic-novel.css");
</style>

<template>
    <div class="sn-modal" v-if="showSchedule">
        <div class="sn-body">
            <div class="sn-close-btn" @click="this.$emit('close')">
                X
            </div>
            <div class="sn-content">
                <div class="snc-head">
                    <div class="snc-title">Publish Chapter</div>
                    <div class="snc-line"></div>
                    <div class="snc-desc">{{ title }} | {{ chapterTitle }}</div>
                </div>
                <div class="toogle-schedule">
                    <label class="sn-switch">
                        <input type="checkbox" @click="toogleSchedule" v-model="isChecked">
                        <div class="sn-slider round"></div>
                    </label>
                    <span>Jadwalkan Publikasi</span>
                </div>
                <div class="form-date-controls" :disabled="isChecked === false ? true : false">
                    <div :class="isChecked ? 'form-date-input' : 'form-date-off'">
                        <label for="">Tanggal</label>
                        <input type="date" :disabled="isChecked === false ? true : false" v-model="selectedDate">
                    </div>
                    <div :class="isChecked ? 'form-time-input' : 'form-time-off'">
                        <label for="">Waktu</label>
                        <input type="time" :disabled="isChecked === false ? true : false" v-model="selectedTime">
                    </div>
                    <img src="@/assets/btn-schedule.png" alt="" class="btn-schedule" v-if="isChecked">
                    <img src="@/assets/btn-offschedule.png" alt="" class="btn-offschedule" v-else>
                </div>
                <div class="sn-or">
                    Atau
                </div>
                <div class="sn-btn">
                    <div :class="isChecked ? 'sn-draft-off' : 'sn-draft-btn'"
                        @click.prevent="isChecked ? null : simpanChapter(3)">
                        <img src="@/assets/save-btn.png" alt="">
                        <span>Draft</span>
                    </div>
                    <div class="sn-publish-btn" @click.prevent="handleSchedule">
                        <img src="@/assets/send-btn.png" alt="">
                        <span>Publish</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showSchedule: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        chapterTitle: {
            type: String,
            default: ''
        },
        simpanChapter: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            isChecked: false,
            selectedDate: '',
            selectedTime: ''
        }
    },
    methods: {
        toogleSchedule() {
            this.isChecked = !this.isChecked
        },
        handleSchedule() {
            if (this.isChecked === true) {
                if (this.selectedDate !== '' || this.selectedTime !== '') {
                    const response = `${this.selectedDate} ${this.selectedTime}:00`;
                    this.simpanChapter(6, response);
                } else {
                    console.log("response kosong")
                }
            } else {
                this.simpanChapter(1)
            }
        }
    }
}
</script>

<style>
@import url('../../components/novel/css/edit-schedule-novel.css');
</style>
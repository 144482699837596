<template>
  <div class="modal" v-if="showAboutBoost">
    <div class="about-popup-section">
      <div class="aps-head">
        <div></div>
        <div class="aps-title">Apa itu Booster ?</div>
        <div @click="$emit('close')" class="aps-close">X</div>
      </div>

      <div class="aps-divide"></div>

      <div class="aps-body" style="text-align: justify; padding-right: 14px">
        Buat karya kamu lebih mudah ditemukan oleh pembaca dengan booster! disini kamu bisa pilih paket untuk boosting karya kamu serta benefitnya !
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAboutBoost: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
@import url("./css/about-boost.css");
</style>

<template>
  <div class="c__store-nav">
    <div class="nav-store-comic">
      <router-link to="/table-content">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Tambah Comic Baru</p>
    </div>
  </div>
  <div class="store-comic-section">
    <div class="store-left-comic">
      <div class="store-left-wrapper">
        <h4>Thumbnail</h4>
        <div class="img-novel-store" v-if="thumbnailUrl === ''">
          <img src="@/assets/default-store.png" />
        </div>
        <div class="img-comic-store" v-else>
          <img :src="thumbnailUrl" />
        </div>
        <div class="comic-upload-img">
          <label class="comic-upload-btn" onclick="document.getElementById('fileInput').click()">Upload Thumbnail</label>
          <input type="file" id="fileInput" ref="fileInput" @change="uploadThumbnail" />
        </div>
      </div>
    </div>
    <div class="store-right">
      <form @submit.prevent="openModal" class="form-store-comic">
        <div class="input-store-comic">
          <label>Judul</label>
          <input type="text" placeholder="Masukkan judul" v-model="title" />
        </div>
        <div class="input-store-comic">
          <label>Genre</label>
          <select v-model="selectedGenre">
            <option value="">Pilih Genre</option>
            <option v-for="genre in genresList" :value="genre.id" :key="genre.id">
              {{ genre.name }}
            </option>
          </select>
        </div>
        <div class="input-store-comic">
          <label>Event (Optional)</label>
          <select v-model="selectedEvent">
            <option value="">Pilih Event</option>
          </select>
        </div>
        <div class="input-store-comic">
          <label>Synopsis</label>
          <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="desc" placeholder="masukkan teks ..." />
        </div>
        <div class="terms-store-comic">
          <label>
            <input type="checkbox" v-model="isChecked" />
            Saya Menyetujui
            <span class="span-terms">Terms and Conditions</span> Klaklik
          </label>
        </div>
        <div class="btn-store-comic">
          <button>Simpan Perubahan</button>
        </div>
        <WarnTerms :errorMsg="errorMsg" :showWarnTerms="showWarnTerms" @close="closeWarnTerms"></WarnTerms>
        <StoreModalComic :handleStore="handleStore" :show-modal="showModal" @close="closeModal"></StoreModalComic>
        <PreStoreNovel :showPreStoreNovel="showPreStoreNovel" @close="closePreStoreNovel"></PreStoreNovel>
      </form>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import imageController from "@/controllers/images";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import StoreModalComic from "@/components/comic/StoreModalComic.vue";
import PreStoreNovel from "@/components/novel/PreStoreNovel.vue";
import WarnTerms from "@/components/novel/WarnTerms.vue";
import { toast } from "vue3-toastify";
import storage from "@/controllers/Localstorage";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Klaklik | Store Comic",
    };
  },
  data() {
    return {
      thumbnailUrl: "",
      title: "",
      desc: "",
      selectedGenre: "",
      selectedEvent: "",
      errorMsg: "",
      file: "",
      isChecked: false,
      showPreStoreNovel: false,
      showModal: false,
      showWarnTerms: false,
      genresList: [],
      eventList: [],
      session: {},
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      this.fetchGenre();
      this.fetchEvent();
    } else {
      this.$router.push({ path: "/" });
    }

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_STORE_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Store Comic",
      page_path: "/store-comic",
      firebase_screen_class: "StoreComic",
      screen_class: "StoreComic",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_STORE_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Store Comic",
      page_path: "/store-comic",
      firebase_screen_class: "StoreComic",
      screen_class: "StoreComic",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        alert("add thumbnail your novel first");
      }
    },
    async handleStore(approve) {
      const res_image = await this.StoreImage();

      if ((res_image.STATUS = 200)) {
        const { data } = await contentController.StoreComic(
          localStorage.getItem("memberId"),
          this.title,
          this.desc,
          res_image.DATA,
          this.selectedGenre,
          approve
        );
        const comicId = data.DATA.id;
        if (data.STATUS == 201) {
          this.$router.push({ name: "detail-comic", params: { id: comicId } });
          toast.success("Comic Berhasil Dibuat!", {
            autoClose: 2000,
            pauseOnHover: false,
            position: toast.POSITION.TOP_RIGHT,
            transition: toast.TRANSITIONS.FLIP,
          });
        } else {
          // change alert wiht something
          alert("something wrong!!!");
        }
      } else {
        // change alert wiht something
        alert("add thumbnail your novel first");
      }
    },
    async fetchGenre() {
      const { data } = await contentController.GenreComic();
      this.genresList = data.DATA;
    },
    async fetchEvent() {
      const { data } = await contentController.ListEvent();
      this.eventList = data.DATA;
    },
    openModal() {
      if (this.isChecked) {
        if (this.title && this.desc && this.selectedGenre && this.file) {
          if (this.desc.length < 250) {
            this.errorMsg = "Deskripsi kurang dari 250 karakter";
            this.openWarnTerms();
          } else {
            this.showModal = true;
          }
        } else {
          this.errorMsg = "Input form belum lengkap!";
          this.openWarnTerms();
        }
      } else {
        this.errorMsg = "Anda harus menyetujui terms dan condition Klaklik terlebih dahulu untuk melanjutkan";
        this.openWarnTerms();
      }
    },
    openWarnTerms() {
      this.showWarnTerms = true;
    },
    closeModal() {
      this.showModal = false;
    },
    closePreStoreNovel() {
      this.showPreStoreNovel = false;
    },
    closeWarnTerms() {
      this.showWarnTerms = false;
    },
  },
  components: {
    QuillEditor,
    PreStoreNovel,
    StoreModalComic,
    WarnTerms,
  },
};
</script>

<style>
#fileInput {
  display: none;
}

@import url("./css/store-comic.css");
</style>

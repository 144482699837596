import { createRouter, createWebHistory } from "vue-router";
import Summary from "../views/summary/Summary.vue";
import Content from "../views/content/Content.vue";
import ListAllContent from "../views/content/ListAllContent.vue";
import DetailNovel from "@/views/content/Novel/DetailNovel.vue";
import AnalyticNovel from "@/views/content/Novel/AnalyticNovel.vue";
import AnalyticComic from "@/views/content/Comic/AnalyticComic.vue";
import EditNovel from "@/views/content/Novel/EditNovel.vue";
import DetailComic from "@/views/content/Comic/DetailComic.vue";
import EditComic from "@/views/content/Comic/EditComic.vue";
import Followers from "@/views/followers/Followers.vue";
import Wallet from "@/views/wallet/Wallet.vue";
import HistoryEarning from "@/views/wallet/earning/HistoryEarning.vue";
import HistoryWithdraw from "@/views/wallet/withdraw/HistoryWithdraw.vue";
import Withdraw from "@/views/wallet/withdraw/Withdraw.vue";
import CreateChapter from "@/views/content/Novel/CreateChapter.vue";
import EditChapter from "@/views/content/Novel/EditChapter.vue";
import Login from "@/components/login.vue";
import StoreNovel from "@/views/content/Novel/StoreNovel.vue";
import StoreComic from "@/views/content/Comic/StoreComic.vue";
import CreateEpisode from "@/views/content/Comic/CreateEpisode.vue";
import UpdateEpisode from "@/views/content/Comic/UpdateEpisode.vue";
import ListMemberAffiliate from "@/views/wallet/affiliate/ListMemberAffiliate.vue";
import DetailMemberAF from "@/views/wallet/affiliate/DetailMemberAF.vue";
import DetailContentAF from "@/views/wallet/affiliate/DetailContentAF.vue";
import DetailComicAF from "@/views/wallet/affiliate/DetailComicAF.vue";
import ListPanelEps from "@/views/content/Comic/ListPanelEps.vue";
import KaryaComic from "@/views/content/Comic/KaryaComic.vue";
import PreviewChapter from "@/views/content/Novel/PreviewChapter.vue";
import DebugNovel from "@/views/content/Novel/DebugNovel.vue";
import CheckLogin from "@/components/CheckLogin.vue";
import BoostKarya from "@/views/content/BoostKarya.vue";
import ListBoostKarya from "@/views/content/ListBoostKarya.vue";
import CreateCS from "@/views/content/ChatStory/CreateCS.vue";
import DetailCS from "@/views/content/ChatStory/DetailCS.vue";
import CreateChapterCS from "@/views/content/ChatStory/CreateChapterCS.vue";
import ListKaryaChatStory from "@/views/content/ChatStory/ListKaryaChatStory.vue";
import EditCS from "@/views/content/ChatStory/EditCS.vue";
import EditChapterCS from "@/views/content/ChatStory/EditChapterCS.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/:usertoken",
    name: "check-login",
    component: CheckLogin,
  },
  {
    path: "/summary",
    name: "summary",
    component: Summary,
  },
  {
    path: "/content",
    name: "content",
    component: Content,
  },
  {
    path: "/store-novel",
    name: "store-novel",
    component: StoreNovel,
  },
  {
    path: "/table-content",
    name: "table-content",
    component: ListAllContent,
  },
  {
    path: "/table-comic",
    name: "table-comic",
    component: KaryaComic,
  },
  {
    path: "/detail-novel/:id",
    name: "detail-novel",
    component: DetailNovel,
  },
  {
    path: "/detail-comic/:id",
    name: "detail-comic",
    component: DetailComic,
  },
  {
    path: "/analytic-novel/:id",
    name: "analytic-novel",
    component: AnalyticNovel,
  },
  {
    path: "/analytic-comic/:id",
    name: "analytic-comic",
    component: AnalyticComic,
  },
  {
    path: "/create-chapter/:id",
    name: "create-chapter",
    component: CreateChapter,
  },
  {
    path: "/edit-chapter/:id",
    name: "edit-chapter",
    component: EditChapter,
  },
  {
    path: "/edit-novel/:id",
    name: "edit-novel",
    component: EditNovel,
  },
  {
    path: "/edit-comic/:id",
    name: "edit-comic",
    component: EditComic,
  },
  {
    path: "/edit-comic/:id",
    name: "edit-comic",
    component: EditComic,
  },
  {
    path: "/followers",
    name: "followers",
    component: Followers,
  },
  {
    path: "/wallet",
    name: "wallet",
    component: Wallet,
  },
  {
    path: "/history-earning",
    name: "history-earning",
    component: HistoryEarning,
  },
  {
    path: "/history-withdraw",
    name: "history-withdraw",
    component: HistoryWithdraw,
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: Withdraw,
  },
  {
    path: "/store-comic",
    name: "store-comic",
    component: StoreComic,
  },
  {
    path: "/store-episode/:id",
    name: "store-episode",
    component: CreateEpisode,
  },
  {
    path: "/update-episode/:id",
    name: "update-episode",
    component: UpdateEpisode,
  },
  {
    path: "/list-member-affiliate",
    name: "list-member-affiliate",
    component: ListMemberAffiliate,
  },
  {
    path: "/detail-member-affiliate/:id",
    name: "detail-member-affiliate",
    component: DetailMemberAF,
  },
  {
    path: "/detail-content-affiliate/:id",
    name: "detail-content-affiliate",
    component: DetailContentAF,
  },
  {
    path: "/detail-comic-affiliate/:id",
    name: "detail-comic-affiliate",
    component: DetailComicAF,
  },
  {
    path: "/list-panel-eps/:id",
    name: "list-panel-eps",
    component: ListPanelEps,
  },
  {
    path: "/preview-chapter/:id",
    name: "preview-chapter",
    component: PreviewChapter,
  },
  {
    path: "/debug-novel",
    name: "debug-novel",
    component: DebugNovel,
  },
  {
    path: "/boost-karya/:id",
    name: "boost-karya",
    component: BoostKarya,
  },
  {
    path: "/list-boost-karya",
    name: "list-boost-karya",
    component: ListBoostKarya,
  },
  {
    path: "/list-karya-chatstory",
    name: "list-karya-chatstory",
    component: ListKaryaChatStory,
  },
  {
    path: "/store-chat-story",
    name: "store-chat-story",
    component: CreateCS,
  },
  {
    path: "/detail-chat-story/:id",
    name: "detail-chat-story",
    component: DetailCS,
  },
  {
    path: "/edit-chat-story/:id",
    name: "edit-chat-story",
    component: EditCS,
  },
  {
    path: "/store-chapter-chatstory/:id",
    name: "store-chapter-chatstory",
    component: CreateChapterCS,
  },
  {
    path: "/edit-chapter-chatstory/:id",
    name: "edit-chapter-chatstory",
    component: EditChapterCS,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import axios from "axios";
import headers from "@/router/headers";

const ListSectionBoost = async (id) => {
  if (id == 1) {
    return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-section-boosting", {
      headers: headers,
    });
  } else if (id == 2) {
    return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-section-boosting-novel", {
      headers: headers,
    });
  } else if (id == 3) {
    return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-section-boosting-comic", {
      headers: headers,
    });
  }
};

const ListPaketBoost = async (type, id) => {
  if (type == 1) {
    return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-section-paket-boosting-byhomeid/" + id, {
      headers: headers,
    });
  } else if (type == 2) {
    return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-section-paket-boosting-byhomeid-novel/" + id, {
      headers: headers,
    });
  } else if (type == 3) {
    return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-section-paket-boosting-byhomeid-comic/" + id, {
      headers: headers,
    });
  }
};

const OrderBoost = async (dataOrder) => {
  let data = new FormData();
  if (dataOrder.content_type == "novel") {
    data.append("novel_id", dataOrder.contentId);
  } else if (dataOrder.content_type == "comic") {
    data.append("comic_id", dataOrder.contentId);
  }

  data.append("member_id", dataOrder.memberId);
  data.append("coin", dataOrder.price);
  data.append("type_order", dataOrder.type_order);
  data.append("section_home_id", dataOrder.sectionId);
  data.append("section_boosting_paket_id", dataOrder.paketId);
  data.append("day_duration_boosting", dataOrder.duration);
  data.append("status_prime", dataOrder.status_prime);
  data.append("k_poin", dataOrder.kpoin); //cashback kpoin

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/order-boosting", data, {
    headers: headers,
  });
};

const OrderBoostKpoin = async (dataOrder) => {
  let data = new FormData();
  if (dataOrder.content_type == "novel") {
    data.append("novel_id", dataOrder.contentId);
  } else if (dataOrder.content_type == "comic") {
    data.append("comic_id", dataOrder.contentId);
  }

  data.append("member_id", dataOrder.memberId);
  data.append("type_order", dataOrder.type_order);
  data.append("section_home_id", dataOrder.sectionId);
  data.append("section_boosting_paket_id", dataOrder.paketId);
  data.append("day_duration_boosting", dataOrder.duration);
  data.append("status_prime", dataOrder.status_prime);
  data.append("k_poin", dataOrder.kpoin); //cashback kpoin
  data.append("price_poin", dataOrder.price_poin); //price kpoin

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/order-boosting-kpoin", data, {
    headers: headers,
  });
};

const OrderBoostAntrian = async (dataOrder) => {
  let data = new FormData();
  if (dataOrder.content_type == "novel") {
    data.append("novel_id", dataOrder.contentId);
  } else if (dataOrder.content_type == "comic") {
    data.append("comic_id", dataOrder.contentId);
  }

  data.append("member_id", dataOrder.memberId);
  data.append("coin", dataOrder.price);
  data.append("type_order", dataOrder.type_order);
  data.append("section_home_id", dataOrder.sectionId);
  data.append("section_boosting_paket_id", dataOrder.paketId);
  data.append("day_duration_boosting", dataOrder.duration);
  data.append("status_prime", dataOrder.status_prime);
  data.append("k_poin", dataOrder.kpoin); //cashback kpoin

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/order-boosting-antrian", data, {
    headers: headers,
  });
};

const OrderBoostAntrianKpoin = async (dataOrder) => {
  let data = new FormData();
  if (dataOrder.content_type == "novel") {
    data.append("novel_id", dataOrder.contentId);
  } else if (dataOrder.content_type == "comic") {
    data.append("comic_id", dataOrder.contentId);
  }

  data.append("member_id", dataOrder.memberId);
  data.append("type_order", dataOrder.type_order);
  data.append("section_home_id", dataOrder.sectionId);
  data.append("section_boosting_paket_id", dataOrder.paketId);
  data.append("day_duration_boosting", dataOrder.duration);
  data.append("status_prime", dataOrder.status_prime);
  data.append("k_poin", dataOrder.kpoin); //cashback kpoin
  data.append("price_poin", dataOrder.price_poin); //price kpoin

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/order-boosting-antrian-kpoin", data, {
    headers: headers,
  });
};

const CheckOrderBoost = async (dataOrder) => {
  let data = new FormData();
  if (dataOrder.content_type == "novel") {
    data.append("novel_id", dataOrder.contentId);
  } else if (dataOrder.content_type == "comic") {
    data.append("comic_id", dataOrder.contentId);
  }

  data.append("member_id", dataOrder.memberId);
  data.append("type_order", dataOrder.type_order);
  data.append("section_boosting_paket_id", dataOrder.paketId);
  data.append("day_duration_boosting", dataOrder.duration);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/check-boosting-antrian-duplicate", data, {
    headers: headers,
  });
};

const ListBoostKarya = async (memberid, offset) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("limit", 5);
  data.append("offset", offset);
  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-order-by-member-booting", data, {
    headers: headers,
  });
};

export default {
  ListSectionBoost,
  ListPaketBoost,
  OrderBoost,
  OrderBoostKpoin,
  OrderBoostAntrian,
  OrderBoostAntrianKpoin,
  CheckOrderBoost,
  ListBoostKarya,
};

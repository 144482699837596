import axios from "axios";
import headers from "@/router/headers";

const StoreChatStory = async (memberid, title, desc, images, genre, event, approve, tags) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("title", title);
  data.append("desc", desc);
  genre.forEach((genres, index) => {
    data.append(`genre[${index}]`, genres.id);
  });
  data.append("thumbnail", images);
  if (event == ""){
    data.append("event_id", 0);

  }else{
    data.append("event_id", event);
  }

  data.append("approve", approve);
  tags.forEach((tag, index) => {
    data.append(`hastag[${index}]`, tag);
  });

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/store-sinopsis-chat", data, {
    headers: headers,
  });
};

const EditChatStory = async (memberid, title, desc, images, genre, event, approve, tags, chat_id) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("title", title);
  data.append("desc", desc);
  genre.forEach((genres, index) => {
    data.append(`genre[${index}]`, genres.id);
  });
  data.append("thumbnail", images);
  data.append("event_id", event);
  data.append("approve", approve);
  tags.forEach((tag, index) => {
    data.append(`hastag[${index}]`, tag);
  });

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/put-sinopsis/" + chat_id, data, {
    headers: headers,
  });
};

const ListChatStoryMember = async (memberid,rangeDay, offset) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);
  data.append("limit", 5);
  data.append("offset", offset);
console.log(rangeDay)
  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/list-sinopsis-chat-by-member", data, {
    headers: headers,
  });
};

const DetailChatStory = async (id) => {
  let data = new FormData();
  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/detail-chat-story/" + id, data, {
    headers: headers,
  });
};

const ListChapterChatStory = async (cs_id, limit, offset) => {
  let data = new FormData();
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/list-eps-by-sinopsis-dashboard/" + cs_id, data, {
    headers: headers,
  });
};

const CreateChar = async (chat_id, name, avatar, status) => {
  let data = new FormData();
  data.append("chat_id", chat_id);
  data.append("nama_karakter", name);
  data.append("avatar", avatar);
  data.append("status_receiver", status);

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/store-character", data, {
    headers: headers,
  });
};

const ListChar = async (chat_id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/list-character-by-sinopsis/" + chat_id, {
    headers: headers,
  });
};

const EditChar = async (chat_id, name, avatar, status, charid) => {
  let data = new FormData();
  data.append("chat_id", chat_id);
  data.append("nama_karakter", name);
  if (avatar != "") {
    data.append("avatar", avatar);
  }
  data.append("status_receiver", status);

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/put-character/" + charid, data, {
    headers: headers,
  });
};
const EditSender = async (id, status) => {
  let data = new FormData();
  if (status == 0){
    data.append("status_receiver", 1);
  }else{
    data.append("status_receiver", 0);

  }

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/put-character/" + id, data, {
    headers: headers,
  });
};
const DetailChar = async (char_id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/detail-character/" + char_id, {
    headers: headers,
  });
};

const DeleteChar = async (char_id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/delete-character/" + char_id, {
    headers: headers,
  });
};

const StoreEpisode = async (chatid, title) => {
  let data = new FormData();
  data.append("chat_id", chatid);
  data.append("title", title);
  data.append("approve", 3);
  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/store-eps-dashboard", data, {
    headers: headers,
  });
};

const DeleteEpisode = async (eps_id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/delete-eps-dashboard/" + eps_id, {
    headers: headers,
  });
};

const DetailEpisode = async (eps_id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/detail-eps-dashboard/" + eps_id, {
    headers: headers,
  });
};

const CheckCharacterDel = async (char_id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/check-character-delete/" + char_id, {
    headers: headers,
  });
};

const StoreChatCS = async (chatid, epsid, charid, themeid, desc, status_receiver, image) => {
  let data = new FormData();
  data.append("chat_id", chatid);
  data.append("chapter_id", epsid);
  data.append("character_id", charid);
  data.append("chat_theme_id", themeid);
  data.append("desc", desc);
  data.append("status_receiver", status_receiver);
  if (image != "") {
    data.append("image", image);
  }

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/store-content-chat-dashboard", data, {
    headers: headers,
  });
};

const ListChatCS = async (epsid, limit, offset) => {
  let data = new FormData();
  data.append("limit", limit);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/list-content-chat-dashboard/" + epsid, data, {
    headers: headers,
  });
};

const EditChatCS = async (synopsisid, chatid, epsid, charid, themeid, desc, status_receiver, image) => {
  let data = new FormData();
  data.append("chat_id", chatid);
  data.append("chapter_id", epsid);
  data.append("character_id", charid);
  data.append("chat_theme_id", themeid);
  data.append("desc", desc);
  data.append("status_receiver", status_receiver);
  if (image != "") {
    data.append("image", image);
  }

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/put-content-chat-dashboard/" + synopsisid, data, {
    headers: headers,
  });
};

const DeleteChatCS = async (msgid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/delete-content-chat-dashboard/" + msgid, {
    headers: headers,
  });
};

const DetailChatCS = async (msgid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/detail-content-chat-dashboard/" + msgid, {
    headers: headers,
  });
};

const EditEpisode = async (chatid, epsid, approve, title) => {
  let data = new FormData();
  data.append("chat_id", chatid);
  data.append("approve", approve);
  data.append("title", title);

  return await axios.post(process.env.VUE_APP_GATEWAY + "chat/put-eps-dashboard/" + epsid, data, {
    headers: headers,
  });
};

const ListChatStorySearch = async (memberid, keyword, limit, offset) => {
  let data = new FormData();
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-novel-search/" + memberid, data, {
    headers: headers,
  });
};

export default {
  ListChapterChatStory,
  ListChatStoryMember,
  ListChar,
  ListChatCS,
  ListChatStorySearch,
  StoreChatStory,
  StoreEpisode,
  StoreChatCS,
  CreateChar,
  DetailChatStory,
  DetailChar,
  DetailEpisode,
  DetailChatCS,
  EditChatStory,
  EditEpisode,
  EditChar,
  EditChatCS,
  CheckCharacterDel,
  DeleteChar,
  DeleteEpisode,
  DeleteChatCS,
  EditSender,
};

<template>
  <div class="modal" v-if="showModal">
    <div class="modal-comic">
      <div class="store-comic-head">
        <span class="closing" @click="$emit('close')">&times;</span>
      </div>
      <div class="store-comic-img">
        <img src="@/assets/store-modal-img.png" alt="" />
      </div>
      <div class="store-comic-body">
        <p>Lanjutkan hanya mengupload sinopsis?</p>
      </div>
      <div class="store-comic-btn">
        <button @click="handleStore(0)">SIMPAN KE DRAFT</button>
        <button @click="handleStore(0)">PUBLIKASIKAN</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "show-modal-comic",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    handleStore: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
@import url("./css/storemodalcomic.css");
</style>

import followers from "@/controllers/followers";

export default {
  namespaced: true,
  state: {
    res_followers: {},
    rangeDay: 1,
  },

  getters: {
    get_res_followers: (state) => state.res_followers,
  },

  mutations: {
    setFollowers(state, res_followers) {
      state.res_followers = res_followers;
    },
  },
  actions: {
    async fetchFollowers({ commit }, data_req) {
      const res = await followers.getFollowers(
        data_req.member_id,
        data_req.usertoken,
        data_req.range_day
      );
      commit("setFollowers", res);
    },
  },
};

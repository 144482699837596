<template>
  <div class="summary" id="summary">
    <div class="s__left">
      <p class="s__title">Statistics</p>
      <div class="s__filter-left">
        <form @submit.prevent="fetchSummaryAction">
          <p class="label">Tampilkan data dalam</p>
          <select v-model="rangeDay">
            <option value="1">Hari ini</option>
            <option value="7">7 hari terakhir</option>
            <option value="30">1 bulan terakhir</option>
            <option value="365">1 tahun terakhir</option>
            <!-- <option value="custom">Custom</option> -->
          </select>
        </form>
      </div>
      <div class="s__views">
        <p class="s__views-title">Total Views</p>
        <div class="s__views-line"></div>
        <p class="s__views-content">
          {{ res_summary.total_views_content }}
        </p>
      </div>

      <div class="s__views">
        <p class="s__views-title">Estimasi Pendapatan</p>
        <div class="s__views-line"></div>
        <p class="s__views-content">
          {{ res_summary.total_earning }}
        </p>
      </div>

      <div class="s__views">
        <p class="s__views-title">Total Followers</p>
        <div class="s__views-line"></div>
        <p class="s__views-content">
          {{ res_summary.total_followers }}
        </p>
      </div>
    </div>
    <!-- calendar section -->
    <div class="s__right">
      <p class="s__title">Upload Activity</p>
      <div class="s__filter-right">
        <!-- calendar -->
        <div class="calendar">
          <Calendar></Calendar>
        </div>
        <div class="box-detail">
          <p>No upload</p>
          <p>Upload</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Calendar from "@/components/summary/Calendar.vue";
import storage from "@/controllers/Localstorage";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import { useMeta } from "vue-meta";

export default {
  name: "summary",
  setup() {
    useMeta({ title: "Klaklik | Summary" });
  },
  data() {
    return {
      rangeDay: 1,
      session: {},
      dataUser: {},
      usertoken: "",
      showModal: false,
      dataUpload: {},
      years: 2020,
      month: 11,
    };
  },

  async created() {
    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      await this.fetchSummaryAction();
    } else {
      this.$router.push({ path: "/" });
    }

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_SUMMARY,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Summary",
      page_path: "/summary",
      firebase_screen_class: "Summary",
      screen_class: "Summary",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_SUMMARY,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Summary",
      page_path: "/summary",
      firebase_screen_class: "Summary",
      screen_class: "Summary",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchSummaryAction() {
      await this.fetchSummary({
        member_id: this.session.memberId,
        usertoken: this.session.usertoken,
        range_day: this.rangeDay,
      });
    },
    ...mapActions({
      fetchSummary: "summary/fetchSummary",
      fetchUser: "user/fetchUser",
    }),
    ...mapMutations({
      setSummary: "summary/setSummary",
      setUser: "user/setUser",
    }),
    closeModal() {
      this.showModal = false;
    },
  },
  computed: {
    ...mapState({
      summary: (state) => state.summary.res_summary,
      user: (state) => state.user.user,
      rangeDay: (state) => state.rangeDay,
    }),
    ...mapGetters("summary", {
      res_summary: "get_res_summary",
    }),
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
  watch: {
    rangeDay(newVal, oldVal) {
      this.fetchSummaryAction();
    },
  },
  components: {
    Calendar,
  },
};
</script>

<style>
@import url("./summary.css");
</style>

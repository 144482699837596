import axios from "axios";
import headers from "@/router/headers";

const ListKarya = async (memberid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/list-karya/" + memberid, {
    headers: headers,
  });
};

const ListNovel = async (memberid, rangeDay, offset) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);
  data.append("limit", 5);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-novel/" + memberid, data, {
    headers: headers,
  });
};

const ListNovelSearch = async (memberid, keyword, limit, offset) => {
  let data = new FormData();
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-novel-search/" + memberid, data, {
    headers: headers,
  });
};

const ListComic = async (memberid, rangeDay, offset) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);
  data.append("limit", 5);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-comic/" + memberid, data, {
    headers: headers,
  });
};

const ListComicSearch = async (memberid, keyword, limit, offset) => {
  let data = new FormData();
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-comic-search/" + memberid, data, {
    headers: headers,
  });
};

const DetailNovel = async (id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/detail-novel/" + id, {
    headers: headers,
  });
};

const DetailComic = async (id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/detail-comic/" + id, {
    headers: headers,
  });
};

const ListGenre = async () => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "genre-novel", {
    headers: headers,
  });
};
const ListGenreChat = async () => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/list-genre-chat", {
    headers: headers,
  });
};

const GenreComic = async () => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "genre-comic", {
    headers: headers,
  });
};

const ListEvent = async () => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "list-event", {
    headers: headers,
  });
};
const ListEventChat = async () => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "chat/list-event-chat", {
    headers: headers,
  });
};
const ListChapter = async (novelid, limit, offset) => {
  let data = new FormData();
  data.append("id", novelid);
  data.append("limit", limit);
  data.append("offset", offset);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-chapter/" + novelid, data, {
    headers: headers,
  });
};

const ListEpisode = async (comicid) => {
  let data = new FormData();
  data.append("id", comicid);

  return await axios.post(
    process.env.VUE_APP_GATEWAY + "dashboard/list-episode-comic/" + comicid,
    {},
    {
      headers: headers,
    }
  );
};

const AnalyticNovel = async (id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/analytic-novel/" + id, {
    headers: headers,
  });
};

const AnalyticComic = async (id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/analytic-comic/" + id, {
    headers: headers,
  });
};

const EditNovel = async (novelid, title, genre, event, desc, images, approve, tags) => {
  let data = new FormData();
  data.append("id", novelid);
  data.append("title", title);
  genre.forEach((genres, index) => {
    data.append(`genre[${index}]`, genres.id);
  });
  data.append("event_id", event);
  data.append("desc", desc);
  if (images) {
    data.append("images_novel", images);
  }
  data.append("approve", approve);
  tags.forEach((tag, index) => {
    data.append(`hastag[${index}]`, tag);
  });
  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/update-novel/" + novelid, data, {
    headers: headers,
  });
};

const EditComic = async (comicid, title, genre, desc, images, approve) => {
  let data = new FormData();
  data.append("id", comicid);
  data.append("title", title);
  data.append("genre", genre);
  data.append("desc", desc);
  if (images) {
    data.append("thumbnail", images);
  }
  data.append("approve", approve);
  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/update-comic/" + comicid, data, {
    headers: headers,
  });
};

const DeleteNovel = async (novelid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/delete-novel/" + novelid, {
    headers: headers,
  });
};

const DeleteComic = async (comicid) => {
  let data = new FormData();
  data.append("id", comicid);

  return await axios.post(
    process.env.VUE_APP_GATEWAY + "dashboard/delete-comic/" + comicid,
    {},
    {
      headers: headers,
    }
  );
};

const StoreNovel = async (memberid, title, desc, images, genre, event, approve, tags) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("title", title);
  data.append("desc", desc);
  genre.forEach((genres, index) => {
    data.append(`genre[${index}]`, genres.id);
  });
  data.append("images_novel", images);
  data.append("event_id", event);
  data.append("approve", approve);
  tags.forEach((tag, index) => {
    data.append(`hastag[${index}]`, tag);
  });

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/store-novel", data, {
    headers: headers,
  });
};

const StoreComic = async (memberid, title, desc, thumbnail, genre, approve) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("title", title);
  data.append("desc", desc);
  data.append("genre", genre);
  data.append("thumbnail", thumbnail);
  data.append("approve", approve);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/store-comic", data, {
    headers: headers,
  });
};

const StoreChapter = async (title, desc, novelid, approve, schedule_time) => {
  let data = new FormData();
  data.append("title", title);
  data.append("desc", desc);
  data.append("novel_id", novelid);
  data.append("approve", approve);
  if (approve === 6) {
    data.append("schedule_time", schedule_time);
  }

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/store-novel-chapter", data, {
    headers: headers,
  });
};

const StoreEpisode = async (title, thumbnail, episode, comicid) => {
  let data = new FormData();
  data.append("title", title);
  data.append("thumbnail", thumbnail);
  data.append("episode_number", episode);
  data.append("comic_id", comicid);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/store-comic-chapter", data, {
    headers: headers,
  });
};

const DetailChapter = async (id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/detail-novel-chapter/" + id, {
    headers: headers,
  });
};

const DetailEpisode = async (id) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/detail-comic-chapter/" + id, {
    headers: headers,
  });
};

const EditChapter = async (chapterid, title, desc, approve, schedule_time) => {
  let data = new FormData();
  data.append("id", chapterid);
  data.append("title", title);
  data.append("desc", desc);
  data.append("approve", approve);
  if (approve === 6) {
    data.append("schedule_time", schedule_time);
  }
  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/update-novel-chapter/" + chapterid, data, {
    headers: headers,
  });
};

const EditEpisode = async (episodeid, title, episode, images) => {
  let data = new FormData();
  data.append("id", episodeid);
  data.append("title", title);
  data.append("episode_number", episode);
  if (images) {
    data.append("thumbnail", images);
  }
  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/update-comic-chapter/" + episodeid, data, {
    headers: headers,
  });
};

const DeleteChapter = async (chapterid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/delete-novel-chapter/" + chapterid, {
    headers: headers,
  });
};

const DeleteEpisode = async (episodeid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/delete-comic-chapter/" + episodeid, {
    headers: headers,
  });
};

const MarkNovelComplete = async (novelid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "mark-novel-complete/" + novelid, {
    headers: headers,
  });
};

const MarkComicComplete = async (comicid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "mark-comic-complete/" + comicid, {
    headers: headers,
  });
};

const HiatusNovel = async (novelid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "mark-novel-hiatus/" + novelid, {
    headers: headers,
  });
};

const PostChapterMulti = async (episodeid, file, caption) => {
  let data = new FormData();
  data.append("episode_id", episodeid);
  for (let i = 0; i < file.length; i++) {
    data.append(`file[${i}]`, file[i]);
    data.append(`page[${i}]`, caption[i].caption);
  }

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/store-comic-page-chapter-multi", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      APPTOKEN: "klaklikapptoken",
    },
  });
};

const GetListPanel = async (episodeid, usertoken) => {
  headers["USERTOKEN"] = usertoken;
  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/list-page-comic/" + episodeid, {}, { headers: headers });
};

const GetDetailPanel = async (panelid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/detail-comic-page-chapter/" + panelid, { headers: headers });
};

const UpdatePanelComic = async (panelid, episodeid, thumbnail, page) => {
  let data = new FormData();
  data.append("episode_id", episodeid);
  data.append("file", thumbnail);
  data.append("page", page);

  return await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/update-comic-page-chapter/" + panelid, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      APPTOKEN: "klaklikapptoken",
    },
  });
  // return await axios.post(
  //   process.env.VUE_APP_GATEWAY +
  //     "dashboard/update-comic-page-chapter/" +
  //     panelid,
  //   data,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       APPTOKEN: "klaklikapptoken",
  //     },
  //   }
  // );
};

const DeletePanelComic = async (panelid, usertoken) => {
  headers["USERTOKEN"] = usertoken;
  return await axios.get(
    process.env.VUE_APP_GATEWAY + "dashboard/delete-comic-page-chapter/" + panelid,
    // {},
    { headers: headers }
  );
};

const ActivationNovel = async (novelid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "mark-novel-on-going/" + novelid, {
    headers: headers,
  });
};

const DeleteHastag = async (novelid, hastagid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/delete-used-novel-hastag/" + novelid + "/" + hastagid, { headers: headers });
};
const DeleteHastagChat = async (chatid, hastagid) => {
  return await axios.get(process.env.VUE_APP_GATEWAY + "dashboard/delete-used-chat-hastag/" + chatid + "/" + hastagid, { headers: headers });
};

export default {
  ListKarya,
  ListNovel,
  ListComic,
  DetailNovel,
  DetailComic,
  ListGenre,
  ListEvent,
  ListChapter,
  ListEpisode,
  AnalyticNovel,
  AnalyticComic,
  EditNovel,
  EditComic,
  DeleteNovel,
  DeleteComic,
  StoreNovel,
  StoreComic,
  StoreChapter,
  DetailChapter,
  EditChapter,
  DeleteChapter,
  GenreComic,
  StoreEpisode,
  DeleteEpisode,
  DetailEpisode,
  EditEpisode,
  ListNovelSearch,
  ListComicSearch,
  MarkNovelComplete,
  PostChapterMulti,
  GetListPanel,
  GetDetailPanel,
  DeletePanelComic,
  UpdatePanelComic,
  HiatusNovel,
  MarkComicComplete,
  ActivationNovel,
  DeleteHastag,
  ListEventChat,
  ListGenreChat,
  DeleteHastagChat,
};

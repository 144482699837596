<template>
  <div class="wallet-nav">
    <p>Select Category</p>
    <div class="radio-w-nav">
      <label for="my-wallet" class="nav-mywallet" :class="{ 'selected-nav': myWallet }">My Wallet</label>
      <input type="radio" name="category" value="my-wallet" v-model="myWallet" :checked="myWallet" @change="handleChange('my-wallet')" />
      <label for="affiliate" class="nav-affiliate" :class="{ 'selected-nav': affiliate }">Affiliate</label>
      <input type="radio" name="category" value="affiliate" v-model="affiliate" :checked="affiliate" @change="handleChange('affiliate')" />
    </div>
  </div>
  <div class="list-member-af">
    <div class="list-member-af-tables">
      <h4>List Member</h4>
      <!-- Tables Here -->
      <div class="laf-filter-controls">
        <div class="laf-filter-page">
          <label for="">Tampilkan Per halaman</label>
          <select v-model="pageSize">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
          </select>
        </div>
        <div class="laf-filter-search">
          <label for="">Cari</label>
          <input type="text" placeholder="Cari" v-model="searchInput" />
        </div>
      </div>
      <div class="laf-table-section">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Author</th>
              <th>Chapter Terjual</th>
              <th>Earnings</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody v-if="listAgent.length === 0">
            <tr>
              <td colspan="5">No records found</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(agent, index) in filteredAgentList" :key="agent.id">
              <td class="td-img-af">
                <span>{{ index + 1 }}</span
                ><img :src="agent.member_image" alt="" />
              </td>
              <td>{{ agent.name }}</td>
              <td>{{ agent.total_content_earning }}</td>
              <td>{{ agent.total_earning }}</td>
              <td><img src="@/assets/next-icon.png" alt="" class="laf-next-icon" @click="redirectDetailMember(agent.member_sso_id)" /></td>
            </tr>
          </tbody>
        </table>
        <div class="pages-section" v-if="listAgent.length !== 0">
          <p class="pages">{{ currentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="currentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="currentPage === 1">&lt; Sebelumnya</button>
            <button :class="currentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="currentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletController from "@/controllers/wallet";
import storage from "@/controllers/Localstorage";
import { mapGetters } from "vuex";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "List Member Affiliate",
    };
  },
  data() {
    return {
      myWallet: false,
      affiliate: true,
      checkAgent: "",
      searchInput: "",
      session: {},
      listAgent: [],
      memberid: 92434,
      pageSize: 5,
      offset: 0,
      currentPage: 1,
      totalData: 0,
      sortName: "",
      sortType: "",
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    this.CheckAgent();
    this.ListAgent();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_LIST_MEMBER_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard List Member Affiliate",
      page_path: "/list-member-affiliate",
      firebase_screen_class: "ListMemberAffiliate",
      screen_class: "ListMemberAffiliate",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_LIST_MEMBER_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard List Member Affiliate",
      page_path: "/list-member-affiliate",
      firebase_screen_class: "ListMemberAffiliate",
      screen_class: "ListMemberAffiliate",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    redirectDetailMember(id) {
      this.$router.push("/detail-member-affiliate/" + id);
    },
    handleChange(value) {
      if (value === "my-wallet") {
        this.myWallet = true;
        this.affiliate = false;
        this.$router.push("/wallet");
      } else if (value === "affiliate") {
        this.myWallet = false;
        this.affiliate = true;
      }
    },
    async CheckAgent() {
      const { data } = await walletController.CheckMemberAgent(this.session.memberId);
      this.checkAgent = data.DATA;
    },
    async ListAgent() {
      const { data } = await walletController.ListMemberAgent(this.session.memberId, this.pageSize, this.offset, this.sortName, this.sortType);
      this.listAgent = data.DATA;
      this.totalData = data.TOTAL;
    },
    async searchAgent() {
      if (this.searchInput === "") {
        this.offset = 0;
        this.currentPage = 1;
        this.ListAgent();
      } else {
        this.offset = 0;
        const { data } = await walletController.SearchMemberAgent(this.session.memberId, this.searchInput, this.pageSize, this.offset);
        this.listAgent = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage = 1;
      }
    },
    async nextPage() {
      if (this.searchInput !== "") {
        this.currentPage++;
        this.offset = (this.currentPage - 1) * this.pageSize;
        const { data } = await walletController.SearchMemberAgent(this.session.memberId, this.searchInput, this.pageSize, this.offset);
        this.listAgent = data.DATA;
        this.totalData = data.TOTAL;
      } else {
        this.currentPage++;
        this.offset = (this.currentPage - 1) * this.pageSize;
        await this.ListAgent();
      }
    },
    async prevPage() {
      if (this.searchInput !== "") {
        this.offset -= this.pageSize;
        const { data } = await walletController.SearchMemberAgent(this.session.memberId, this.searchInput, this.pageSize, this.offset);
        this.listAgent = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage--;
      } else {
        this.currentPage--;
        this.offset = (this.currentPage - 1) * this.pageSize;
        await this.ListAgent();
      }
    },
    sortTable(columnName) {
      if (columnName === this.sortName) {
        this.sortType = this.sortType === "asc" ? "desc" : "";
        if (this.sortType === "") {
          this.sortName = "";
        }
      } else {
        this.sortName = columnName;
        this.sortType = "asc";
      }
    },
    handleWithdraw() {
      window.location.href = process.env.VUE_APP_WITHDRAW;
    },
  },
  computed: {
    filteredAgentList() {
      return this.listAgent.filter((item) => item.name.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
    totalPages() {
      return Math.ceil(this.totalData / this.pageSize);
    },
    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric", timeZoneName: "short" };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
  watch: {
    pageSize(newType, oldType) {
      this.offset = 0;
      this.currentPage = 1;
      this.searchAgent();
      this.totalPages();
    },
    sortType(newType, oldType) {
      this.ListAgent();
    },
    searchInput() {
      this.searchAgent();
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #8eb5db;
  color: #ffffff;
}

th {
  padding: 10px;
}

td {
  border-bottom: 2px solid #e4e4e4;
}

th,
td {
  text-align: center;
  padding: 10px;
}

td > img {
  width: 40px;
}
@import url("./css/list-member-af.css");
</style>

<template>
  <div class="af-nav-detailcontent">
    <div class="nav-af-detailcontent">
      <router-link to="/list-member-affiliate">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>List Member</p>
      <div class="divide">/</div>
      <p>Detail Member</p>
      <div class="divide">/</div>
      <p>Detail Content</p>
    </div>
  </div>
  <div class="af-detailcontent">
    <div class="af-detail-contenthead">
      <div class="contenthead-title">
        <div class="contenthead-title-img">
          <img :src="detailContent.thumbnail" alt="" />
        </div>
        <div class="contenthead-title-name">
          <h4>{{ detailContent.title }}</h4>
        </div>
      </div>
      <div class="line"></div>
      <div class="contenthead-detail">
        <div class="contenthead-detail-item">
          <h4>Views</h4>
          <p>{{ detailContent.total_views }}</p>
        </div>
        <div class="contenthead-detail-item">
          <h4>Jumlah Chapter</h4>
          <p>{{ detailContent.total_chapter }}</p>
        </div>
        <div class="contenthead-detail-item">
          <h4>Chapter Terjual</h4>
          <p>{{ detailContent.total_chapter_bought }}</p>
        </div>
      </div>
    </div>
    <div class="afcontent-table-section">
      <h4>List Karya</h4>
      <div class="afcontent-filter-controls">
        <div class="afcontent-filter-page">
          <label for="">Tampilkan Per halaman</label>
          <select v-model="pageSize">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
          </select>
        </div>
        <div class="afcontent-filter-search">
          <label for="">Cari</label>
          <input type="text" placeholder="Cari" v-model="searchInput" />
        </div>
      </div>
      <div class="afcontent-list-table">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Chapter</th>
              <th>Chapter Terjual</th>
              <th>Earning Member</th>
              <th>Earning Affiliate</th>
            </tr>
          </thead>
          <tbody v-if="listContentNovel.length === 0">
            <tr>
              <td colspan="5">No records found</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(items, index) in filteredNovelList" :key="items.id">
              <td>{{ index + 1 }}</td>
              <td>{{ items.title }}</td>
              <td>{{ items.qty }}</td>
              <td>{{ items.total_pendapatan }}</td>
              <td>{{ items.total_pendapatan_agent }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pages-section" v-if="listContentNovel.length !== 0">
          <p class="pages">{{ currentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="currentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="currentPage === 1">&lt; Sebelumnya</button>
            <button :class="currentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="currentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletController from "@/controllers/wallet";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Klaklik | List Karya Affiliate",
    };
  },
  data() {
    return {
      contentId: 0,
      detailContent: {},
      listContentNovel: [],
      pageSize: 5,
      offset: 0,
      currentPage: 1,
      totalData: 0,
      searchInput: "",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.contentId = id;
    this.getDetailContent();
    this.getListContentNovel();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_DETAIL_NOVEL_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Novel Affiliate",
      page_path: "/detail-content-affiliate",
      firebase_screen_class: "DetailContentAF",
      screen_class: "DetailContentAF",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_DETAIL_NOVEL_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Novel Affiliate",
      page_path: "/detail-content-affiliate",
      firebase_screen_class: "DetailContentAF",
      screen_class: "DetailContentAF",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async getDetailContent() {
      const { data } = await walletController.NovelDetail(this.contentId);
      this.detailContent = data.DATA;
    },
    async getListContentNovel() {
      const { data } = await walletController.ListContentNovel(this.contentId, this.pageSize, this.offset);
      this.listContentNovel = data.DATA;
      this.totalData = data.TOTAL;
    },
    async nextPage() {
      if (this.searchInput !== "") {
        this.offset += this.pageSize;
        const { data } = await walletController.ListContentSearchNovel(this.contentId, this.searchInput, this.pageSize, this.offset);
        this.listContentNovel = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage++;
      } else {
        this.currentPage++;
        this.offset = (this.currentPage - 1) * this.pageSize;
        await this.getListContentNovel();
      }
    },
    async prevPage() {
      if (this.searchInput !== "") {
        this.offset -= this.pageSize;
        const { data } = await walletController.ListContentSearchNovel(this.contentId, this.searchInput, this.pageSize, this.offset);
        this.listContentNovel = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage--;
      } else {
        this.currentPage--;
        this.offset = (this.currentPage - 1) * this.pageSize;
        await this.getListContentNovel();
      }
    },
    async searchContent() {
      if (this.searchInput === "") {
        this.offset = 0;
        this.currentPage = 1;
        this.getListContentNovel();
      } else {
        this.offset = 0;
        const { data } = await walletController.ListContentSearchNovel(this.contentId, this.searchInput, this.pageSize, this.offset);
        this.listContentNovel = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage = 1;
      }
    },
  },
  computed: {
    filteredNovelList() {
      return this.listContentNovel.filter((item) => item.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
    totalPages() {
      return Math.ceil(this.totalData / this.pageSize);
    },
  },
  watch: {
    pageSize(newType, oldType) {
      this.offset = 0;
      this.currentPage = 1;
      this.searchContent();
      // this.getListContentNovel()
      this.totalPages();
    },
    searchInput() {
      this.searchContent();
    },
  },
};
</script>

<style>
@import url("./css/detailcontentaf.css");
</style>

<template>
  <div class="pn-modal" v-if="showPreviewNull">
    <div class="pn-content">
      <div class="pn-head">
        <img src="@/assets/warn-modal.png" alt="" />
        <p>Warning</p>
        <span class="closing" @click="$emit('close')">&times;</span>
      </div>
      <div class="pn-body">
        <p>{{ errorMsg }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPreviewNull: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
@import url("./css/preview-null.css");
</style>

<template>
  <div class="analytic-nav-section">
    <div class="nav-analytic-comic">
      <router-link :to="{ name: 'detail-comic', params: { id: comicid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Comic</p>
      <div class="divide">/</div>
      <p>Detail and Episode</p>
      <div class="divide">/</div>
      <p>Title Analytics</p>
    </div>
  </div>
  <div class="analytic-comic-section">
    <div class="left-analytic-comic">
      <img :src="analytics_comic.thumbnail" alt="analytics-thumbnail" />
      <p class="analytic-title">{{ analytics_comic.title }}</p>
      <!-- <router-link to="/" class="export-analytic-btn">Export <img src="@/assets/export.png" alt=""></router-link> -->
    </div>
    <div class="right-analytic-comic">
      <div class="box">
        <label>Total Views</label>
        <h3>{{ analytics_comic.total_views }}</h3>
      </div>
      <div class="box">
        <label>Total Comments</label>
        <h3>{{ analytics_comic.total_comment }}</h3>
      </div>
      <div class="box">
        <label>Jumlah Chapter</label>
        <h3>{{ analytics_comic.total_chapter }}</h3>
      </div>
      <div class="box">
        <label>Total Pendapatan Karya</label>
        <h3>{{ analytics_comic.total_earning }}</h3>
      </div>
      <div class="box">
        <label>Disimpan ke library</label>
        <h3>{{ analytics_comic.total_bookmark }}</h3>
      </div>
      <div class="box">
        <label>Upload Chapter Terakhir</label>
        <h3>{{ analytics_comic.updated_at }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import contentController from "@/controllers/content";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: this.analytics_comic.title ? `${this.analytics_comic.title} | Analytic` : "Dasbboard",
    };
  },
  data() {
    return {
      comicid: 0,
      analytics_comic: {},
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.comicid = id;

    const { data } = await contentController.AnalyticComic(id);
    this.analytics_comic = data.DATA;

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_ANALYTICS_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Analytic Comic",
      page_path: "/analytic-comic",
      firebase_screen_class: "AnalyticComic",
      screen_class: "AnalyticComic",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_ANALYTICS_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Analytic Comic",
      page_path: "/analytic-comic",
      firebase_screen_class: "AnalyticComic",
      screen_class: "AnalyticComic",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
};
</script>

<style>
@import url("../css/analytic-comic.css");
</style>

<template>
    <div class="modal" v-if="showCheckModal">
        <div class="modal-delete-novel">
            <div class="modal-delete-head">
                <p>Apakah kamu yakin ingin mempublish konten?</p>
            </div>
            <div class="modal-delete-body">
                <button @click="$emit('close')">Batal</button>
                <button @click.prevent="simpanChapter(1)">Publish</button>
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    name: "show-barcode",
    props: {
        showCheckModal: {
            type: Boolean,
            default: false
        },
        simpanChapter: {
            type: Function,
            required: true
        }
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
    
<style>
@import url('./css/modalnovel.css');
</style>
  
import axios from "axios";
import headers from "@/router/headers";

export async function getEarning(memberid, usertoken) {
  let data = new FormData();
  data.append("member_id", memberid);

  headers["USERTOKEN"] = usertoken;

  if (usertoken !== undefined) {
    let response = await axios.post(
      process.env.VUE_APP_GATEWAY + "dashboard/analytic-wallet/" + memberid,
      {},
      { headers: headers }
    );
    let res = response.data.DATA;
    return res;
  }
}

const getHistory = async (memberid, usertoken, rangeDay) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);

  headers["USERTOKEN"] = usertoken;

  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/history-earning-member/" +
      memberid,
    data,
    {
      headers: headers,
    }
  );
};

const getWithdraw = async (memberid, usertoken, rangeDay) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);

  headers["USERTOKEN"] = usertoken;

  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/history-withdraw-member/" +
      memberid,
    data,
    {
      headers: headers,
    }
  );
};

const getNominalWithdraw = async (memberid, usertoken) => {
  let data = new FormData();
  data.append("member_id", memberid);

  headers["USERTOKEN"] = usertoken;

  return await axios.get(
    process.env.VUE_APP_GATEWAY + "nominal-withdraw/" + memberid,
    {},
    {
      headers: headers,
    }
  );
};

// Affiliate API
const CheckMemberAgent = async (memberid) => {
  return await axios.get(
    process.env.VUE_APP_GATEWAY +
      "dashboard/check-member-was-agent/" +
      memberid,
    {
      headers: headers,
    }
  );
};

const ListMemberAgent = async (memberid, limit, offset, sort, sortType) => {
  let data = new FormData();
  data.append("limit", limit);
  data.append("offset", offset);
  data.append("sort", sort);
  data.append("sort_type", sortType);

  return await axios.post(
    process.env.VUE_APP_GATEWAY + "dashboard/list-member-agent/" + memberid,
    data,
    {
      headers: headers,
    }
  );
};

const SearchMemberAgent = async (memberid, keyword, limit, offset) => {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);

  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/list-member-agent-search/" +
      memberid,
    data,
    {
      headers: headers,
    }
  );
};

const MemberDetail = async (memberid) => {
  return await axios.get(
    process.env.VUE_APP_GATEWAY + "dashboard/member-agent-detail/" + memberid,
    {
      headers: headers,
    }
  );
};

const ListContentEarning = async (memberid, type, limit, offset) => {
  let data = new FormData();
  data.append("type_content", type);
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(
    process.env.VUE_APP_GATEWAY + "dashboard/list-content-earning/" + memberid,
    data,
    {
      headers: headers,
    }
  );
};

const ListContentEarningSearch = async (
  memberid,
  type,
  keyword,
  limit,
  offset
) => {
  let data = new FormData();
  data.append("type_content", type);
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/list-content-earning-search/" +
      memberid,
    data,
    {
      headers: headers,
    }
  );
};

const NovelDetail = async (novelid) => {
  return await axios.get(
    process.env.VUE_APP_GATEWAY + "dashboard/novel-detail-earning/" + novelid,
    {
      headers: headers,
    }
  );
};

const ComicDetail = async (comicid) => {
  return await axios.get(
    process.env.VUE_APP_GATEWAY + "dashboard/comic-detail-earning/" + comicid,
    {
      headers: headers,
    }
  );
};

const ListContentNovel = async (contentid, limit, offset) => {
  let data = new FormData();
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/list-content-earning-novelbyid/" +
      contentid,
    data,
    {
      headers: headers,
    }
  );
};

const ListContentSearchNovel = async (contentid, keyword, limit, offset) => {
  let data = new FormData();
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/list-content-earning-novelbyid-search/" +
      contentid,
    data,
    {
      headers: headers,
    }
  );
};

const ListContentComic = async (contentid, limit, offset) => {
  let data = new FormData();
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/list-content-earning-comicbyid/" +
      contentid,
    data,
    {
      headers: headers,
    }
  );
};

const ListContentSearchComic = async (contentid, keyword, limit, offset) => {
  let data = new FormData();
  data.append("keyword", keyword);
  data.append("limit", limit);
  data.append("offset", offset);
  return await axios.post(
    process.env.VUE_APP_GATEWAY +
      "dashboard/list-content-earning-comicbyid-search/" +
      contentid,
    data,
    {
      headers: headers,
    }
  );
};

export default {
  getEarning,
  getHistory,
  getWithdraw,
  getNominalWithdraw,
  CheckMemberAgent,
  ListMemberAgent,
  SearchMemberAgent,
  MemberDetail,
  ListContentEarning,
  ListContentEarningSearch,
  NovelDetail,
  ComicDetail,
  ListContentNovel,
  ListContentSearchNovel,
  ListContentComic,
  ListContentSearchComic,
};

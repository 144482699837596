const getLocalStorage = () => {
  localStorage.getItem("memberId");
  localStorage.getItem("memberEmail");
  localStorage.getItem("firstname");
  localStorage.getItem("lastname");
  localStorage.getItem("aboutMe");
  localStorage.getItem("memberAddress");
  localStorage.getItem("memberBod");
  localStorage.getItem("memberPhone");
  localStorage.getItem("memberImage");
  localStorage.getItem("keyActived");
  localStorage.getItem("keyMember");
  localStorage.getItem("token");
  localStorage.getItem("usertoken");
  var data = {};
  return (data = {
    memberId: localStorage.getItem("memberId"),
    memberEmail: localStorage.getItem("memberEmail"),
    firstname: localStorage.getItem("firstname"),
    lastname: localStorage.getItem("lastname"),
    aboutMe: localStorage.getItem("aboutMe"),
    memberAddress: localStorage.getItem("memberAddress"),
    memberBod: localStorage.getItem("memberBod"),
    memberPhone: localStorage.getItem("memberPhone"),
    memberImage: localStorage.getItem("memberImage"),
    keyActived: localStorage.getItem("keyActived"),
    keyMember: localStorage.getItem("keyMember"),
    token: localStorage.getItem("token"),
    usertoken: localStorage.getItem("usertoken"),
  });
};

const setLocalStorage = (datarequest) => {
  if (datarequest.memberId != undefined) {
    localStorage.setItem("memberId", datarequest.memberId);
    localStorage.setItem("memberEmail", datarequest.memberEmail);
    localStorage.setItem("firstname", datarequest.firstname);
    localStorage.setItem("lastname", datarequest.lastname);
    localStorage.setItem("aboutMe", datarequest.aboutMe);
    localStorage.setItem("memberAddress", datarequest.memberAddress);
    localStorage.setItem("memberBod", datarequest.memberBod);
    localStorage.setItem("memberPhone", datarequest.memberPhone);
    localStorage.setItem("memberImage", datarequest.memberImage);
    localStorage.setItem("keyActived", datarequest.keyActived);
    localStorage.setItem("keyMember", datarequest.keyMember);
    localStorage.setItem("token", datarequest.token);
    localStorage.setItem("usertoken", datarequest.usertoken);
    localStorage.setItem("password", "");
  }
};
const RemoveLocalStorage = (data) => {
  localStorage.removeItem("memberId", data.memberId);
  localStorage.removeItem("memberEmail", data.memberEmail);
  localStorage.removeItem("firstname", data.firstname);
  localStorage.removeItem("lastname", data.lastname);
  localStorage.removeItem("aboutMe", data.aboutMe);
  localStorage.removeItem("memberAddress", data.memberAddress);
  localStorage.removeItem("memberBod", data.memberBod);
  localStorage.removeItem("memberPhone", data.memberPhone);
  localStorage.removeItem("memberImage", data.memberImage);
  localStorage.removeItem("keyActived", data.keyActived);
  localStorage.removeItem("keyMember", data.keyMember);
  localStorage.removeItem("token", data.token);
  localStorage.removeItem("usertoken", data.usertoken);
  localStorage.removeItem("password", "");
};
export default { getLocalStorage, setLocalStorage, RemoveLocalStorage };

<template>
  <div class="modal-finish" v-if="showFinishPopup">
    <div class="modal-finish-section">
      <div class="modal-finish-head">
        <p>Apa Kamu yakin Ingin menyelesaikan Judul?</p>
      </div>
      <div class="modal-fp-warn">
        <p>Judul yang telah selesai tidak dapat diaktifkan maupun diedit kembali</p>
      </div>
      <div class="modal-fp-btn">
        <button @click="$emit('close')">Batal</button>
        <button @click="submitFinish">Selesaikan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showFinishPopup: {
      type: Boolean,
      default: false,
    },
    submitFinish: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
@import url("./css/finish-popup.css");
</style>

<template>
  <div class="boost-novel-section">
    <div class="bns-head">
      <img src="@/assets/arrow-back.png" alt="" class="arrow-novel-back" @click="redirectBack" />
      <p>{{ titleContent }}</p>
      <div></div>
    </div>
    <div class="bns-content">
      <div class="bns-left">
        <div class="bnsl-head">
          <label for="">Pilih Paket Booster</label>
          <img src="../../assets//hiatus/information.svg" alt="" @click="openDetailBoost('about')" />
        </div>
        <div class="bnsl-section">
          <p>Pilih Section untuk Boosting Karyamu</p>
          <div @click="toggleDropdown" class="bnsl-option-section" :style="selectedSection ? 'background: #55C1BB' : ''">
            <span :style="selectedSection ? 'color: #FFFFFF' : ''">{{ selectedSection ? selectedSection.section_name : "Pilih Section" }}</span>
            <div class="bnsl-arrow-down" :style="selectedSection ? 'border-left: 11px solid #FFFFFF' : ''"></div>
          </div>
          <div v-if="showSection" class="bnsl-list-section">
            <li v-for="section in listSection" :key="section.id" @click="selectSection(section)">{{ section.section_name }}</li>
          </div>
        </div>
        <div class="bnsl-paket">
          <p>Pilih paket Boosting</p>
          <div
            @click="tooglePaket"
            class="bnsl-option-paket"
            :style="[selectedPaket ? 'background: #55C1BB' : '', !selectedSection ? 'background: #E9E9E9' : '']"
          >
            <div class="bnsl-option-paket-left">
              <span :style="[selectedPaket ? 'color: #FFFFFF' : '', !selectedSection ? 'color: #C2C2C2' : '']" style="text-transform: capitalize">{{
                selectedPaket ? selectedPaket.boosting_name : "Pilih Paket"
              }}</span>
              <label v-if="selectedPaket && selectedPaket.status_antrian > 0">Antrian ke {{ selectedPaket.antrian }}</label>
            </div>
            <div class="bnsl-arrow-down" :style="selectedPaket ? 'border-left: 11px solid #FFFFFF' : ''"></div>
          </div>
          <div v-if="showPaket" class="bnsl-list-paket">
            <li v-for="paket in listBooster" :key="paket.id" @click="selectPaket(paket)" class="commonboost">
              <div class="bnsl-item-paket">
                <div class="bnsl-item-left">
                  <span class="bnsl-item-name">{{ paket.boosting_name }}</span>
                  <label v-if="paket.status_antrian > 0" :style="paket.status_antrian > 0 ? 'color: red' : ''">Antrian ke {{ paket.antrian }}</label>
                </div>
                <div class="bnsl-item-right">
                  <img src="../../assets/boosting/coin.svg" alt="" />
                  <span class="bnsl-item-price">{{ paket.price_boosting }}</span>
                </div>
              </div>
            </li>
          </div>
        </div>
        <div class="bnsl-price-section">
          <label for="">Total</label>
          <!-- <div class="bnsl-mycoin">
            <label for=""> Jumlah coin kamu </label>
            <div class="bnsl-item-coin">
              <img src="../../assets/boosting/coin.svg" alt="" />
              <span>{{ memberData.COIN_ANDROID }}</span>
            </div>
          </div>
          <div class="bnsl-mycoin">
            <label for=""> Jumlah Kpoin kamu </label>
            <div class="bnsl-item-coin">
              <img src="../../assets/boosting/poin-icon.png" alt="" />
              <span>{{ memberData.POIN }}</span>
            </div>
          </div> -->
          <div class="bnsl-creator-coin">
            <label for=""> Harga paket Boosting(Coin)</label>
            <div class="bnsl-item-coin">
              <img src="../../assets/boosting/coin.svg" alt="" />
              <span>{{ selectedPaket ? selectedPaket.price_boosting : 0 }}</span>
            </div>
          </div>
          <div class="bnsl-creator-coin">
            <label for=""> Harga paket Boosting(KPoin)</label>
            <div class="bnsl-item-coin">
              <img src="../../assets/boosting/poin-icon.png" alt="" />
              <span>{{ selectedPaket ? selectedPaket.price_poin_boosting : 0 }}</span>
            </div>
          </div>
          <!-- <div class="bnsl-coinleft">
            <label for=""> Sisa coin kamu </label>
            <div class="bnsl-item-coin">
              <img src="../../assets/boosting/coin.svg" alt="" />
              <span :style="coinLeft < 0 ? 'color: red' : ''">{{ coinLeft }}</span>
            </div>
          </div> -->
          <div v-if="errMsg">
            <p class="bnsl-error">{{ msg }}</p>
          </div>
        </div>
        <div class="bnsl-policy">
          <label>
            <input type="checkbox" v-model="isChecked" class="bnsl-check" />
            <div class="bnsl-terms-desc">
              Saya Menyetujui
              <span class="bnsl-terms" @click.prevent="redirectTerms">Syarat & Ketentuan</span>
              yang berlaku
            </div>
          </label>
        </div>
        <button :class="isAbleOrder ? 'bnsl-use' : 'bnsl-disabled'" :disabled="!isAbleOrder" @click="openDetailBoost('popup')">
          Gunakan Booster
        </button>
      </div>
      <div class="bns-right">
        <label for="">Placement Paket Booster</label>
        <img :src="imgBoost" alt="" v-if="!imageBoost" />
        <img :src="imageBoost" alt="" v-else />
        <p>Pilih Paket Booster</p>
      </div>
    </div>
    <about-boost :showAboutBoost="showAboutBoost" @close="closeBoostModal"></about-boost>
    <popup-boost
      :showPopupBoost="showPopupBoost"
      @close="closeBoostModal"
      :dataOrder="dataOrder"
      :coinLeft="coinLeft"
      :estimasi="estimasi"
    ></popup-boost>
  </div>
</template>

<script>
import BoostingController from "@/controllers/boosting";
import AboutBoost from "@/components/boosting/AboutBoost.vue";
import PopupBoost from "@/components/boosting/PopupBoost.vue";
import memberSession from "@/controllers/login";
import storage from "../../controllers/Localstorage";

export default {
  data() {
    return {
      isChecked: false,
      showSection: false,
      showPaket: false,
      showAboutBoost: false,
      showPopupBoost: false,
      selectedSection: null,
      selectedPaket: null,
      listSection: [],
      listBooster: [],
      titleContent: null,
      memberData: {},
      session: {},
      dataOrder: {
        type_order: 0,
        contentId: 0,
        sectionId: 0,
        paketId: 0,
        coin: 0,
        memberId: 0,
        paketName: "",
        price: 0, //harga (coin)
        antrian: 0,
        duration: null,
        content_type: "",
        status_prime: 0,
        benefit: "",
        kpoin: 0, //cashback kpoin
        mypoin: 0, //my kpoin
        price_poin: 0, //harga (poin)
      },
      estimasi: "",
      errMsg: false,
      msg: "",
      imageBoost: null,
    };
  },
  async mounted() {
    const route = this.$route.params.id;
    this.dataOrder.content_type = route.split("-")[0];
    this.dataOrder.contentId = route.split("-")[1];
    this.dataOrder.type_order = route.split("-")[2];
    this.titleContent = route.split("-")[3];

    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      this.getDataMember();
      this.dataOrder.memberId = this.session.memberId;

      const { data } = await BoostingController.ListSectionBoost(this.dataOrder.type_order);
      this.listSection = data.DATA;
    } else {
      this.$router.push("/");
    }

    document.body.addEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      const isSectionClicked = event.target.closest(".bnsl-section");
      const isPaketClicked = event.target.closest(".bnsl-paket");

      if (!isSectionClicked && !isPaketClicked) {
        this.showSection = false;
        this.showPaket = false;
      }
    },
    toggleDropdown() {
      this.showSection = !this.showSection;
    },
    tooglePaket() {
      if (this.selectedSection) {
        this.showPaket = !this.showPaket;
      }
    },
    async getDataMember() {
      const { data } = await memberSession.getUser(this.session.usertoken);
      this.memberData = data.DATA;
      this.dataOrder.coin = this.memberData.COIN_ANDROID;
      this.dataOrder.mypoin = this.memberData.POIN;
    },
    async selectSection(section) {
      this.selectedSection = section;
      this.imageBoost = this.selectedSection.boosting_image;
      this.dataOrder.sectionId = this.selectedSection.home_id;
      const { data } = await BoostingController.ListPaketBoost(this.dataOrder.type_order, this.dataOrder.sectionId);
      this.listBooster = data.DATA;
      this.selectedPaket = null;
      this.showSection = false;
    },
    selectPaket(paket) {
      this.selectedPaket = paket;
      this.dataOrder.paketId = this.selectedPaket.id;
      this.dataOrder.price = this.selectedPaket.price_boosting;
      this.dataOrder.paketName = this.selectedPaket.boosting_name;
      this.dataOrder.antrian = this.selectedPaket.antrian;
      this.dataOrder.duration = this.selectedPaket.duration_day_boosting;
      this.dataOrder.status_prime = this.selectedPaket.status_prime;
      this.dataOrder.benefit = this.selectedPaket.benefit;
      this.dataOrder.kpoin = this.selectedPaket.kpoin;
      this.dataOrder.price_poin = this.selectedPaket.price_poin_boosting;
      this.showPaket = false;
      if (this.coinLeft < 0) {
        this.msg = "Coin kamu tidak cukup untuk menggunakan booster";
        this.errMsg = true;
      } else {
        this.errMsg = false;
      }
    },
    async openDetailBoost(target) {
      if (target == "about") {
        this.showAboutBoost = true;
      } else if (target == "popup") {
        const { data } = await BoostingController.CheckOrderBoost(this.dataOrder);
        if (data.STATUS == 200) {
          this.estimasi = data.DATA.estimasi_tanggal;
          this.showPopupBoost = true;
        } else {
          this.msg = data.MESSAGE;
          this.errMsg = true;
        }
      }
    },
    closeBoostModal() {
      this.showAboutBoost = false;
      this.showPopupBoost = false;
    },
    redirectBack() {
      this.$router.push("/detail-novel/" + this.dataOrder.contentId);
    },
  },
  computed: {
    coinLeft() {
      if (this.selectedPaket) {
        return this.memberData.COIN_ANDROID - this.selectedPaket.price_boosting;
      } else {
        return 0;
      }
    },
    isAbleOrder() {
      if (this.isChecked && this.selectedPaket && this.selectedSection) {
        return true;
      }
    },
    imgBoost() {
      if (this.dataOrder.type_order == 1) {
        return require("../../assets/boosting/Home.png");
      } else {
        return require("../../assets/boosting/Novel.png");
      }
    },
  },
  components: {
    AboutBoost,
    PopupBoost,
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style>
@import url("./css/boost-karya.css");
</style>

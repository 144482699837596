<template>
  <div class="earning-section">
    <div class="nav-earning">
      <router-link to="/wallet">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Wallet</p>
      <div class="divide">/</div>
      <p>History Pendapatan</p>
    </div>

    <div class="earning-tables-section">
      <h3 class="earning-title">History Pendapatan</h3>
      <div class="earning-head">
        <div class="earning-filter">
          <form @submit.prevent="fetchHistory">
            <p class="label">Urutkan Berdasarkan</p>
            <select v-model="rangeDay">
              <option value="1">Hari ini</option>
              <option value="7">7 hari terakhir</option>
              <option value="30">1 bulan terakhir</option>
              <option value="365">1 tahun terakhir</option>
              <!-- <option value="custom">Custom</option> -->
            </select>
          </form>
        </div>
        <!-- <div class="earning-export" v-if="list_history.length != 0">
                    <router-link to="/" class="export-earning-btn">Export <img src="@/assets/export.png"
                            alt=""></router-link>
                </div> -->
      </div>
      <div class="e__table-section" v-if="list_history.length == 0">
        <div class="e__table-body">
          <table>
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Judul</th>
                <th>Chapter QTY</th>
                <th>Rupiah</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="empty-tabel">
          <p style="text-align: center; margin: 10px 0">No records found.</p>
        </div>
      </div>
      <div class="e__table-section" v-else>
        <div class="e__table-body">
          <table>
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Judul</th>
                <th>Chapter QTY</th>
                <th>Rupiah</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(items, index) in paginatedData" :key="index">
                <td>{{ formattedDate[index] }}</td>
                <td>{{ items.content_title }}</td>
                <td>{{ items.qty }}</td>
                <td>Rp. {{ items.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pages-section">
          <p class="pages">{{ curentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="curentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="curentPage === 1">&lt; Sebelumnya</button>
            <button :class="curentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="curentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/controllers/Localstorage";
import earningController from "@/controllers/wallet";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Klaklik | Earning",
    };
  },
  data() {
    return {
      session: {},
      rangeDay: 365,
      list_history: [],
      curentPage: 1,
      totalDataPerPage: 5,
    };
  },
  async created() {
    this.session = storage.getLocalStorage();
    this.fetchHistory();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_HISTORY_EARNING,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard History Earning",
      page_path: "/history-earning",
      firebase_screen_class: "HistoryEarning",
      screen_class: "HistoryEarning",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_HISTORY_EARNING,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard History Earning",
      page_path: "/history-earning",
      firebase_screen_class: "HistoryEarning",
      screen_class: "HistoryEarning",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchHistory() {
      const { data } = await earningController.getHistory(this.session.memberId, this.session.usertoken, this.rangeDay);
      this.list_history = data.DATA;
    },
    paginateData(data) {
      const start = (this.curentPage - 1) * this.totalDataPerPage;
      const end = start + this.totalDataPerPage;
      return data.slice(start, end);
    },
    nextPage() {
      this.curentPage++;
    },
    prevPage() {
      this.curentPage--;
    },
  },
  computed: {
    formattedDate() {
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      return this.list_history.map((item) => {
        const date = new Date(item.created_at);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return `${day} ${months[monthIndex]} ${year}`;
      });
    },
    totalPages() {
      if (this.rangeDay) {
        return Math.ceil(this.list_history.length / this.totalDataPerPage);
      }
    },
    paginatedData() {
      if (this.rangeDay) {
        return this.paginateData(this.list_history);
      }
    },
  },
  watch: {
    rangeDay(newVal, oldVal) {
      this.fetchHistory();
    },
  },
};
</script>

<style>
@import url("../css/historyearning.css");
</style>

<template>
    <div class="modal" v-if="showPreChapter">
        <div class="modal-pc">
            <div class="modal-pc-head">
                <img src="@/assets/warn-modal.png" alt="">
                <p>Warning</p>
                <span class="closing" @click="$emit('close')">&times;</span>
            </div>
            <div class="modal-pc-body">
                <div class="modal-pc-body-desc">
                    <p>Pastikan chapter yang akan kamu tulis tidak mengandung kata - kata yang dilarang!</p>
                </div>
                <div class="modal-pc-body-warnlist">
                    <p>Cek daftar kata larangan klaklik <span @click="redirectTNC">disini</span></p>
                </div>
                <div class="modal-pc-body-btn">
                    <div @click="$emit('close')" class="pc-decline-btn">Decline</div>
                    <div @click="redirectStoreChapter" class="pc-accept-btn">Accept</div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "show-barcode",
    props: {
        showPreChapter: {
            type: Boolean,
            default: false
        },
        redirectStoreChapter: {
            type: Function,
            required: true
        },
        errorMsg: {
            type: String,
            default: ""
        }
    },
    data() {
        return {

        }
    },
    methods: {
        redirectTNC() {
            window.open(process.env.VUE_APP_TNC, "_blank")
        }
    }
}
</script>
    
<style>
@import url('./css/prechaptermodal.css');
</style>
  
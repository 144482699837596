<template>
  <div class="dpc-modal" v-if="showDeletePanel">
    <div class="dpc-content">
      <div class="dpc-head">
        <p>Apakah kamu yakin ingin menghapus panel?</p>
      </div>
      <div class="dpc-body">
        <button @click="$emit('close')">Batal</button>
        <button @click="deletePanel(panelid)">Hapus</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showDeletePanel: {
      type: Boolean,
      default: false,
    },
    panelid: {
      type: Number,
      default: 0,
    },
    deletePanel: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
@import url("./css/delete-panel.css");
</style>

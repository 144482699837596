<template>
  <div class="list-content-section">
    <div class="c__head">
      <div class="c__head-title">
        <h4>{{ res_summary.total_views_content }}</h4>
        <p>Total Viewers</p>
      </div>
      <div class="c__head-line"></div>
      <p class="c__head-desc">{{ dateTime }}</p>
    </div>
    <div class="data-tables-section">
      <div class="data__title">
        <p>List Karya {{ typeContent === "novel" ? "Novel" : "Comic" }}</p>
      </div>
      <div class="head-table-section">
        <div class="lc_date-filter">
          <form @submit.prevent="">
            <p class="label-date">Urutkan berdasarkan</p>
            <select v-model="rangeDay">
              <option value="1">Terbaru</option>
              <option value="0">Terlama</option>
            </select>
          </form>
        </div>
        <div class="input-search-table">
          <p class="label-search">Cari</p>
          <input type="text" placeholder="search here.." v-model="searchInput" />
        </div>
      </div>
      <div class="lc__table-section">
        <div class="c__table-body">
          <!-- List Novel Data Table -->
          <table v-if="typeContent == 'novel'" class="n__table">
            <thead class="n__table-head">
              <tr>
                <th>Last Update</th>
                <th>Thumbnail</th>
                <th>Judul</th>
                <th>Chapters</th>
                <th>Status</th>
                <th>Boost</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody v-if="filteredNovelList == 0">
              <tr>
                <td colspan="6" style="text-align: center">No records found.</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="items in filteredNovelList" :key="items.id">
                <td>{{ items.updated_at }}</td>
                <td><img :src="items.thumbnail" alt="thumbnail" /></td>
                <td>{{ items.title }}</td>
                <td>{{ items.total_chapter }}</td>
                <td style="text-transform: capitalize">{{ items.approve }}</td>
                <td>
                  <div class="boost-expired" v-if="items.date_expired_boosting != ''">
                    <span>{{ calculateExpiredBoost(items.date_expired_boosting) }}</span>
                  </div>
                  <div
                    class="boost-status"
                    :class="items.total_chapter < 8 ? 'disabled-boost' : ''"
                    v-else
                    @click="openOptionBoost(items.date_expired_boosting, items.total_chapter, items.id, items.title)"
                  >
                    <img src="../../assets/boosting/rocket.svg" alt="" v-if="items.total_chapter >= 8" />
                    <img src="../../assets/boosting/rocket-off.svg" alt="" v-else />
                    <span>Boost</span>
                  </div>
                </td>
                <td>
                  <router-link :to="{ name: 'detail-novel', params: { id: items.id } }" class="detail-content"> Detail </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- List Comic Data Table -->
          <table v-else-if="typeContent == 'comic'">
            <thead>
              <tr>
                <th>Last Update</th>
                <th>Thumbnail</th>
                <th>Judul</th>
                <th>Episode</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody v-if="filteredComicList == 0">
              <tr>
                <td colspan="6" style="text-align: center">No records found.</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="items in filteredComicList" :key="items.id">
                <td>{{ items.created_at }}</td>
                <td><img :src="items.thumbnail" alt="thumbnail" /></td>
                <td>{{ items.title }}</td>
                <td>{{ items.total_chapter }}</td>
                <td>{{ items.approve }}</td>
                <td>
                  <router-link :to="{ name: 'detail-comic', params: { id: items.id } }" class="detail-content"> Detail </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pages-section" v-if="listnovel !== 0 && listcomic !== 0">
          <p class="pages">{{ curentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="curentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="curentPage === 1">&lt; Sebelumnya</button>
            <button
              :class="curentPage === totalPages ? 'disabled-next' : 'next'"
              @click="nextPage"
              :disabled="curentPage === totalPages || filteredNovelList == 0"
            >
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
    <option-boost :showOptionBoost="showOptionBoost" @close="closeModal" :redirectBoost="redirectBoost"></option-boost>
  </div>
</template>

<script>
import storage from "@/controllers/Localstorage";
import contentController from "@/controllers/content";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import { useMeta } from "vue-meta";
import OptionBoost from "@/components/boosting/OptionBoost.vue";

export default {
  setup() {
    useMeta({ title: "List Karya Novel" });
  },
  data() {
    return {
      session: {},
      rangeDay: 1,
      typeContent: "",
      listnovel: [],
      listcomic: [],
      searchInput: "",
      offset: 0,
      curentPage: 1,
      totalData: 0,
      novelid: 0,
      noveltitle: "",

      showOptionBoost: false,
    };
  },
  async created() {
    this.session = storage.getLocalStorage();
    // this.fetchContent();
    // if (this.session.memberId) {
    //   await this.fetchSummary({
    //     member_id: this.session.memberId,
    //     usertoken: this.session.usertoken,
    //     range_day: this.rangeDay,
    //   });

    //   this.res_summary;
    // }
    this.getTypeContentFromLocalStorage();

    this.fetchTypeContent();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_LIST_CONTENT_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard ListContent Novel",
      page_path: "/table-content",
      firebase_screen_class: "ListAllContent",
      screen_class: "ListAllContent",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_LIST_CONTENT_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard ListContent Novel",
      page_path: "/table-content",
      firebase_screen_class: "ListAllContent",
      screen_class: "ListAllContent",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  async mounted() {
    if (this.session.memberId) {
      await this.fetchSummary({
        member_id: this.session.memberId,
        usertoken: this.session.usertoken,
        // range_day: this.rangeDay,
      });

      this.res_summary;
    } else {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    expiredBoost() {},
    filteredNovelList() {
      return this.listnovel.filter((item) => item.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
    filteredComicList() {
      return this.listcomic.filter((item) => item.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
    totalPages() {
      return Math.ceil(this.totalData / 5);
    },
    ...mapState({
      summary: (state) => state.summary.res_summary,
      rangeDay: (state) => state.rangeDay,
    }),
    ...mapGetters("summary", {
      res_summary: "get_res_summary",
    }),
    ...mapGetters("user", {
      user: "get_user",
    }),

    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
  },
  methods: {
    async fetchContent() {
      const { data } = await contentController.ListNovel(this.session.memberId, this.rangeDay, this.offset);
      this.listnovel = data.DATA;
      this.totalData = data.TOTAL;
    },
    async fetchComic() {
      const { data } = await contentController.ListComic(this.session.memberId, this.rangeDay, this.offset);
      this.listcomic = data.DATA;
      this.totalData = data.TOTAL;
    },
    getTypeContentFromLocalStorage() {
      const storedTypeContent = localStorage.getItem("typeContent");
      if (storedTypeContent === "comic") {
        this.typeContent = "comic";
      } else {
        this.typeContent = "novel";
      }
    },
    async fetchTypeContent() {
      localStorage.setItem("typeContent", this.typeContent);

      this.offset = 0;
      const { data } = await contentController.ListNovel(this.session.memberId, this.rangeDay, this.offset);
      this.listnovel = data.DATA;
      this.totalData = data.TOTAL;
    },
    async searchContent() {
      if (this.searchInput === "") {
        this.curentPage = 1;
        await this.fetchTypeContent();
      } else {
        this.offset = 0;
        if (this.typeContent === "novel") {
          const { data } = await contentController.ListNovelSearch(this.session.memberId, this.searchInput, 5, 0);
          this.listnovel = data.DATA;
          this.totalData = data.TOTAL;
        } else {
          const { data } = await contentController.ListComicSearch(this.session.memberId, this.searchInput, 5, 0);
          this.listcomic = data.DATA;
          this.totalData = data.TOTAL;
        }
        this.curentPage = 1;
      }
    },
    async nextPage() {
      if (this.searchInput != "") {
        this.offset += 5;
        if (this.typeContent == "novel") {
          const { data } = await contentController.ListNovelSearch(this.session.memberId, this.searchInput, 5, this.offset);
          this.listnovel = data.DATA;
          this.totalData = data.TOTAL;
        } else {
          const { data } = await contentController.ListComicSearch(this.session.memberId, this.searchInput, 5, this.offset);
          this.listcomic = data.DATA;
          this.totalData = data.TOTAL;
        }
        this.curentPage++;
      } else {
        this.offset += 5;
        if (this.typeContent == "novel") {
          await this.fetchContent();
        } else {
          await this.fetchComic();
        }
        this.curentPage++;
      }
    },
    async prevPage() {
      this.offset -= 5;
      if (this.searchInput != "") {
        if (this.typeContent == "novel") {
          const { data } = await contentController.ListNovelSearch(this.session.memberId, this.searchInput, 5, this.offset);
          this.listnovel = data.DATA;
          this.totalData = data.TOTAL;
        } else {
          const { data } = await contentController.ListComicSearch(this.session.memberId, this.searchInput, 5, this.offset);
          this.listcomic = data.DATA;
          this.totalData = data.TOTAL;
        }
        this.curentPage--;
      } else {
        if (this.typeContent == "novel") {
          await this.fetchContent();
        } else {
          await this.fetchComic();
        }
        this.curentPage--;
      }
    },

    calculateExpiredBoost(expirationDate) {
      const expiration = new Date(expirationDate);
      const now = new Date();
      const timeDifference = expiration.getTime() - now.getTime();
      const secondsDifference = Math.ceil(timeDifference / 1000);

      if (secondsDifference < 0) {
        return;
      } else {
        let displayText;
        if (secondsDifference < 86400) {
          // kurang dari sehari
          const hours = Math.floor(secondsDifference / 3600);
          const minutes = Math.floor((secondsDifference % 3600) / 60);
          displayText = `${hours} jam `;
        } else if (secondsDifference < 3600) {
          // kurang dari 1 jam
          const minutes = Math.floor(secondsDifference / 60);
          displayText = `${minutes} menit`;
        } else {
          // berapa hari
          const days = Math.floor(secondsDifference / 86400);
          const hours = Math.floor((secondsDifference % (3600 * 24)) / 3600);
          const minutes = Math.floor((secondsDifference % 3600) / 60);
          const seconds = secondsDifference % 60;
          displayText = `${days} hari ${hours} jam`;
        }
        return displayText;
      }
    },

    openOptionBoost(expired, chapter, id, title) {
      if (expired == "" && chapter >= 8) {
        this.novelid = id;
        this.noveltitle = title;
        this.showOptionBoost = true;
      } else {
        return;
      }
    },

    redirectBoost(target) {
      this.$router.push("/boost-karya/novel" + "-" + this.novelid + "-" + target + "-" + this.noveltitle);
    },

    closeModal() {
      this.showPreChapter = false;
      this.showCompleteModal = false;
      this.showDeleteChapter = false;
      this.showAboutHiatus = false;
      this.showFinishPopup = false;
      this.showHiatusPopup = false;
      this.showWarnHiatus = false;
      this.showOptionBoost = false;
    },

    ...mapActions({
      fetchSummary: "summary/fetchSummary",
    }),
  },
  watch: {
    typeContent(newType, oldType) {
      this.fetchTypeContent();
      this.curentPage = 1;
    },
    rangeDay(newVal, oldVal) {
      if (this.typeContent === "novel") {
        this.fetchContent();
        this.curentPage = 1;
      } else {
        this.fetchComic();
        this.curentPage = 1;
      }
    },
    searchInput() {
      this.searchContent();
    },
  },
  components: {
    OptionBoost,
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #8eb5db;
  color: #ffffff;
}

th {
  padding: 10px;
}

td {
  border-bottom: 2px solid #e4e4e4;
}

th,
td {
  text-align: center;
  padding: 10px;
}

td > img {
  width: 40px;
}
@import url("./css/list-content.css");
</style>

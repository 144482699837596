<template>
    <div class="modal" v-if="showModal">
        <div class="modal-delete-novel">
            <div class="modal-delete-head">
                <p>{{ warnMsg }}</p>
            </div>
            <div class="modal-delete-body">
                <button @click="$emit('close')">Batal</button>
                <button @click="handleDelete">Hapus</button>
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    name: "show-barcode",
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        warnMsg: {
            type: String,
            default: ''
        },
        handleDelete: {
            type: Function,
            required: true
        }
    },
}
</script>
    
<style>
@import url('./css/modalnovel.css');
</style>
  
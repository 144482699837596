<template>
  <div class="modal" v-if="showHiatusPopup">
    <div class="modal-hiatus-section">
      <div class="modal-hiatus-head">
        <p>Apa Kamu Yakin Ingin Memasuki Masa Hiatus?</p>
      </div>
      <div class="modal-hp-btn">
        <button @click="$emit('close')">Batal</button>
        <button @click="submitHiatus">Hiatuskan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showHiatusPopup: {
      type: Boolean,
      default: false,
    },
    submitHiatus: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
@import url("./css/hiatus-popup.css");
</style>

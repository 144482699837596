<template>
  <div class="eps-chatstory-modal" v-if="showStoreEpsCS">
    <div class="eps-chatstory-section">
      <div class="epscs-head" style="display: flex; align-items: center; justify-content: center">
        <div class="epscs-title" style="font-weight: 600">Tambah Chapter</div>
      </div>

      <input
        type="text"
        placeholder="Masukkan Judul Chapter"
        class="epscs-input"
        v-model="titleEps"
        style="padding: 8px; border-radius: 5px; border: 1px solid #000"
      />
      <div class="btn-edit-char">
        <button class="acs-btn-delete" @click="closeModal" style="color: #8eb5db">Batal</button>
        <button class="acs-btn-submit" @click="handleSubmit">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import chatStoryController from "@/controllers/chatstory";
import { toast } from "vue3-toastify";

export default {
  props: {
    showStoreEpsCS: {
      type: Boolean,
      default: false,
    },
    chatstoryid: {
      type: Number,
    },
  },
  data() {
    return {
      titleEps: "",
    };
  },
  methods: {
    async handleSubmit() {
      if (this.titleEps != " ") {
        const { data } = await chatStoryController.StoreEpisode(this.chatstoryid, this.titleEps);
        if (data.STATUS == 201) {
          this.$router.push("/edit-chapter-chatstory/" + this.chatstoryid + "-" + data.DATA.id + "-" + data.DATA.title);
          toast.success("Episode Berhasil Dibuat!", {
            autoClose: 2000,
            pauseOnHover: false,
            position: toast.POSITION.TOP_RIGHT,
            transition: toast.TRANSITIONS.FLIP,
          });
        }
      } else {
        alert("Judul tidak boleh kosong!");
      }
    },
    closeModal() {
      this.titleEps = "";
      this.$emit("close");
    },
  },
};
</script>

<style>
@import url("./css/create-eps.css");
</style>

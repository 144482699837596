<template>
  <div class="modal" v-if="showModal">
    <div class="modal-novel">
      <div class="store-novel-head">
        <span class="closing" @click="$emit('close')">&times;</span>
      </div>
      <div class="store-novel-img">
        <img src="@/assets/store-modal-img.png" alt="" />
      </div>
      <div class="store-novel-body">
        <p>Apakah kamu yakin ingin publish konten?</p>
      </div>
      <div class="store-novel-btn">
        <button @click="handleStore(3)">SIMPAN KE DRAFT</button>
        <button v-if="!isPost" @click="handleStore(1)">PUBLIKASIKAN</button>
        <button v-else>Waiting...</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "show-barcode",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    handleStore: {
      type: Function,
      required: true,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
@import url("./css/modalnovel.css");
</style>

<template>
  <div v-if="showAddChar" class="add-char-modal">
    <div class="add-char-section">
      <div class="acs-head">
        <div></div>
        <div class="acs-title">Tambah Karakter</div>
        <div class="acs-close" @click="closeThisModal">X</div>
      </div>
      <label for="">Avatar</label>
      <div class="acs-img-section">
        <div class="acs-img">
          <img src="@/assets/chatstory/img-null.png" alt="" v-if="previewImage == null" />
          <img :src="previewImage" alt="" v-else />
          <img src="@/assets/chatstory/icon-edit-blue.png" alt="" @click="openGallery" />
          <input type="file" id="fileInput" ref="fileInput" @change="uploadThumbnail" />
        </div>
      </div>
      <div class="acs-input-section">
        <label for="">Nama</label>
        <input type="text" placeholder="Input Nama" v-model="nama" />
      </div>
      <div class="acs-input-section">
        <label for="">Posisi (Buble Chat)</label>
        <select v-model="selectedPosition">
          <option value="0">Pengirim (Kanan)</option>
          <option value="1">Penerima (Kiri)</option>
        </select>
      </div>
      <button class="acs-btn-submit" @click="createChar">Simpan Karakter</button>
    </div>
  </div>
</template>

<script>
import chatstoryController from "@/controllers/chatstory";
import imageController from "@/controllers/images";

export default {
  props: {
    showAddChar: {
      type: Boolean,
      default: false,
    },
    chatstoryid: {
      type: Number,
    },
    tambahChar: {
      type: Function,
    },
    getListChar: {
      type: Function,
    },
  },
  data() {
    return {
      previewImage: null,
      nama: null,
      selectedPosition: "0",
      charImage: "",
    };
  },
  methods: {
    openGallery() {
      // Memanggil klik pada input file tersembunyi saat tombol di klik
      this.$refs.fileInput.click();
    },
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.charImage = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.previewImage = reader.result;
      };
    },
    async StoreImage() {
      if (this.charImage != "" || this.charImage != undefined || this.charImage != null) {
        const { status, data } = await imageController.PostImage(this.charImage);
        return data;
      } else {
        // change alert wiht something
        console.log("error");
      }
    },
    async createChar() {
      if (this.previewImage != null && this.nama != null) {
        const res_image = await this.StoreImage();
        if (res_image.STATUS == 200) {
          const { data } = await chatstoryController.CreateChar(this.chatstoryid, this.nama, res_image.DATA, this.selectedPosition);
          if (data.STATUS == 201) {
            this.closeThisModal();
            this.getListChar();
          }
        }
      } else {
        this.previewImage = "img-null.png"
        const { data } = await chatstoryController.CreateChar(this.chatstoryid, this.nama, this.previewImage, this.selectedPosition);
          if (data.STATUS == 201) {
            this.closeThisModal();
            this.getListChar();
          }
      }
    },
    closeThisModal() {
      this.charImage = null;
      this.previewImage = null;
      this.nama = null;
      this.selectedPosition = "0";
      this.$emit("close");
    },
  },
  beforeDestroy() {
    if (this.previewImage) {
      URL.revokeObjectURL(this.previewImage);
    }
  },
};
</script>

<style>
@import url("./css/add-char.css");
</style>

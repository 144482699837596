<template>
  <div class="navbar">
    <nav>
      <div
        class="nav-item"
        :class="{
          active: $route.name === 'summary',
          inactive: $route.name !== 'summary',
        }"
        @click="redirectNav(1)"
      >
        Summary
      </div>
      <!-- <router-link
        :to="{ path: '/' + user.USERTOKEN }"
        class="nav-item"
        :class="{
          active: $route.name === 'summary',
          inactive: $route.name !== 'summary',
        }"
      >
        Summary
      </router-link> -->
      <div class="nav-item-web" @click="openType">
        <p :class="{ active: isContentActive, inactive: !isContentActive }">Content &#x25BE;</p>
        <div class="list-type-content" v-show="showType">
          <div class="type-novel" @click="redirectType(1)">
            <div class="dropdown-novel"><img src="@/assets/new-novel-icon.png" alt="" /> Novel</div>
          </div>
          <div class="type-comic" @click="redirectType(2)">
            <div class="dropdown-comic"><img src="@/assets/new-comic-icon.png" alt="" /> Comic</div>
          </div>
          <div class="type-comic" @click="redirectType(3)">
            <div class="dropdown-comic"><img src="@/assets/chatstory.png" alt="" /> Chat Story</div>
          </div>
        </div>
      </div>
      <div class="nav-content-mobile">
        <div class="nav-item" @click="openType">
          <p :class="{ active: isContentActive, inactive: !isContentActive }">Content &#x25BE;</p>
        </div>
      </div>

      <div class="list-mobile-content" v-show="showType">
        <div class="item-mobile-novel" @click="redirectTypeMobile(1)"><img src="@/assets/new-novel-icon.png" alt="" /> Novel</div>
        <div class="divide-content"></div>
        <div class="item-mobile-comic" @click="redirectTypeMobile(2)"><img src="@/assets/new-comic-icon.png" alt="" /> Comic</div>
        <div class="divide-content"></div>
        <div class="item-mobile-comic" @click="redirectTypeMobile(5)"><img src="@/assets/chatstory.png" alt="" /> ChatStory</div>
      </div>
      <div class="nav-content-mobile">
        <div class="nav-item" @click="openWallet">
          <p :class="{ active: isWalletActive, inactive: !isWalletActive }">Transactions &#x25BE;</p>
        </div>
      </div>
      <div class="list-mobile-content" v-show="showDropdown">
        <div class="item-mobile-novel" @click="redirectTypeMobile(3)">Wallet</div>
        <div class="divide-content"></div>
        <div class="item-mobile-comic" @click="redirectTypeMobile(4)">Booster</div>
      </div>
      <div
        class="nav-item"
        :class="{
          active: $route.name === 'followers',
          inactive: $route.name !== 'followers',
        }"
        @click="redirectNav(3)"
      >
        Followers
      </div>

      <div class="nav-item-web">
        <p :class="{ active: isWalletActive, inactive: !isWalletActive }" @click="openWallet">Transactions &#x25BE;</p>
        <div class="list-transaction-type" v-show="showDropdown">
          <div class="type-wallet" @click="redirectNav(4)">Wallet</div>
          <div class="type-booster" @click="redirectNav(5)">Booster</div>
        </div>
      </div>
    </nav>
    <div class="nav-btn">
      <div @click="toggleUpload" class="nav-btn-upload">
        <img src="@/assets/upload-karya-icon.png" alt="" />
        <p>Upload Karya</p>
      </div>
      <div class="dropdown-mobile" v-show="showUpload">
        <div class="mobile-novel" @click="redirectNovel">Novel</div>
        <div class="mobile-comic" @click="redirectComic">Comic</div>
        <div class="mobile-comic" @click="redirectChatStory">Chat</div>
      </div>
      <div class="logout-mobile" @click="handleLogout">
        <img src="@/assets/icon-logout.png" alt="" class="icon-logout" />
        <p>Keluar</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import storage from "@/controllers/Localstorage";

export default {
  name: "navbar",
  props: {
    redirectNovel: {
      type: Function,
      required: true,
    },
    redirectComic: {
      type: Function,
      required: true,
    },
    closeNav: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      session: {},
      showUpload: false,
      showType: false,
      showDropdown: false,
    };
  },
  async mounted() {
    this.fetchUserSession();
    document.body.addEventListener("click", this.handleOutsideClick);
  },
  methods: {
    redirectNav(navid) {
      if (navid === 1) {
        this.$router.push({ path: "/summary" });
        this.closeNav();
      } else if (navid === 3) {
        this.$router.push({ name: "followers" });
        this.closeNav();
      } else if (navid === 4) {
        this.$router.push({ name: "wallet" });
        this.openWallet();
        this.closeNav();
      } else if (navid === 5) {
        this.$router.push({ name: "list-boost-karya" });
        this.openWallet();
        this.closeNav();
      }
    },
    redirectChatStory() {
      this.$router.push("/store-chat-story");
      this.closeNav();
    },
    openType() {
      this.showType = !this.showType;
    },
    openWallet() {
      this.showDropdown = !this.showDropdown;
    },
    async redirectType(type) {
      if (type === 1) {
        this.$router.push({ name: "table-content" });
      } else if (type == 2) {
        this.$router.push({ name: "table-comic" });
      } else if (type == 3) {
        this.$router.push("/list-karya-chatstory");
      }
    },
    async redirectTypeMobile(type) {
      if (type === 1) {
        this.$router.push({ name: "table-content" });
        this.showType = false;
        this.closeNav();
      } else if (type == 2) {
        this.$router.push({ name: "table-comic" });
        this.showType = false;
        this.closeNav();
      } else if (type == 3) {
        this.$router.push({ name: "wallet" });
        this.openWallet();
        this.closeNav();
      } else if (type == 4) {
        this.$router.push({ name: "list-boost-karya" });
        this.openWallet();
        this.closeNav();
      } else if (type == 5) {
        this.$router.push({ name: "list-karya-chatstory" });
        this.showType = false;
        this.closeNav();
      }
    },
    async fetchUserSession() {
      const usertoken = this.$route.params.usertoken;
      if (usertoken != undefined) {
        await this.fetchUser(usertoken);
      } else {
        this.session = storage.getLocalStorage();
        await this.fetchUser(this.session.usertoken);
      }
    },
    async handleLogout() {
      this.session = storage.getLocalStorage();
      var datarequest = {
        memberId: this.session.memberId,
        memberEmail: this.session.memberEmail,
        firstname: this.session.firstname,
        lastname: this.session.lastname,
        aboutMe: this.session.aboutMe,
        memberAddress: this.session.memberAddress,
        memberBod: this.session.memberBod,
        memberPhone: this.session.memberPhone,
        memberImage: this.session.memberImage,
        keyActived: this.session.keyActived,
        keyMember: this.session.keyMember,
        token: this.session.token,
        usertoken: this.session.usertoken,
      };
      storage.RemoveLocalStorage(datarequest);
      localStorage.removeItem("typeContent");
      window.location.href = "/";
    },
    ...mapActions({
      fetchUser: "user/fetchUser",
    }),
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    handleOutsideClick(event) {
      const isNavClicked = event.target.closest(".navbar");

      // Jika elemen yang diklik bukan bagian dari navbar, atur showType menjadi false
      if (!isNavClicked) {
        this.showType = false;
      }
    },
  },
  computed: {
    isContentActive() {
      return this.$route.matched.some(
        (record) =>
          record.name === "content" ||
          record.name === "table-content" ||
          record.name === "store-novel" ||
          record.name === "detail-novel" ||
          record.name === "edit-novel" ||
          record.name === "analytic-novel" ||
          record.name === "create-chapter" ||
          record.name === "edit-chapter" ||
          record.name === "detail-comic" ||
          record.name === "store-comic" ||
          record.name === "edit-comic" ||
          record.name === "store-episode" ||
          record.name === "update-episode" ||
          record.name === "list-panel-eps" ||
          record.name === "table-novel" ||
          record.name === "table-comic" ||
          record.name === "preview-chapter" ||
          record.name === "boost-karya" ||
          record.name === "analytic-comic" ||
          record.name === "detail-chat-story" ||
          record.name === "store-chat-story" ||
          record.name === "store-chapter-chatstory" ||
          record.name === "edit-chapter-chatstory" ||
          record.name === "list-karya-chatstory"
      );
    },
    isWalletActive() {
      return this.$route.matched.some(
        (record) =>
          record.name === "wallet" ||
          record.name === "history-withdraw" ||
          record.name === "list-member-affiliate" ||
          record.name === "detail-member-affiliate" ||
          record.name === "detail-content-affiliate" ||
          record.name === "detail-comic-affiliate" ||
          record.name === "list-boost-karya" ||
          record.name === "history-earning"
      );
    },
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style>
.nav-item {
  cursor: pointer;
}
@import url("../main.css");
</style>

<template>
  <div class="af-nav-detailmember">
    <div class="nav-af-detailmember">
      <router-link to="/list-member-affiliate">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>List Member</p>
      <div class="divide">/</div>
      <p>Detail Member</p>
    </div>
  </div>
  <div class="af-detailmember">
    <div class="af-detail-memberhead">
      <div class="memberhead-profile">
        <div class="memberhead-profile-img">
          <img :src="memberDetail.member_image" alt="" />
        </div>
        <div class="memberhead-profile-name">
          <h4>{{ memberDetail.name }}</h4>
        </div>
      </div>
      <div class="line"></div>
      <div class="memberhead-detail">
        <div class="memberhead-detail-item">
          <h4>Total Views</h4>
          <p>{{ memberDetail.total_views }}</p>
        </div>
        <div class="memberhead-detail-item">
          <h4>Total Judul</h4>
          <p>{{ memberDetail.total_content }}</p>
        </div>
        <div class="memberhead-detail-item">
          <h4>Total Chapter Berbayar</h4>
          <p>{{ memberDetail.total_chapter_berbayar }}</p>
        </div>
        <div class="memberhead-detail-item">
          <h4>Total Pendapatan</h4>
          <p>{{ memberDetail.pendapatan }}</p>
        </div>
      </div>
    </div>
    <div class="afdetail-membertable">
      <h4>List Karya</h4>
      <div class="afdetail-filter-controls">
        <div class="afdetail-filter-left">
          <div class="afdetail-filter-type">
            <label for="">Filter Berdasarkan Tipe</label>
            <select v-model="contentType">
              <option value="1">Novel</option>
              <option value="2">Comic</option>
            </select>
          </div>
          <div class="afdetail-filter-page">
            <label for="">Tampilkan Per halaman</label>
            <select v-model="pageSize">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
          </div>
        </div>
        <div class="afdetail-filter-search">
          <label for="">Cari</label>
          <input type="text" placeholder="search here" v-model="searchInput" />
        </div>
      </div>
      <div class="afdetail-table-section">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Title</th>
              <th>Earnings</th>
              <th>Total Chapter</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody v-if="listMemberContent.length === 0">
            <tr>
              <td colspan="5">No records found</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(content, index) in filteredContentList" :key="index">
              <td class="td-img">
                <span>{{ index + 1 }}</span> <img :src="content.thumbnail" alt="" />
              </td>
              <td>{{ content.title }}</td>
              <td>{{ content.total_earning }}</td>
              <td>{{ content.total_chapter }}</td>
              <td v-if="contentType !== 1">
                <img src="@/assets/next-icon.png" alt="" class="laf-next-icon" @click="redirectDetailContent(content.id)" />
              </td>
              <td v-else><img src="@/assets/next-icon.png" alt="" class="laf-next-icon" @click="redirectDetailContent(content.id)" /></td>
            </tr>
          </tbody>
        </table>
        <div class="pages-section">
          <p class="pages" v-if="totalPages !== 0">{{ currentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page" v-if="listMemberContent.length !== 0">
            <button :class="currentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="currentPage === 1">&lt; Sebelumnya</button>
            <button :class="currentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="currentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletController from "@/controllers/wallet";
import { mapGetters } from "vuex";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Klaklik | List Member Affiliate",
    };
  },
  data() {
    return {
      memberAgentId: 0,
      memberDetail: {},
      listMemberContent: [],
      contentType: 1,
      pageSize: 5,
      offset: 0,
      currentPage: 1,
      totalData: 0,
      searchInput: "",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.memberAgentId = id;
    this.getMemberDetail();
    this.getListContent();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_DETAIL_MEMBER_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Member Affiliate",
      page_path: "/detail-member-affiliate",
      firebase_screen_class: "DetailMemberAF",
      screen_class: "DetailMemberAF",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_DETAIL_MEMBER_AFFILIATE,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Member Affiliate",
      page_path: "/detail-member-affiliate",
      firebase_screen_class: "DetailMemberAF",
      screen_class: "DetailMemberAF",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async getMemberDetail() {
      const { data } = await walletController.MemberDetail(this.memberAgentId);
      this.memberDetail = data.DATA;
    },
    async getListContent() {
      const { data } = await walletController.ListContentEarning(this.memberAgentId, this.contentType, this.pageSize, this.offset);
      this.listMemberContent = data.DATA;
      this.totalData = data.TOTAL;
    },
    async nextPage() {
      if (this.searchInput !== "") {
        this.currentPage++;
        this.offset = (this.currentPage - 1) * this.pageSize;
        const { data } = await walletController.ListContentEarningSearch(
          this.memberAgentId,
          this.contentType,
          this.searchInput,
          this.pageSize,
          this.offset
        );
        this.listMemberContent = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage++;
      } else {
        this.currentPage++;
        this.offset = (this.currentPage - 1) * this.pageSize;
        await this.getListContent();
      }
    },
    async prevPage() {
      if (this.searchInput !== "") {
        this.currentPage--;
        this.offset -= this.pageSize;
        const { data } = await walletController.ListContentEarningSearch(
          this.memberAgentId,
          this.contentType,
          this.searchInput,
          this.pageSize,
          this.offset
        );
        this.listMemberContent = data.DATA;
        this.totalData = data.TOTAL;
      } else {
        this.currentPage--;
        this.offset = (this.currentPage - 1) * this.pageSize;
        await this.getListContent();
      }
    },
    async searchContent() {
      if (this.searchInput === "") {
        this.offset = 0;
        this.currentPage = 1;
        this.getListContent();
      } else {
        this.offset = 0;
        const { data } = await walletController.ListContentEarningSearch(
          this.memberAgentId,
          this.contentType,
          this.searchInput,
          this.pageSize,
          this.offset
        );
        this.listMemberContent = data.DATA;
        this.totalData = data.TOTAL;
        this.currentPage = 1;
      }
    },
    redirectDetailContent(id) {
      if (this.contentType === 1) {
        this.$router.push("/detail-content-affiliate/" + id);
      } else {
        this.$router.push("/detail-comic-affiliate/" + id);
      }
    },
  },
  computed: {
    filteredContentList() {
      return this.listMemberContent.filter((item) => item.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
    totalPages() {
      return Math.ceil(this.totalData / this.pageSize);
    },
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
  watch: {
    contentType(newType, oldType) {
      this.offset = 0;
      this.currentPage = 1;
      this.searchContent();
      this.totalPages();
    },
    pageSize(newType, oldType) {
      this.offset = 0;
      this.currentPage = 1;
      this.searchContent();
      this.totalPages();
    },
    searchInput() {
      this.searchContent();
    },
  },
};
</script>

<style>
@import url("./css/detailmemberaf.css");
</style>

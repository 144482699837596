<template>
    <div class="modal-edit-panel" v-if="showPopupEdit">
        <div class="content-edit-panel">
            <div class="cep-btn-close" @click="this.$emit('close')">
                X
            </div>
            <div class="cep-img">
                <img v-if="thumbnailUrl === ''" :src="dataPanel.file" alt="" class="panel-img">
                <img :src="thumbnailUrl" v-else class="panel-img" />
            </div>
            <div class="cep-btn-upload" @click="openFolder">
                Ganti Panel
            </div>
            <input type="file" id="inputFile" ref="fileInput" @change="uploadThumbnail" />
            <div class="cep-page">
                <label for="">Halaman ke :</label>
                <input type="text" placeholder="halaman" v-model="dataPanel.page">
            </div>
            <div class="cep-btn-save" @click="handleUpdate">
                Simpan
            </div>
        </div>
    </div>
</template>

<script>
import contentController from "@/controllers/content"
import imageController from "@/controllers/images"

export default {
    props: {
        showPopupEdit: {
            type: Boolean,
            default: false
        },
        usertoken: {
            type: String,
            default: ''
        },
        episodeid: {
            type: Number,
            default: 0
        },
        dataPanel: {
            type: Object,
            default: {}
        },
        getListPanel: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            thumbnailUrl: '',
            file: '',
        }
    },
    async mounted() {
        console.log(this.dataPanel.file)
    },
    methods: {
        openFolder() {
            document.getElementById('inputFile').click()
        },
        uploadThumbnail() {
            const fileReader = this.$refs.fileInput.files[0];
            this.file = fileReader;
            const reader = new FileReader();
            reader.readAsDataURL(fileReader);
            reader.onload = () => {
                this.thumbnailUrl = reader.result;
            };
        },
        async StoreImage() {
            if (this.file != "" || this.file != undefined || this.file != null) {
                const { status, data } = await imageController.PostImage(this.file);
                return data;
            } else {
                // change alert wiht something
                alert('something wrong')
            }
        },
        async handleUpdate() {
            if (this.file == "") {
                const url = this.dataPanel.file
                const fileName = url.slice(url.indexOf("media/"));
                const { data } = await contentController.UpdatePanelComic(this.dataPanel.id, this.episodeid, fileName, this.dataPanel.page);
                this.$emit('close')
                this.getListPanel(this.episodeid)
            } else {
                const images = await this.StoreImage()
                const { data } = await contentController.UpdatePanelComic(this.dataPanel.id, this.episodeid, images.DATA, this.dataPanel.page);
                this.$emit('close')
                this.getListPanel(this.episodeid)

            }
        }
    }
}
</script>

<style>
#inputFile {
    display: none
}

@import url('./css/popup-edit-panel.css');
</style>
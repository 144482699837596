<template>
    <div v-if="karya.length == 0" class="content-empty">
        <div class="c__empty">
            <img src="@/assets/content-null.png" alt="content-null">
            <div class="c__empty-msg">
                <h4>Kamu belum ada karya nih</h4>
                <p>Upload karyamu di Klaklik dan mulai ......</p>
            </div>
            <div class="c__empty-btn">
                <button>+ Upload Karya</button>
            </div>
        </div>
    </div>
    <div class="content" id="content-section" v-else>
        <div class="c__head">
            <div class="c__head-title">
                <h4>{{ res_summary.total_views_content }}</h4>
                <p>Total Viewers</p>
            </div>
            <div class="c__head-line"></div>
            <p class="c__head-desc">{{ dateTime }}</p>
        </div>
        <div class="c__table-section">
            <p class="c__table-title">List Karya</p>
            <div class="c__table-body">
                <table>
                    <thead>
                        <tr>
                            <th>Last Update</th>
                            <th>Thumbnail</th>
                            <th>Judul</th>
                            <th>Chapters</th>
                            <th>Status</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="items in karya.slice(0, 3)" :key="items.id">
                            <td>{{ items.updated_at }}</td>
                            <td><img :src="items.thumbnail" alt="thumbnail"></td>
                            <td>{{ items.title }}</td>
                            <td>{{ items.total_chapter }}</td>
                            <td>{{ items.approve }}</td>
                            <td>
                                <router-link :to="{ name: 'detail-novel', params: { id: items.id } }"
                                    class="detail-content">
                                    Detail
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <router-link to="/table-content" class="redirect-novel">Selengkapnya</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import storage from "@/controllers/Localstorage"
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import contentController from "@/controllers/content"

export default {
    name: "Content",
    data() {
        return {
            session: {},
            karya: [],
        }
    },
    async created() {
        this.session = storage.getLocalStorage()
    },
    async mounted() {
        if (this.session.memberId) {
            await this.fetchSummary({
                member_id: this.session.memberId,
                usertoken: this.session.usertoken,
                range_day: this.rangeDay
            })

            this.res_summary
        }

        const { data } = await contentController.ListKarya(this.session.memberId)

        this.karya = data.DATA

    },
    methods: {
        ...mapActions({
            fetchSummary: "summary/fetchSummary",
        }),
    },
    computed: {
        ...mapState({
            summary: state => state.summary.res_summary,
            rangeDay: state => state.rangeDay
        }),
        ...mapGetters("summary", {
            res_summary: "get_res_summary"
        }),
        ...mapGetters("user", {
            user: "get_user"
        },),

        dateTime() {
            const now = new Date()
            const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' }
            const timeOptions = { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' }

            const dateString = now.toLocaleDateString('id-ID', dateOptions)
            const timeString = now.toLocaleTimeString('id-ID', timeOptions)

            return `per ${dateString} | ${timeString}`
        }
    },
}
</script>

<style>
@import url('./css/content.css');
</style>
const headers = {
  "Content-Type": "application/json",
  APPTOKEN: "klaklikapptoken",
};

// const headers = (usertoken) =>{
//     if (usertoken != ""){
//         return { "Content-Type": "application/json", "APPTOKEN" : "klaklikapptoken",  "USERTOKEN" : usertoken}
//     } else {
//         return { "Content-Type": "application/json", "APPTOKEN" : "klaklikapptoken" }
//     }
// }
export default headers;

<template>
    <div class="modal" v-if="showEditModal">
        <div class="modal-delete-novel">
            <div class="modal-delete-head">
                <p>Apakah kamu yakin datanya sudah benar?</p>
            </div>
            <div class="modal-delete-body">
                <button @click="$emit('close')">Batal</button>
                <button @click="handleUpdate">Simpan</button>
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    name: "show-barcode",
    props: {
        showEditModal: {
            type: Boolean,
            default: false
        },
        handleUpdate: {
            type: Function,
            required: true
        }
    },
}
</script>
    
<style>
@import url('./novel/css/modalnovel.css');
</style>
  
<template>
  <div class="form-box">
    <div class="form__left"></div>
    <div class="form__right">
      <div class="form__right-head">
        <img src="@/assets/head-mobile1.png" alt="" />
      </div>
      <div class="form__login">
        <div class="form-login-box">
          <div class="form-login-head">
            <h4>Creator Login</h4>
            <div v-if="errorMsg">
              <p class="error-msg">{{ errorMsg }}</p>
            </div>
          </div>
          <div class="form-section">
            <form @submit.prevent="handleLogin" class="form-control-l">
              <div class="form-input-l">
                <label for="">Email</label>
                <input type="email" v-model="email" required />
              </div>
              <div class="form-input-l">
                <label for="">Password</label>
                <input type="password" v-model="password" required />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="form__right-bottom">
        <img src="@/assets/image-login.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import login from "@/controllers/login";
import storage from "@/controllers/Localstorage";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
      session: {},
      redirectUrl: "",
    };
  },
  metaInfo() {
    return {
      title: "Login | Dashboard",
    };
  },
  async mounted() {
    this.redirectUrl = localStorage.getItem("redirectUrl");
    await this.checkLogin();
  },
  methods: {
    async handleLogin() {
      const res = await login.login(this.email, this.password);
      if (res.data.STATUS == 200) {
        var datarequest = {
          memberId: res.data.DATA.MEMBER_ID,
          memberEmail: res.data.DATA.MEMBER_EMAIL,
          firstname: res.data.DATA.FIRSTNAME,
          lastname: res.data.DATA.LASTNAME,
          aboutMe: res.data.DATA.ABOUT_ME,
          memberAddress: res.data.DATA.MEMBER_ADDRESS,
          memberBod: res.data.DATA.MEMBER_BOD,
          memberPhone: res.data.DATA.MEMBER_PHONE,
          memberImage: res.data.DATA.MEMBER_IMAGE,
          keyActived: res.data.DATA.KEY_ACTIVED,
          keyMember: res.data.DATA.KEY_MEMBER,
          token: res.data.DATA.TOKEN,
          usertoken: res.data.DATA.USERTOKEN,
        };
        if (this.redirectUrl) {
          storage.setLocalStorage(datarequest);
          this.session = storage.getLocalStorage();
          if (this.redirectUrl == "/") {
            if (this.session.memberId) {
              window.location.href = "/summary";
            } else {
              window.location.href = this.redirectUrl;
            }
          } else {
            window.location.href = this.redirectUrl;
          }
          localStorage.removeItem("redirectUrl");
        }
      } else {
        this.errorMsg = res.data.MESSAGE;
      }
    },
    async checkLogin() {
      this.session = storage.getLocalStorage();
      if (this.session.usertoken) {
        window.location.href = "/summary";
      }
    },
  },
  watch: {
    $route(to) {
      localStorage.setItem("redirectUrl", to.fullPath);
      this.redirectUrl = to.fullPath;
    },
  },
};
</script>

<style>
@import url("./css/login.css");
</style>

import bubleImage from "@/assets/chatstory/gloomy.png";

const listBubble = [
  {
    id: 1,
    nama: "Default",
    image: bubleImage,
  },
  {
    id: 2,
    nama: "Gloomy",
    image: bubleImage,
  },
  {
    id: 3,
    nama: "Winter",
    image: bubleImage,
  },
];

export default listBubble;

<template>
  <div class="edit-novel-nav">
    <div class="nav-edit-novel">
      <router-link :to="{ name: 'detail-novel', params: { id: novelid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Novel</p>
      <div class="divide">/</div>
      <p>Detail and Chapters</p>
      <div class="divide">/</div>
      <p>Edit Title Details</p>
    </div>
  </div>
  <div class="edit-novel-section">
    <div class="left-edit-novel">
      <img v-if="thumbnailUrl === ''" :src="analytics.images_novel" alt="analytics-thumbnail" />
      <img :src="thumbnailUrl" v-else />
      <div class="edit-novel-thumbnail">
        <input type="file" ref="fileInput" @change="uploadThumbnail" />
      </div>
      <p class="edit-title-novel">{{ novel_title }}</p>

      <button @click="openModal" class="delete-novel-btn">Delete <img src="@/assets/trash.png" alt="" /></button>
    </div>
    <div class="right-edit-novel">
      <div class="form-section">
        <div class="form-input">
          <label for="">Title</label>
          <input type="text" v-model="analytics.title" />
        </div>
        <div class="ms-input-section">
          <label for="">Genre</label>
          <multiselect
            v-model="selectedGenre"
            label="name"
            track-by="id"
            :options="genresList"
            :multiple="true"
            :taggable="true"
            @newGenre="multipleGenre"
            class="multiselect-input"
          ></multiselect>
      
        </div>
        <div class="form-input">
          <label for="">Event(Optional)</label>
          <select v-model="analytics.event_id">
            <option v-if="analytics.event_id == 0" value="0">Pilih Event</option>
            <option v-for="event in eventList" :value="event.id" :key="event.id">{{ event.title }}</option>
          </select>
        </div>
        <div class="form-input">
          <label for="">Hastag (Optional)</label>
          <p class="tags-text">Tekan tombol spasi untuk menambahkan tag.</p>
          <ul>
            <li v-for="(tag, index) in analytics.hastag" :key="index">
              <span>{{ tag.title }}</span>
              <img src="../../../assets/hapus-tag.svg" alt="hapus-tag" class="remove-tag" @click="removeHastagNovel(tag.id)" />
            </li>
            <li v-for="(tag, index) in tags" :key="index">
              <span>{{ tag }}</span>
              <img src="../../../assets/hapus-tag.svg" alt="hapus-tag" class="remove-tag" @click="removeTag(index)" />
            </li>
            <input type="text" spellcheck="false" v-model="tagInput" id="tagsInput" :placeholder="remainingTags == 10 ? 'Ketik disini ...' : ''" />
          </ul>
          <div class="tags-detail">
            <p class="tags-text">
              <span>{{ remainingTags }}</span> tag tersisa
            </p>
          </div>
        </div>
        <div class="form-input">
          <label for="">About</label>
          <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="analytics.desc" placeholder="masukkan teks ..." />
        </div>
        <button @click="openEditModal">Submit</button>
      </div>
    </div>
    <ModalNovel :show-modal="showModal" @close="closeModal" v-if="analytics.approve === 1 || analytics.approve === 5"> </ModalNovel>
    <DeleteModal :handleDelete="handleDelete" :warnMsg="warnMsg" :show-modal="showModal" @close="closeModal" v-else> </DeleteModal>
    <ShowEditModal
      v-if="analytics.approve === 1 || analytics.approve === 5"
      :showEditModal="showEditModal"
      @close="closeModal"
      :handleUpdate="handleUpdate"
      :approve="approve"
    ></ShowEditModal>
    <StoreModal :handleStore="handleStore" :showModal="showStore" @close="closeModal" v-else></StoreModal>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import ModalNovel from "@/components/novel/ModalNovel.vue";
import DeleteModal from "@/components/novel/DeleteModal.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import storage from "@/controllers/Localstorage";
import ShowEditModal from "@/components/novel/EditModal.vue";
import StoreModal from "@/components/novel/StoreModal.vue";
import imageController from "@/controllers/images";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import { useMeta } from "vue-meta";
import { ref, onMounted } from "vue";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      novelid: 0,
      analytics: [],
      selectedGenre:[],
      selectedEvent: "",
      thumbnailUrl: "",
      warnMsg: "",
      file: "",
      genresList: [],
      eventList: [],
      showModal: false,
      showEditModal: false,
      showStore: false,
      novel_title: "",
      memberId: 0,
      session: {},
      approve: 0,

      //   Hastag
      maxTags: 10,
      tags: [],
      tagInput: "",
      novelTags: 0,
      sendTags: [],
    };
  },
  metaInfo() {
    return {
      title: this.analytics.title ? `${this.analytics.title} | Edit Novel` : "Dasbboard",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.novelid = id;
    this.session = storage.getLocalStorage();
    this.fetchDetail();
    this.fetchGenre();
    this.fetchEvent();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_EDIT_CONTENT_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Edit Novel",
      page_path: "/edit-novel",
      firebase_screen_class: "EditNovel",
      screen_class: "EditNovel",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_EDIT_CONTENT_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Edit Novel",
      page_path: "/edit-novel",
      firebase_screen_class: "EditNovel",
      screen_class: "EditNovel",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        alert("something wrong");
      }
    },
    openModal() {
      this.showModal = true;
      this.warnMsg = "Apakah kamu yakin ingin menghapus konten?";
    },
    openEditModal() {
      this.showEditModal = true;
      this.showStore = true;
    },
    closeModal() {
      this.showModal = false;
      this.showEditModal = false;
      this.showStore = false;
    },
    async fetchDetail() {
      const { data } = await contentController.DetailNovel(this.novelid);
      this.analytics = data.DATA.novel_detail;
      this.approve = this.analytics.approve;
      this.novel_title = this.analytics.title;
      this.memberId = this.analytics.member_id;
      this.selectedGenre = this.analytics.genres;
      if (parseInt(this.memberId) !== parseInt(this.session.memberId)) {
        this.$router.push("/");
      }

      //   Hashtag
      this.novelTags = this.analytics.hastag.length;
    },
    async fetchGenre() {
      const { data } = await contentController.ListGenre();
      this.genresList = data.DATA;
    },
    async fetchEvent() {
      const { data } = await contentController.ListEvent();
      this.eventList = data.DATA;
      this.selectedEvent = this.eventList[0].id;
    },
    async handleStore(approve) {
      if (this.analytics.hastag.length > 0) {
        this.sendTags = this.analytics.hastag.map((tag) => tag.title).concat(this.tags);
      } else {
        this.sendTags = this.tags;
      }

      if (this.file == "") {
        const title = this.analytics.title;
        const genre = this.selectedGenre;
        const event = this.analytics.event_id;
        const desc = this.analytics.desc;

        await contentController.EditNovel(this.novelid, title, genre, event, desc, this.file, approve, this.sendTags);
        this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
      } else {
        const images = await this.StoreImage();
        const title = this.analytics.title;
        const genre = this.selectedGenre;
        const event = this.analytics.event_id;
        const desc = this.analytics.desc;

        await contentController.EditNovel(this.novelid, title, genre, event, desc, images.DATA, approve, this.sendTags);
        this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
      }
    },
    async handleUpdate(approve) {
      if (this.analytics.hastag.length > 0) {
        this.sendTags = this.analytics.hastag.map((tag) => tag.title).concat(this.tags);
      } else {
        this.sendTags = this.tags;
      }

      if (this.file == "") {
        const title = this.analytics.title;
        const genre = this.selectedGenre;
        const event = this.analytics.event_id;
        const desc = this.analytics.desc;

        await contentController.EditNovel(this.novelid, title, genre, event, desc, this.file, approve, this.sendTags);
        this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
      } else {
        const images = await this.StoreImage();
        const title = this.analytics.title;
        const genre = this.selectedGenre;
        const event = this.analytics.event_id;
        const desc = this.analytics.desc;

        await contentController.EditNovel(this.novelid, title, genre, event, desc, images.DATA, approve, this.sendTags);
        this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
      }
    },
    async handleDelete() {
      const { data } = await contentController.DeleteNovel(this.novelid);
      this.$router.push("/table-content");
    },

    // hastag function
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addTag() {
      let newTags = this.tagInput.split(",").map((tag) => tag.trim());
      newTags.forEach((newTag) => {
        if (newTag.length > 1 && !this.tags.includes(newTag) && this.tags.length < this.maxTags) {
          if (this.tags.length + this.novelTags < this.maxTags) {
            this.tags.push(newTag);
          }
        }
      });
      this.tagInput = "";
    },
    removeAllTags() {
      this.tags = [];
    },
    async removeHastagNovel(tagid) {
      const { data } = await contentController.DeleteHastag(this.novelid, tagid);
      if (data.STATUS == 201) {
        this.fetchDetail();
      }
    },

    multipleGenre(newGenreName) {
      const newGenre = {
        name: newGenreName,
        id: newGenreName.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.genresList.push(newGenre);
      this.selectedGenre.push(newGenre);
    },
  },
  components: {
    ModalNovel,
    DeleteModal,
    QuillEditor,
    ShowEditModal,
    StoreModal,
    Multiselect,
  },
  computed: {
    remainingTags() {
      return this.maxTags - this.tags.length - this.novelTags;
    },
  },
  watch: {
    tagInput(value) {
      if (value.includes(" ")) {
        this.addTag();
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
@import url("../css/edit-novel.css");
</style>

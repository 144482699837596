<template>
  <div v-if="showEditChar" class="add-char-modal">
    <div class="add-char-section">
      <div class="acs-head">
        <div></div>
        <div class="acs-title">Edit Karakter</div>
        <div class="acs-close" @click="closeThisModal">X</div>
      </div>
      <label for="">Avatar</label>
      <div class="acs-img-section">
        <div class="acs-img">
          <img :src="previewImage" alt="" v-if="previewImage != null" />
          <img :src="charData.avatar" alt="" v-else />
          <img src="@/assets/chatstory/icon-edit-blue.png" alt="" @click="openGallery" />
          <input type="file" ref="fileInput" style="display: none" @change="uploadThumbnail" />
        </div>
      </div>
      <div class="acs-input-section">
        <label for="">Nama</label>
        <input type="text" placeholder="Input Nama" v-model="charData.nama_karakter" />
      </div>
      <div class="acs-input-section">
        <label for="">Posisi (Buble Chat)</label>
        <select v-model="charData.status_receiver">
          <option value="0">Pengirim (Kanan)</option>
          <option value="1">Penerima (Kiri)</option>
        </select>
      </div>
      <div class="btn-edit-char">
        <button class="acs-btn-delete" @click="handleDelete"><img src="@/assets/chatstory/trash-red.png" alt="" /> Hapus</button>
        <button class="acs-btn-submit" @click="handleSubmit">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import chatstoryController from "@/controllers/chatstory";
import imageController from "@/controllers/images";

export default {
  props: {
    showEditChar: {
      type: Boolean,
      default: false,
    },
    chatstoryid: {
      type: Number,
      default: 0,
    },
    charid: {
      type: Number,
      default: 0,
    },
    charData: {
      type: Object,
    },
    getListChar: {
      type: Function,
    },
  },
  data() {
    return {
      previewImage: null,
      nama: null,
      selectedPosition: null,
      charImage: "",
    };
  },
  async mounted() {},

  methods: {
    openGallery() {
      // Memanggil klik pada input file tersembunyi saat tombol di klik
      this.$refs.fileInput.click();
    },
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.charImage = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.previewImage = reader.result;
      };
    },
    async StoreImage() {
      if (this.charImage != "" || this.charImage != undefined || this.charImage != null) {
        const { status, data } = await imageController.PostImage(this.charImage);
        return data;
      } else {
        // change alert wiht something
        console.log("error");
      }
    },

    async handleSubmit() {
      if (this.charData.nama_karakter != "") {
        if (this.previewImage != null) {
          const res_image = await this.StoreImage();

          if (res_image.STATUS == 200) {
            const { data } = await chatstoryController.EditChar(
              this.chatstoryid,
              this.charData.nama_karakter,
              res_image.DATA,
              this.charData.status_receiver,
              this.charid
            );
            if (data.STATUS == 200) {
              this.closeThisModal();
              this.getListChar();
            }
          }
        } else {
          const { data } = await chatstoryController.EditChar(
            this.chatstoryid,
            this.charData.nama_karakter,
            "",
            this.charData.status_receiver,
            this.charid
          );
          if (data.STATUS == 200) {
            this.closeThisModal();
            this.getListChar();
          }
        }
      } else {
        alert("Form tidak lengkap!");
      }
    },
    closeThisModal() {
      this.charImage = null;
      this.previewImage = null;
      this.nama = null;
      this.selectedPosition = "0";
      this.$emit("close");
    },
    async handleDelete() {
      const { data } = await chatstoryController.CheckCharacterDel(this.charid);

      if (data.STATUS == 200) {
        const { data } = await chatstoryController.DeleteChar(this.charid);
        if (data.STATUS == 200) {
          this.closeThisModal();
          this.getListChar();
        }
      }
    },
  },
  beforeDestroy() {
    if (this.previewImage) {
      URL.revokeObjectURL(this.previewImage);
    }
  },
};
</script>

<style>
@import url("./css/add-char.css");
</style>

const event_name = {
  VIEW_SPLASH: "view_splash",
  VIEW_MAIN: "view_main",
  VIEW_HOME: "view_home",
  VIEW_HOME_SEEALL: "view_home_seeall",
  VIEW_NOVEL: "view_novel",
  VIEW_NOVEL_SEEALL: "view_novel_seeall",
  VIEW_NOVEL_DETAIL: "view_novel_detail",
  VIEW_NOVEL_CONTENT: "view_novel_content",
  VIEW_NOVEL_SCENARIO_DETAIL: "view_novel_scenario_detail",
  VIEW_NOVEL_SCENARIO_CONTENT: "view_novel_scenario_content",
  VIEW_NOVEL_QUOTE_DETAIL: "view_novel_quote_detail",
  VIEW_NOVEL_GENRE: "view_novel_genre",
  VIEW_NOVEL_SCENARIO_GENRE: "view_novel_scenario_genre",
  VIEW_NOVEL_CERPEN_GENRE: "view_novel_cerpen_genre",
  VIEW_NOVEL_QUOTE_GENRE: "view_novel_quote_genre",
  VIEW_PLAY: "view_play",
  VIEW_PLAY_SEEALL: "view_play_seeall",
  VIEW_PLAY_DETAIL: "view_play_detail",
  VIEW_PLAY_PLAYER: "view_play_player",
  VIEW_PLAY_GENRE: "view_play_genre",
  VIEW_PLAY_GOKS_DETAIL: "view_play_goks_detail",
  VIEW_PLAY_EPISODE_DETAIL: "view_play_episode_detail",
  VIEW_PLAY_EPISODE_PLAYER: "view_play_episode_player",
  VIEW_COMIC: "view_comic",
  VIEW_COMIC_SEEALL: "view_comic_seeall",
  VIEW_COMIC_DETAIL: "view_comic_detail",
  VIEW_COMIC_CONTENT: "view_comic_content",
  VIEW_COMIC_GENRE: "view_comic_genre",
  VIEW_CIRCLE: "view_circle",
  VIEW_MY_CIRCLE: "view_my_circle",
  VIEW_CIRCLE_HANGOUT_PLAYER: "view_circle_hangout_player",
  VIEW_CIRCLE_HANGOUT: "view_circle_hangout",
  VIEW_FORUM_LIST: "view_forum_list",
  VIEW_FORUM_DETAIL: "view_forum_detail",
  VIEW_CASTING: "view_casting",
  VIEW_BE_THE_ARTIST_LIST: "view_be_the_artist_list",
  VIEW_BE_THE_ARTIST_DETAIL: "view_be_the_artist_detail",
  VIEW_CASTING_SEEALL: "view_casting_seeall",
  VIEW_CASTING_LIST: "view_casting_list",
  VIEW_CASTING_DETAIL: "view_casting_detail",
  VIEW_FANBASE: "view_fanbase",
  VIEW_ARTIST: "view_artist",
  VIEW_AUDIO: "view_audio",
  VIEW_AUDIO_LIST_SEEALL: "view_audio_list_seeall",
  VIEW_AUDIO_DETAIL: "view_audio_detail",
  VIEW_AUDIO_PLAYER: "view_audio_player",
  VIEW_AUDIO_GENRE: "view_audio_genre",
  VIEW_SEARCH: "view_search",
  VIEW_PROFILE: "view_profile",
  VIEW_KPOIN: "view_kpoin",
  VIEW_KPOIN_MISI: "view_kpoin_misi",

  VIEW_SUMMARY: "view_summary",
  VIEW_STORE_NOVEL: "view_store_novel",
  VIEW_LIST_CONTENT_NOVEL: "view_list_content_novel",
  VIEW_DETAIL_CONTENT_NOVEL: "view_detail_content_novel",
  VIEW_ANALYTICS_NOVEL: "view_analytics_novel",
  VIEW_EDIT_CONTENT_NOVEL: "view_edit_content_novel",
  VIEW_CREATE_CHAPTER_NOVEL: "view_create_chapter_novel",
  VIEW_EDIT_CHAPTER_NOVEL: "view_edit_chapter_novel",

  VIEW_LIST_CONTENT_COMIC: "view_list_content_comic",
  VIEW_STORE_COMIC: "view_store_comic",
  VIEW_DETAIL_CONTENT_COMIC: "view_detail_content_comic",
  VIEW_ANALYTICS_COMIC: "view_analytics_comic",
  VIEW_EDIT_CONTENT_COMIC: "view_edit_content_comic",
  VIEW_CREATE_CHAPTER_COMIC: "view_create_chapter_comic",
  VIEW_EDIT_CHAPTER_COMIC: "view_edit_chapter_comic",

  VIEW_FOLLOWERS: "view_followers",
  VIEW_WALLET: "view_wallet",
  VIEW_AFFILIATE: "view_affiliate",
  VIEW_LIST_MEMBER_AFFILIATE: "view_list_member_affiliate",
  VIEW_DETAIL_MEMBER_AFFILIATE: "view_list_detail_affiliate",
  VIEW_DETAIL_NOVEL_AFFILIATE: "view_detail_novel_affiliate",
  VIEW_DETAIL_COMIC_AFFILIATE: "view_detail_comic_affiliate",

  VIEW_HISTORY_WITHDRAW: "view_history_withdraw",
  VIEW_HISTORY_EARNING: "view_history_earning",

  VIEW_DETAIL_CHATSTORY: "view_detail_chatstory",
};

const param_name = {
  APP_USER_ID: "app_user_id",
  APP_EVENT_NAME: "app_event_name",
  MEMBER_ID: "member_id",
  NOVEL_ID: "novel_id",
  NOVEL_TITLE: "novel_title",
  NOVEL_CHAPTER_ID: "novel_chapter_id",
  NOVEL_CHAPTER_TITLE: "novel_chapter_title",
  NOVEL_SCENARIO_TITLE: "novel_scenario_title",
  NOVEL_SCENARIO_CHAPTER_ID: "novel_scenario_chapter_id",
  NOVEL_SCENARIO_CHAPTER_TITLE: "novel_scenario_chapter_title",
  NOVEL_QUOTE_ID: "novel_quote_id",
  NOVEL_QUOTE_TITLE: "novel_quote_title",
  NOVEL_GENRE_ID: "novel_genre_id",
  NOVEL_GENRE_TITLE: "novel_genre_title",
  NOVEL_SCENARIO_GENRE_ID: "novel_scenario_genre_id",
  NOVEL_SCENARIO_GENRE_TITLE: "novel_scenario_genre_title",
  NOVEL_CERPEN_GENRE_ID: "novel_cerpen_genre_id",
  NOVEL_CERPEN_GENRE_TITLE: "novel_cerpen_genre_title",
  NOVEL_QUOTE_GENRE_ID: "novel_quote_genre_id",
  NOVEL_QUOTE_GENRE_TITLE: "novel_quote_genre_title",
  PLAY_ID: "play_id",
  PLAY_TITLE: "play_title",
  PLAYER_TITLE: "player_title",
  PLAYER_EPISODE_TITLE: "player_episode_title",
  PLAY_GENRE_ID: "play_genre_id",
  PLAY_GENRE_TITLE: "play_genre_title",
  PLAY_EPISODE_ID: "play_episode_id",
  PLAY_EPISODE_TITLE: "play_episode_title",
  PLAY_GOKS_ID: "play_goks_id",
  PLAY_GOKS_TITLE: "play_goks_title",
  COMIC_ID: "comic_id",
  COMIC_TITLE: "comic_title",
  COMIC_CONTENT_ID: "comic_content_id",
  COMIC_CONTENT_TITLE: "comic_content_title",
  COMIC_GENRE_ID: "comic_genre_id",
  COMIC_GENRE_TITLE: "comic_genre_title",
  FANBASE_NAME: "fanbase_name",
  ARTIST_NAME: "artist_name",
  CIRCLE_HANGOUT_ID: "circle_hangout_id",
  CIRCLE_HANGOUT_TITLE: "circle_hangout_title",
  FORUM_ID: "forum_id",
  FORUM_TITLE: "forum_title",
  BE_THE_ARTIST_ID: "be_the_artist_id",
  BE_THE_ARTIST_TITLE: "be_the_artist_title",
  CASTING_ID: "casting_id",
  CASTING_TITLE: "casting_title",
  AUDIO_ID: "audio_id",
  AUDIO_TITLE: "audio_title",
  AUDIO_EPISODE_ID: "audio_episode_id",
  AUDIO_EPISODE_TITLE: "audio_episode_title",
  AUDIO_GENRE_ID: "audio_genre_id",
  AUDIO_GENRE_TITLE: "audio_genre_title",
  PROFILE_NAME: "profile_name",
  SECTION_TYPE: "section_type",
  HOME_ID: "home_id",
  HOME_SEEALL_TITLE: "home_seeall_title",
  NOVEL_SEEALL_TITLE: "novel_seeall_title",
  PLAY_SEEALL_TITLE: "play_seeall_title",
  COMIC_SEEALL_TITLE: "comic_seeall_title",
  CASTING_SEEALL_TITLE: "casting_seeall_title",
  CASTING_LIST_TITLE: "casting_list_title",
  AUDIO_SEEALL_TITLE: "audio_seeall_title",
};

const section_name = {
  NOVEL: "novel",
  PLAY: "play",
  COMIC: "comic",
  CIRCLE: "circle",
  CASTING: "casting",
  AUDIO: "audio",
  SEARCH: "search",
  PROFILE: "profile",
  HOME: "home",
  MAIN: "main",
  SPLASH: "splash",
  KPOIN: "kpoin",
  DASHBOARD: "dashboard",
};

export default {
  section_name,
  param_name,
  event_name,
};

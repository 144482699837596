<template>
  <div class="modal-warn-hiatus" v-if="showWarnHiatus">
    <div class="modal-wh-section">
      <div class="modal-wh-head">
        <div></div>
        <div class="modal-wh-title">Warning</div>
        <div class="modal-wh-close" @click="$emit('close')">X</div>
      </div>
      <div class="modal-wh-body">{{ msgHiatus }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showWarnHiatus: {
      type: Boolean,
      default: false,
    },
    msgHiatus: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
@import url("./css/warn-hiatus.css");
</style>

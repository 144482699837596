<template>
  <div class="list-boost-section">
    <div class="c__head">
      <div class="c__head-title">
        <h4>{{ res_summary.total_views_content }}</h4>
        <p>Total Viewers</p>
      </div>
      <div class="c__head-line"></div>
      <p class="c__head-desc">{{ dateTime }}</p>
    </div>
    <div class="boost-tables-section">
      <h4 class="boost-tables-title">List Karya Boosting</h4>
      <div class="e__table-section" v-if="listBoost.length == 0">
        <div class="e__table-body">
          <table>
            <thead>
              <tr>
                <th>Tanggal Transaksi</th>
                <th>Judul</th>
                <th>Paket</th>
                <th>Tanggal Boosting</th>
                <th>Status</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="empty-tabel">
          <p style="text-align: center; margin: 10px 0">No records found.</p>
        </div>
      </div>
      <div class="e__table-section" v-else>
        <div class="e__table-body">
          <table>
            <thead>
              <tr>
                <th>Tanggal Transaksi</th>
                <th>Judul</th>
                <th>Paket</th>
                <th>Tanggal Boosting</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listBoost" :key="item.id">
                <td>{{ formattedDate(item.date_order) }}</td>
                <td>{{ contentTitle(item) }}</td>
                <td style="text-transform: capitalize">{{ item.boosting_name }}</td>
                <td>{{ formattedDate(item.date_order) }} - {{ formattedDate(item.date_expired) }}</td>
                <td>
                  <div v-if="item.status_content_expired == 2" class="status-antrian">
                    Antrian <span>{{ item.antrian }}</span>
                  </div>
                  <div
                    v-else
                    class="status-nonantrian"
                    :style="item.status_content_expired == 1 ? 'color: #41B9AA' : '#0000'"
                    style="font-weight: 600"
                  >
                    {{ item.status_content_expired == 1 ? "Selesai" : item.status_content_expired == 0 ? "On Going" : "" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pages-section">
          <p class="pages">{{ curentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="curentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="curentPage === 1">&lt; Sebelumnya</button>
            <button :class="curentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="curentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/controllers/Localstorage";
import boostingController from "@/controllers/boosting";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      session: {},
      curentPage: 1,
      totalDataPerPage: 0,
      offset: 0,
      listBoost: [],
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();
    if (this.session.memberId) {
      await this.fetchSummary({
        member_id: this.session.memberId,
        usertoken: this.session.usertoken,
        range_day: this.rangeDay,
      });

      this.res_summary;

      const { data } = await boostingController.ListBoostKarya(this.session.memberId, this.offset);
      this.listBoost = data.DATA;
      this.totalDataPerPage = data.TOTAL;
    } else {
      this.$router.push({ path: "/" });
    }
  },

  methods: {
    ...mapActions({
      fetchSummary: "summary/fetchSummary",
    }),

    paginateData(data) {
      const start = (this.curentPage - 1) * this.totalDataPerPage;
      const end = start + this.totalDataPerPage;
      return data.slice(start, end);
    },
    async nextPage() {
      this.offset += 5;
      const { data } = await boostingController.ListBoostKarya(this.session.memberId, this.offset);
      this.listBoost = data.DATA;
      this.totalDataPerPage = data.TOTAL;
      this.curentPage++;
    },
    async prevPage() {
      this.offset -= 5;
      const { data } = await boostingController.ListBoostKarya(this.session.memberId, this.offset);
      this.listBoost = data.DATA;
      this.totalDataPerPage = data.TOTAL;
      this.curentPage--;
    },

    contentTitle(target) {
      if (target.novel_id) {
        return target.novel_title;
      } else {
        return target.comic_title;
      }
    },

    formattedDate(date) {
      const newDate = new Date(date);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return newDate.toLocaleDateString("en-GB", options);
    },
  },
  computed: {
    ...mapState({
      summary: (state) => state.summary.res_summary,
      rangeDay: (state) => state.rangeDay,
    }),
    ...mapGetters("summary", {
      res_summary: "get_res_summary",
    }),

    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },

    totalPages() {
      return Math.ceil(this.totalDataPerPage / 5);
    },
    paginatedData() {
      if (this.rangeDay) {
        return this.paginateData(this.list_history);
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #8eb5db;
  color: #ffffff;
}

th {
  padding: 10px;
}

td {
  border-bottom: 2px solid #e4e4e4;
}

th,
td {
  text-align: center;
  padding: 10px;
}

td > img {
  width: 40px;
}

@import url("./css/list-boost-karya.css");
</style>

<template>
    <div class="withdraw-section">
        <div class="wd__form-card">
            <h1>Pendapatan yang bisa ditarik</h1>
            <h2>Rp. 324.000</h2>
            <div class="wd__form">
                <form action="">
                    <div class="wd__form-input">
                        <label for="">Jumlah dana yang ditarik</label>
                        <input type="text" disabled>
                    </div>
                    <div class="wd__form-input">
                        <label for="">Potongan Pajak 30% (Tanpa NPWP)</label>
                        <input type="text" disabled>
                    </div>
                    <div class="wd__form-input">
                        <label for="">Informasi Bank</label>
                        <input type="text" disabled>
                    </div>
                    <div class="wd__form-input">
                        <label for="">Biaya Admin</label>
                        <input type="text" disabled>
                    </div>
                    <div class="wd__detail">
                        <p>Total Penarikan</p>
                        <h4>Rp. 220.300</h4>
                    </div>
                    <div class="without-npwp">
                        <img src="@/assets/warn-modal.png" alt="">
                        <p>Kamu akan mendapatkan potongan pajak lebih rendah apabila mencantumkan NPWP pada profile kamu.
                            <span>Lengkapi Profile</span>
                        </p>
                    </div>
                    <div class="wd__btn">
                        <button>Kirim Permintaan Penarikan</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
@import url('../css/withdraw.css');
</style>
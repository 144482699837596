<template>
  <div class="followers-head">
    <div class="f__head">
      <div class="f__head-title">
        <h4>{{ res_followers.total_followers_all }}</h4>
        <p>Total Followers</p>
      </div>
      <div class="f__head-line"></div>
      <p class="f__head-desc">{{ dateTime }}</p>
    </div>
  </div>
  <div class="followers" id="followers-section">
    <div class="f__left">
      <h1 class="f__left-title">Followers Growth</h1>
      <form @submit.prevent="fetchFollowersData" class="f__box-left">
        <div class="f__box-head">
          <h3>{{ res_followers.followers_by_date }}</h3>
          <h3>New Followers</h3>
        </div>
        <select v-model="rangeDay" @change="updateDateString">
          <option value="1">Hari ini</option>
          <option value="7">7 hari terakhir</option>
          <option value="30">1 bulan terakhir</option>
          <option value="365">1 tahun terakhir</option>
          <!-- <option value="custom">Custom</option> -->
        </select>
        <div class="f__box-rate">
          <div
            :class="res_followers.followers_percent === '0.000%' ? 'arrow-null' : res_followers.followers_static === 'up' ? 'arrow-up' : 'arrow-down'"
          ></div>
          <h3>{{ res_followers.followers_percent }}</h3>
          <p>dari Bulan {{ res_followers.from_date }}</p>
        </div>
        <p>per {{ res_followers.date }}</p>
      </form>
    </div>
    <div class="f__right">
      <h1>Followers Statistics</h1>
      <img src="@/assets/followers-empty-img.png" alt="" />
      <p>Untuk membuka fitur ini harap</p>
      <h4>Hubungi Admin</h4>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import storage from "@/controllers/Localstorage";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({ title: "Klaklik | Followers" });
  },
  data() {
    return {
      session: {},
      rangeDay: 1,
    };
  },
  async created() {
    this.fetchFollowersData();
    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_FOLLOWERS,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Followers",
      page_path: "/followers",
      firebase_screen_class: "Followers",
      screen_class: "Followers",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_FOLLOWERS,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Followers",
      page_path: "/followers",
      firebase_screen_class: "Followers",
      screen_class: "Followers",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchFollowersData() {
      this.session = storage.getLocalStorage();

      await this.fetchFollowers({
        member_id: this.session.memberId,
        usertoken: this.session.usertoken,
        range_day: this.rangeDay,
      });
    },
    ...mapActions({
      fetchFollowers: "followers/fetchFollowers",
    }),
    ...mapMutations({
      setFollowers: "followers/setFollowers",
    }),
  },
  computed: {
    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric", timeZoneName: "short" };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
    ...mapState({
      followers: (state) => state.followers.res_followers,
      rangeDay: (state) => state.rangeDay,
    }),
    ...mapGetters("followers", {
      res_followers: "get_res_followers",
    }),
  },
  watch: {
    rangeDay(newVal, oldVal) {
      this.fetchFollowersData();
    },
  },
};
</script>

<style>
@import url("./css/followers.css");
</style>

<template>
    <div class="modal" v-if="showModal">
        <div class="modal-wallet-content">
            <div class="modal-wallet-head">
                <img src="@/assets/warn-modal.png" alt="">
                <p>Gagal Melakukan Penarikan !</p>
                <span class="closing" @click="$emit('close')">&times;</span>
            </div>
            <div class="modal-body">
                <p>Jumlah pendapatan kamu belum memenuhi minimum pendapatan yang bisa ditarik oleh creator (Min. Rp.200.000)
                </p>
                <h4>Informasi lebih lanjut</h4>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "show-barcode",
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {

        }
    },
}
</script>
    
<style>
@import url('./css/warnmodal.css');
</style>
  
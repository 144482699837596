// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCpJ1Bqik0_50VytLYWeiGfcorvAYpJDR4",

  authDomain: "website-b91b8.firebaseapp.com",

  projectId: "website-b91b8",

  storageBucket: "website-b91b8.appspot.com",

  messagingSenderId: "815057077741",

  appId: "1:815057077741:web:8be91bd664301c6cbd93e4",

  measurementId: "G-ZV6JJX882Z",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export default {
  firebaseConfig,

  analytics,
};

<template>
    <div class="modal" v-if="showPreStoreNovel">
        <div class="modal-ps">
            <div class="modal-ps-head">
                <!-- <img src="@/assets/warn-modal.png" alt="">
                <p>Warning</p> -->
                <!-- <span class="closing" @click="redirect">&times;</span> -->
                <span class="closing" @click="$emit('close')">&times;</span>
            </div>
            <div class="modal-ps-body">
                <div class="modal-ps-body-title">
                    <h3>Update on Terms & Conditions</h3>
                </div>
                <div class="modal-ps-body-desc">
                    <p>• Sekarang kamu tidak perlu Upload KTP sebelum mengupload</p>
                    <p>• Upload KTP dan NPWP tetap diperlukan untuk penarikan pendapatan</p>
                </div>
                <div class="modal-ps-body-terms">
                    <p>Lihat Terms and Conditions <span @click="redirectTerms">disini</span></p>
                </div>
                <div class="modal-ps-body-msg">
                    Dengan melanjutkan kamu telah menyetuji terms and conditions dari klaklik.
                </div>
                <div @click="$emit('close')" class="modal-ps-body-btn">
                    Lanjutkan
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "show-barcode",
    props: {
        showPreStoreNovel: {
            type: Boolean,
            default: true
        },
        errorMsg: {
            type: String,
            default: ""
        }
    },
    data() {
        return {

        }
    },
    methods: {
        redirect() {
            this.$router.push("/")
        },
        redirectTerms() {
            window.open(process.env.VUE_APP_TERMSOFUSE, "_blank")
        }
    }
}
</script>
    
<style>
@import url('./css/prestorenovel.css');
</style>
  
<template>
  <div v-if="showSwitchModal" class="switch-modal">
    <div class="switch-modal-section">
      <div class="sms-head">Ganti Posisi Bubble</div>
      <div class="sms-body">
        <img src="@/assets/chatstory/narator.png" alt="" />
        <p>Ganti posisi bubble <span>Julian Casatmatas</span> menjadi Penerima (kiri) untuk bab ini?</p>
      </div>
      <div class="btn-sms">
        <button class="sms-btn-delete" @click="closeModal">Batal</button>
        <button class="sms-btn-submit">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showSwitchModal: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
@import url("./css/switch-modal.css");
</style>

import axios from "axios";
import headers from "@/router/headers";

const PostImage = async (file) => {
  let data = new FormData();
  data.append("thumbnail", file);

  return await axios.post(process.env.VUE_APP_GATEWAY + "post-image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      APPTOKEN: "klaklikapptoken",
    },
  });
};

const PostImageEps = async (file, total) => {
  let data = new FormData();
  for (let i = 0; i < file.length; i++) {
    data.append(`thumbnail[${i}]`, file[i]);
  }
  data.append("total", total);

  return await axios.post(
    process.env.VUE_APP_GATEWAY + "post-image-array",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        APPTOKEN: "klaklikapptoken",
      },
    }
  );
};

export default {
  PostImage,
  PostImageEps,
};

<template>
  <div class="nav-back-section">
    <div class="nav-create-chapter">
      <router-link :to="{ name: 'detail-comic', params: { id: comicid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Comic</p>
      <div class="divide">/</div>
      <p>Detail and Chapters</p>
    </div>
  </div>
  <div class="create-episode-section">
    <form class="form-create-episode">
      <div class="create-episode-left">
        <div class="form-upload-thumbnail">
          <div class="store-left-wrapper">
            <div class="img-novel-store" v-if="thumbnailUrl === ''">
              <img src="@/assets/default-store.png" />
            </div>
            <div class="img-comic-store" v-else>
              <img :src="thumbnailUrl" />
            </div>
            <div class="comic-upload-img">
              <label class="comic-upload-btn" onclick="document.getElementById('fileInput').click()">Upload Thumbnail</label>
              <input type="file" id="fileInput" ref="fileInput" @change="uploadThumbnail" />
            </div>
            <!-- <h4>Upload Thumbnail</h4>
                        <div class="comic-upload-img">
                            <input type="file" ref="fileInput" @change="uploadThumbnail" />
                        </div> -->
          </div>
        </div>
      </div>
      <div class="create-episode-right">
        <div class="form-input-episode">
          <label>Judul Episode</label>
          <input type="text" v-model="judulEpisode" placeholder="masukkan judul episode" />
        </div>
        <div class="form-input-episode">
          <label>Episode ke : </label>
          <input type="text" v-model="episode" placeholder="0" />
        </div>
        <div class="content-episode" v-if="showUploadContent">
          <div class="warn-upload-eps">
            <label><span>Episode Berhasil Dibuat,</span> Upload Content Terlebih Dahulu!</label>
          </div>
          <input type="file" ref="fileInput" @change="handleFileChange" multiple />
          <div v-if="files.length > 0" class="box-preview-img">
            <div v-for="(file, index) in files" :key="index" class="preview-img-item">
              <img :src="file.url" alt="Preview Image" class="preview-img" />
              <div class="option-preview">
                <div class="page-preview-eps">
                  <label>Page : </label>
                  <input type="text" v-model="file.caption" />
                </div>
                <img @click="removeImage(index)" src="@/assets/trash-comic.png" alt="" class="trash-icon-eps" />
              </div>
            </div>
          </div>
        </div>
        <div class="terms-section">
          <label>
            <input type="checkbox" v-model="isChecked" />
            Saya Menyetujui
            <span class="span-terms" @click="redirectTerms">Terms and Conditions</span>
            Klaklik
          </label>
        </div>
        <button type="submit" @click.prevent="handleUpload" class="btn-chapter-publish">Upload</button>
        <!-- <button type="submit" @click.prevent="uploadImage" class="btn-chapter-publish"
                    v-if="showUploadContent">Upload</button> -->
      </div>
      <ModalFormError :errorMsg="errorMsg" :showFormError="showFormError" @close="closeModal"></ModalFormError>
      <CheckModalComic :showCheckModal="showCheckModal" @close="closeModal" :simpanEpisode="simpanEpisode"> </CheckModalComic>
    </form>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import imageController from "@/controllers/images";
import CheckModalComic from "@/components/comic/CheckModalComic.vue";
import ModalFormError from "@/components/ModalFormError.vue";
import { toast } from "vue3-toastify";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Create Episode",
    };
  },
  data() {
    return {
      comicid: 0,
      episodeid: 0,
      judulEpisode: "",
      episode: "",
      thumbnailUrl: "",
      file: "",
      isChecked: false,
      showCheckModal: false,
      showFormError: false,
      showUploadContent: false,
      errorMsg: "",
      files: [],
      dataImages: [],
      pageEps: 0,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    const { data } = await contentController.DetailComic(id);
    this.comicid = data.DATA.id;

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_CREATE_CHAPTER_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Create Chapter Comic",
      page_path: "/store-episode",
      firebase_screen_class: "CreateEpisode",
      screen_class: "CreateEpisode",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_CREATE_CHAPTER_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Create Chapter Comic",
      page_path: "/store-episode",
      firebase_screen_class: "CreateEpisode",
      screen_class: "CreateEpisode",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    handleFileChange() {
      const newFiles = Array.from(this.$refs.fileInput.files);
      this.files.push(...newFiles);
      this.previewImages();
    },
    previewImages() {
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        file.caption = "";
        const reader = new FileReader();

        reader.onload = (e) => {
          this.files[i].url = e.target.result;
          this.files = [...this.files];
        };

        reader.readAsDataURL(file);
      }
    },
    async uploadImage() {
      const { data } = await imageController.PostImageEps(this.files, this.files.length);
      const imageUrls = data.DATA;
      if (imageUrls.length > 0) {
        for (let i = 0; i < imageUrls.length; i++) {
          const imageData = imageUrls[i];
        }
        const captionData = this.files.map((file) => ({
          caption: file.caption,
        }));
        const isCaptionEmpty = captionData.some((item) => !item.caption);
        if (!isCaptionEmpty) {
          const { data } = await contentController.PostChapterMulti(this.episodeid, imageUrls, captionData);
          if (data.STATUS === 200) {
            this.$router.push({
              name: "update-episode",
              params: { id: this.episodeid },
            });
          }
        } else {
          this.showFormError = true;
          this.errorMsg = "Page tidak boleh kosong!";
        }
      } else {
        this.showFormError = true;
        this.errorMsg = "Content tidak boleh kosong!";
      }
    },
    async removeImage(index) {
      this.files.splice(index, 1);
    },
    async handleUpload() {
      if (this.judulEpisode && this.episode && this.file) {
        if (this.isChecked) {
          this.simpanEpisode();
          // this.showUploadContent = true
        } else {
          this.showFormError = true;
          this.errorMsg = "Anda belum menyetujui terms and condition klaklik!";
        }
      } else {
        this.showFormError = true;
        this.errorMsg = "Input form belum lengkap, lengkapi form terlebih dahulu!";
      }
    },
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        alert("add thumbnail your novel first");
      }
    },

    async simpanEpisode() {
      const res_image = await this.StoreImage();

      if (res_image.STATUS === 200) {
        const { data } = await contentController.StoreEpisode(this.judulEpisode, res_image.DATA, this.episode, this.comicid);
        if (data.STATUS === 201) {
          this.episodeid = data.DATA.id;
          this.$router.push({
            name: "update-episode",
            params: { id: this.episodeid },
          });
          toast.success("Berhasil! Tambahkan Content Episode", {
            autoClose: 2000,
            pauseOnHover: false,
            position: toast.POSITION.TOP_RIGHT,
            transition: toast.TRANSITIONS.FLIP,
          });
        }

        if (data.STATUS === 201) {
        } else {
          alert("something wrong!!!");
        }
      } else {
        // change alert with something
        alert("gambar gagal terupload");
      }
    },
    openModal() {
      if (this.judulEpisode && this.episode && this.file) {
        if (this.isChecked) {
          this.showCheckModal = true;
        } else {
          this.showFormError = true;
          this.errorMsg = "Anda belum menyetujui terms and condition klaklik!";
        }
      } else {
        this.showFormError = true;
        this.errorMsg = "Input form belum lengkap, lengkapi form terlebih dahulu!";
      }
    },
    closeModal() {
      this.showCheckModal = false;
      this.showFormError = false;
    },
    redirectTerms() {
      window.open(process.env.VUE_APP_TERMSOFUSE, "_blank");
    },
  },
  components: {
    CheckModalComic,
    ModalFormError,
  },
};
</script>

<style>
@import url("../Comic/css/create-episode.css");
</style>

import wallet from "@/controllers/wallet";

export default {
  namespaced: true,
  state: {
    res_earning: {},
    res_history: [],
  },

  getters: {
    get_res_earning: (state) => state.res_earning,
    get_res_history: (state) => state.res_history,
  },

  mutations: {
    setEarning(state, res_earning) {
      state.res_earning = res_earning;
    },
    setHistory(state, res_history) {
      state.res_history = res_history;
    },
  },
  actions: {
    async fetchEarning({ commit }, data_req) {
      const res = await wallet.getEarning(
        data_req.member_id,
        data_req.usertoken
      );
      commit("setEarning", res);
    },

    async fetchHistory({ commit }, data_req) {
      const res = await wallet.getHistory(
        data_req.member_id,
        data_req.usertoken,
        data_req.range_day
      );
      commit("setHistory", res);
    },
  },
};

import axios from "axios";
import headers from "@/router/headers";

export async function getFollowers(memberid, usertoken, rangeDay) {
  let data = new FormData();
  data.append("member_id", memberid);
  data.append("range_day", rangeDay);

  headers["USERTOKEN"] = usertoken;

  if (usertoken !== undefined) {
    let response = await axios.post(process.env.VUE_APP_GATEWAY + "dashboard/analytic-followers/" + memberid, data, { headers: headers });
    let res = response.data.DATA;
    return res;
  }
}

export default {
  getFollowers
};

<template>
  <div v-if="showAddImg" class="edit-msg-modal">
    <div class="edit-msg-section">
      <div class="ems-head">
        <div></div>
        <div class="ems-title">Upload Gambar</div>
        <div class="ems-close" @click="closeThisModal">X</div>
      </div>
      <div class="ems-img-section">
        <img src="@/assets/chatstory/null-img.png" alt="" v-if="previewImage == null" @click="openGallery" />
        <img :src="previewImage" alt="" v-else @click="openGallery" class="ems-img-preview" />
        <input type="file" id="fileInput" ref="fileInput" @change="uploadThumbnail" />
      </div>
      <div class="ems-input-section">
        <input type="text" placeholder="Tambah Caption" v-model="dataMessage.captionMsg" />
      </div>
      <div class="btn-edit-msg">
        <!-- <button class="ems-btn-delete" @click="handleSubmit"><img src="@/assets/chatstory/trash-red.png" alt="" /> Hapus</button> -->
        <button class="ems-btn-submit" @click="handleSubmit">Upload</button>
      </div>
    </div>
  </div>
</template>

<script>
import chatstoryController from "@/controllers/chatstory";
import imageController from "@/controllers/images";

export default {
  props: {
    showAddImg: {
      type: Boolean,
      default: false,
    },
    sendImageMessage: {
      type: Function,
    },
  },
  data() {
    return {
      previewImage: null,
      dataMessage: {
        msgImage: "",
        captionMsg: "",
      },
    };
  },
  methods: {
    openGallery() {
      // Memanggil klik pada input file tersembunyi saat tombol di klik
      this.$refs.fileInput.click();
    },
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.dataMessage.msgImage = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.previewImage = reader.result;
      };
    },
    async StoreImage() {
      if (this.dataMessage.msgImage != "" || this.dataMessage.msgImage != undefined || this.dataMessage.msgImage != null) {
        const { status, data } = await imageController.PostImage(this.dataMessage.msgImage);
        return data;
      } else {
        // change alert wiht something
        console.log("error");
      }
    },

    async handleSubmit() {
      const res_image = await this.StoreImage();

      if (res_image.STATUS == 200) {
        this.dataMessage.msgImage = res_image.DATA;
        this.sendImageMessage(this.dataMessage);
        this.closeThisModal();
      }
    },
    closeThisModal() {
      this.dataMessage.msgImage = null;
      this.previewImage = null;
      this.$emit("close");
    },
    beforeDestroy() {
      if (this.previewImage) {
        URL.revokeObjectURL(this.previewImage);
      }
    },
  },
};
</script>

<style>
@import url("./css/edit-msg.css");
</style>

import headers from "@/router/headers";
import axios from "axios";

const getUser = async (usertoken) => {
  headers["USERTOKEN"] = usertoken;
  return await axios.get(process.env.VUE_APP_GATEWAY + "byusertoken", {
    headers: headers
  });
};

async function login(email, password) {
  let req = new FormData();
  req.append("MEMBER_EMAIL", email);
  req.append("MEMBER_PASSWORD", password);

  let { data, status } = await axios.post(process.env.VUE_APP_GATEWAY + "login", req, {
    headers: headers
  });
  return { data: data, status: status };
}

export default {
  getUser,
  login
};

<template>
  <div class="wallet-nav">
    <p>Select Category</p>
    <div class="radio-w-nav">
      <label for="my-wallet" class="nav-mywallet" :class="{ 'selected-nav': myWallet }">My Wallet</label>
      <input type="radio" name="category" value="my-wallet" v-model="myWallet" :checked="myWallet" @change="handleChange('my-wallet')" />
      <label for="affiliate" class="nav-affiliate" :class="{ 'selected-nav': affiliate }">Affiliate</label>
      <input type="radio" name="category" value="affiliate" v-model="affiliate" :checked="affiliate" @change="handleChange('affiliate')" />
    </div>
  </div>
  <!-- My Wallet Components Starts-->
  <div v-if="myWallet">
    <div class="wallet-head">
      <div class="w__head">
        <div class="w__head-title">
          <p>Estimasi Pendapatan</p>
          <div class="w__head-title-detail">
            <h4>{{ res_earning.estimasi_earning }}</h4>
            <span v-if="checkAgent.owner_agent.trim() !== ''">Kamu tergabung dalam {{ checkAgent.owner_agent }} Affiliate</span>
          </div>
          <div class="w__head-line"></div>
          <p class="w__head-desc">{{ dateTime }}</p>
        </div>
      </div>
      <div class="wallet-body">
        <div class="w__body-left">
          <div class="w__warn" v-if="res_earning.show_warning_profile === 0">
            <img src="@/assets/w_warn-icon.png" alt="" />
            <div class="w__warn-desc">
              <p>Lengkapi profil kamu untuk melakukan penarikan</p>
            </div>
            <div @click="redirectProfile" class="edit-profile">Edit Profile</div>
          </div>
          <h3 class="w__platform-title">Distribusi Pendapatan</h3>
          <div class="w__platform">
            <div class="w__platform-box">
              <div class="w__box-name">
                <img src="@/assets/w__android-icon.png" alt="" />
                <p>Android + Web</p>
              </div>
              <p>{{ res_earning.distribution_android }}</p>
              <h4>{{ res_earning.percent_android }}</h4>
            </div>
            <div class="w__platform-box">
              <div class="w__box-name">
                <img src="@/assets/w__ios-icon.png" alt="" />
                <p>IOS</p>
              </div>
              <p>{{ res_earning.distribution_ios }}</p>
              <h4>{{ res_earning.percent_ios }}</h4>
            </div>
          </div>
          <div class="w__platform-btn">
            <div @click="redirectHistoryEarning" class="w__direct-earn">
              <p>Lihat History Pendapatan</p>
              <img src="@/assets/history-earning-icon.png" alt="" class="earning-icon" />
            </div>
          </div>
          <!-- <div class="w__platform-btn">
                <router-link to="/history-earning" class="w__direct-earn">Lihat History Pendapatan</router-link>
            </div> -->
          <!-- test -->
        </div>
        <div class="w__body-right">
          <h3 class="w__platform-title">Pendapatan yang bisa ditarik</h3>
          <h1>{{ nominal }}</h1>
          <p>Penarikan dapat dilakukan dengan minimal pendapatan Rp. 200.000</p>
          <div class="w__line-bottom"></div>
          <div class="body-right-btn" @click="handleWithdraw">
            <p>Tarik Pendapatan</p>
            <img src="@/assets/w__tarik-icon.png" alt="" />
          </div>
          <div @click="redirectHistoryWd" class="withdraw-direct">Lihat History Penarikan</div>
          <!-- <router-link to="/history-withdraw">Lihat History Penarikan</router-link> -->
        </div>
        <WalletWarnModal :show-modal="showModal" @close="closeModal"></WalletWarnModal>
      </div>
    </div>
  </div>
  <!-- My Wallet Components Ends-->
  <!-- Affiliate Components Starts -->
  <Affiliate v-else />
  <!-- Affiliate Components Ends -->
</template>

<script>
import WalletWarnModal from "@/components/wallet/WalletWarnModal.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import storage from "@/controllers/Localstorage";
import walletController from "@/controllers/wallet";
import Affiliate from "./affiliate/Affiliate.vue";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

import Monthly from "./components/Monthly";

export default {
  metaInfo() {
    return {
      title: "Klaklik | Wallet",
    };
  },
  data() {
    return {
      showModal: false,
      session: {},
      earning: 0,
      nominal: 0,
      myWallet: true,
      affiliate: false,
      checkAgent: {},
    };
  },
  async created() {
    this.session = storage.getLocalStorage();
    this.CheckAgent();
    this.fetchEarningData();
    this.fetchNominal();
    this.handleChange();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_WALLET,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Wallet",
      page_path: "/wallet",
      firebase_screen_class: "Wallet",
      screen_class: "Wallet",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_WALLET,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Wallet",
      page_path: "/wallet",
      firebase_screen_class: "Wallet",
      screen_class: "Wallet",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    handleChange(value) {
      if (value === "my-wallet") {
        this.myWallet = true;
        this.affiliate = false;
      } else if (value === "affiliate") {
        this.myWallet = false;
        this.affiliate = true;
      }
    },
    async CheckAgent() {
      const { data } = await walletController.CheckMemberAgent(this.session.memberId);
      this.checkAgent = data.DATA;
    },
    async fetchEarningData() {
      this.session = storage.getLocalStorage();
      if (this.session.usertoken) {
        await this.fetchEarning({
          member_id: this.session.memberId,
          usertoken: this.session.usertoken,
        });
      } else {
        this.$router.push("/");
      }
    },
    async fetchNominal() {
      const { data } = await walletController.getNominalWithdraw(this.session.memberId, this.session.usertoken);
      this.nominal = data.DATA.toLocaleString("id-ID", { style: "currency", currency: "IDR" });
    },
    ...mapActions({
      fetchEarning: "earning/fetchEarning",
    }),
    ...mapMutations({
      setEarning: "earning/setEarning",
    }),
    handleWithdraw() {
      window.open(process.env.VUE_APP_WITHDRAW, "_blank");
      // let earning = parseInt(this.res_earning.estimasi_earning.replace(/[^\d]/g, ''));
      // if (earning <= 200000) {
      //     this.showModal = truess
      // } else {
      //     this.$router.push('/withdraw');
      // }
    },
    redirectHistoryWd() {
      this.$router.push("/history-withdraw");
    },
    redirectHistoryEarning() {
      this.$router.push("/history-earning");
    },
    redirectProfile() {
      window.open(process.env.VUE_APP_PROFILE, "_blank");
    },
    closeModal() {
      this.showModal = false;
    },
  },
  components: {
    WalletWarnModal,
    Affiliate,
    Monthly,
  },
  computed: {
    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric", timeZoneName: "short" };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
    ...mapState({
      earning: (state) => state.earning.res_earning,
    }),
    ...mapGetters("earning", {
      res_earning: "get_res_earning",
    }),
    ...mapGetters("user", {
      user: "get_user",
    }),
  },
};
</script>

<style>
@import url("./css/wallet.css");
</style>

<template>
  <div class="history-withdraw-section">
    <div class="nav-withdraw">
      <router-link to="/wallet">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Wallet</p>
      <div class="divide">/</div>
      <p>History Penarikan</p>
    </div>

    <div class="withdraw-tables-section">
      <h3 class="withdraw-title">History Penarikan</h3>
      <div class="withdraw-head">
        <div class="withdraw-filter">
          <form @submit.prevent="">
            <p class="label">Urutkan Berdasarkan</p>
            <select v-model="rangeDay">
              <option value="1">Hari ini</option>
              <option value="7">7 hari terakhir</option>
              <option value="30">1 bulan terakhir</option>
              <option value="365">1 tahun terakhir</option>
              <!-- <option value="custom">Custom</option> -->
            </select>
          </form>
        </div>
        <!-- <div class="withdraw-export" v-if="list_withdraw.length != 0">
                    <router-link to="/" class="export-withdraw-btn">Export <img src="@/assets/export.png"
                            alt=""></router-link>
                </div> -->
      </div>
      <div class="w__table-section" v-if="list_withdraw.length == 0">
        <div class="w__table-body">
          <table>
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Jumlah</th>
                <th>Status</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="empty-tabel">
          <p style="text-align: center; margin: 10px 0">No records found.</p>
        </div>
      </div>
      <div class="w__table-section" v-else>
        <div class="w__table-body">
          <table>
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Jumlah</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="withdraw in list_withdraw" :key="withdraw.id">
                <td class="td_wd">{{ formatedDate }}</td>
                <td class="td_wd">{{ withdraw.wallet }}</td>
                <td class="td_wd">
                  <span :class="withdraw.status === 'pending' ? 'pending' : withdraw.status === 'reject' ? 'reject' : 'approve'">{{
                    withdraw.status
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pages-section">
          <p class="pages">{{ curentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="curentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="curentPage === 1">&lt; Sebelumnya</button>
            <button :class="curentPage === totalPages ? 'disabled-next' : 'next'" @click="nextPage" :disabled="curentPage === totalPages">
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletController from "@/controllers/wallet";
import storage from "@/controllers/Localstorage";
import dayjs from "dayjs";
import "dayjs/locale/id";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

dayjs.locale("id");

export default {
  metaInfo() {
    return {
      title: "Klaklik | Withdraw",
    };
  },
  data() {
    return {
      session: {},
      rangeDay: 365,
      list_withdraw: [],
      curentPage: 1,
      totalDataPerPage: 5,
    };
  },
  async created() {
    this.session = storage.getLocalStorage();

    this.fetchWithdraw();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_HISTORY_WITHDRAW,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard History Withdraw",
      page_path: "/history-withdraw",
      firebase_screen_class: "HistoryWithdraw",
      screen_class: "HistoryWithdraw",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_HISTORY_WITHDRAW,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard History Withdraw",
      page_path: "/history-withdraw",
      firebase_screen_class: "HistoryWithdraw",
      screen_class: "HistoryWithdraw",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchWithdraw() {
      const { data } = await walletController.getWithdraw(this.session.memberId, this.session.usertoken, this.rangeDay);

      this.list_withdraw = data.DATA;
    },
    paginateData(data) {
      const start = (this.curentPage - 1) * this.totalDataPerPage;
      const end = start + this.totalDataPerPage;
      return data.slice(start, end);
    },
    nextPage() {
      this.curentPage++;
    },
    prevPage() {
      this.curentPage--;
    },
  },
  computed: {
    totalPages() {
      if (this.rangeDay) {
        return Math.ceil(this.list_withdraw.length / this.totalDataPerPage);
      }
    },
    paginatedData() {
      if (this.rangeDay) {
        return this.paginateData(this.list_withdraw);
      }
    },
    formatedDate() {
      return dayjs(this.list_withdraw.created_at, "YYYY-MM-DD HH:mm:ss").format("D MMM YYYY | HH.MM WIB");
    },
  },
  watch: {
    rangeDay(newVal, oldVal) {
      this.fetchHistory();
    },
  },
};
</script>

<style>
@import url("../css/historywithdraw.css");
</style>

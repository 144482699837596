<template>
  <div v-if="showEditMsg" class="edit-msg-modal">
    <div class="edit-msg-section">
      <div class="ems-head">
        <div></div>
        <div class="ems-title">Edit dan Hapus Chat</div>
        <div class="ems-close" @click="closeThisModal">X</div>
      </div>
      <div class="ems-img-section">
        <img v-if="detailContentChat.image != '' && previewImage == ''" :src="detailContentChat.image" alt="" @click="openGallery" />
        <img v-else-if="detailContentChat.image == '' && previewImage == ''" src="@/assets/chatstory/null-img.png" alt="" @click="openGallery" />
        <img v-else :src="previewImage" alt="" @click="openGallery" />
        <input type="file" id="fileInput" ref="fileInput" @change="uploadThumbnail" />
      </div>
      <div class="ems-input-section">
        <img src="@/assets/chatstory/plus-upload.png" alt="" />
        <input type="text" v-model="detailContentChat.desc" placeholder="Masukkan Caption" />
        <!-- <img src="@/assets/chatstory/send-msg.png" alt="" /> -->
      </div>
      <div class="btn-edit-msg">
        <button class="ems-btn-delete"><img src="@/assets/chatstory/trash-red.png" alt="" /> Hapus</button>
        <button class="ems-btn-submit" @click="handleSubmit">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import chatstoryController from "@/controllers/chatstory";
import imageController from "@/controllers/images";

export default {
  props: {
    showEditMsg: {
      type: Boolean,
      default: false,
    },
    detailContentChat: {
      type: Object,
    },
  },
  data() {
    return {
      previewImage: "",
      dataMessage: {
        msgImage: "",
        captionMsg: "",
      },
    };
  },
  methods: {
    openGallery() {
      // Memanggil klik pada input file tersembunyi saat tombol di klik
      this.$refs.fileInput.click();
    },
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.dataMessage.msgImage = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.previewImage = reader.result;
      };
    },

    async StoreImage() {
      if (this.dataMessage.msgImage != "" || this.dataMessage.msgImage != undefined || this.dataMessage.msgImage != null) {
        const { status, data } = await imageController.PostImage(this.dataMessage.msgImage);
        return data;
      } else {
        // change alert wiht something
        console.log("error");
      }
    },
    async handleSubmit() {
      if (this.previewImage != "") {
        const res_image = await this.StoreImage();
        if (res_image.STATUS == 200) {
          this.dataMessage.msgImage = res_image.DATA;
          this.dataMessage.captionMsg = this.detailContentChat.desc;
          const { data } = await chatstoryController.EditChatCS(
            this.detailContentChat.id,
            this.detailContentChat.chat_id,
            this.detailContentChat.chapter_id,
            this.detailContentChat.character_id,
            this.detailContentChat.chat_theme_id,
            this.dataMessage.captionMsg,
            this.detailContentChat.status_receiver,
            this.dataMessage.msgImage
          );

          if (data.STATUS == 200) {
            const updatedMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
            };

            this.$store.commit("chatstory/updateMessage", updatedMessage);
            this.closeThisModal();
          }
        }
      } else {
        this.dataMessage.captionMsg = this.detailContentChat.desc;
        const { data } = await chatstoryController.EditChatCS(
          this.detailContentChat.id,
          this.detailContentChat.chat_id,
          this.detailContentChat.chapter_id,
          this.detailContentChat.character_id,
          this.detailContentChat.chat_theme_id,
          this.dataMessage.captionMsg,
          this.detailContentChat.status_receiver,
          ""
        );

        if (data.STATUS == 200) {
          const updatedMessage = {
            avatar: data.DATA.avatar,
            created_at: data.DATA.Created_at,
            desc: data.DATA.desc,
            id: data.DATA.id,
            image: data.DATA.image,
            nama_karakter: data.DATA.nama_karakter,
            status_receiver: data.DATA.status_receiver,
          };

          this.$store.commit("chatstory/updateMessage", updatedMessage);
          this.closeThisModal();
        }
      }
    },

    closeThisModal() {
      this.previewImage = "";
      this.$emit("close");
    },
    beforeDestroy() {
      if (this.previewImage) {
        URL.revokeObjectURL(this.previewImage);
      }
    },
  },
};
</script>

<style>
@import url("./css/edit-msg.css");
</style>

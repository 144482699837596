<template>
  <div class="nav-back-section">
    <div class="nav-create-chapter">
      <router-link :to="{ name: 'detail-comic', params: { id: comicid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Comic</p>
      <div class="divide">/</div>
      <p>Detail and Episode</p>
    </div>
  </div>
  <div class="update-episode-section">
    <form class="form-create-episode">
      <div class="update-episode-left">
        <div class="form-upload-thumbnail">
          <div class="store-left-wrapper">
            <div class="img-comic-store">
              <img v-if="thumbnailUrl === ''" :src="episodes.thumbnail" alt="analytics-thumbnail" />
              <img :src="thumbnailUrl" v-else />
            </div>
            <div class="comic-upload-img">
              <label class="comic-upload-btn" onclick="document.getElementById('fileInput').click()">Edit Thumbnail</label>
              <input type="file" id="fileInput" ref="fileInput" @change="uploadThumbnail" />
            </div>
            <div class="form-input-episode">
              <label>Judul Episode</label>
              <input type="text" v-model="episodes.title" placeholder="masukkan judul episode" />
            </div>
            <div class="form-input-episode">
              <label>Episode ke : </label>
              <input type="text" v-model="episodes.episode_number" placeholder="0" />
            </div>
            <button type="submit" @click.prevent="handleUpdate" class="btn-chapter-publish">Save</button>
          </div>
        </div>
      </div>
      <div class="create-episode-right">
        <h4 class="right-panel-title">List Content</h4>
        <div class="right-comic-line"></div>
        <div class="list-action-panel">
          <div class="add-action-panel" @click="openAddContent">
            <img src="@/assets/plus-white-icon.png" alt="" />
            <span>Tambah Konten</span>
          </div>
          <div class="preview-action-panel" @click="redirectPreviewPanel">
            <img src="@/assets/eye-blue-icon.png" alt="" />
            <span>Preview</span>
          </div>
        </div>
        <div class="list-panel" v-if="listPanel.length > 0">
          <table>
            <tr>
              <th>Halaman</th>
              <th>Panel</th>
              <th>Action</th>
            </tr>
            <tr v-for="(item, index) in listPanel.slice(0, slicePanel)" :key="index">
              <td>{{ item.page }}</td>
              <td><img :src="item.file" alt="" /></td>
              <td>
                <div class="list-action-panel">
                  <div class="panel-edit" @click="openPopupPanel(item.id)">
                    <img src="@/assets/pencil-comic-icon.png" alt="" />
                    <span>Edit</span>
                  </div>
                  <div class="panel-delete" @click="openDeletePanel(item.id)">
                    <img src="@/assets/trash-comic.png" alt="" />
                    <span>Hapus</span>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div class="loadMore" @click="loadMorePanel" v-if="listPanel.length > 5 && slicePanel < listPanel.length">Show More</div>
        </div>
        <div class="panel-null" v-else>Belum ada konten untuk episode ini.</div>
      </div>
      <ModalFormError :errorMsg="errorMsg" :showFormError="showFormError" @close="closeModal"></ModalFormError>
    </form>
    <PopupEditPanel
      :showPopupEdit="showPopupEdit"
      :episodeid="episodeid"
      :dataPanel="dataPanel"
      :usertoken="session.usertoken"
      :getListPanel="getListPanel"
      @close="closePopupPanel"
    />
    <AddContentComic :showAddContent="showAddContent" @close="closeAddContent" />
    <DeletePanel :showDeletePanel="showDeletePanel" :deletePanel="deletePanel" :panelid="panelid" @close="closeDeletePanel"> </DeletePanel>
    <PreviewNull :showPreviewNull="showPreviewNull" :errorMsg="errorMsg" @close="closePreviewNull"></PreviewNull>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import imageController from "@/controllers/images";
import ModalFormError from "@/components/ModalFormError.vue";
import storage from "@/controllers/Localstorage";
import PopupEditPanel from "./PopupEditPanel.vue";
import AddContentComic from "@/components/comic/AddContentComic.vue";
import DeletePanel from "@/components/comic/DeletePanel.vue";
import PreviewNull from "@/components/comic/PreviewNull.vue";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: "Edit Episode",
    };
  },
  data() {
    return {
      comicid: 0,
      episodeid: 0,
      panelid: 0,
      episodes: {},
      thumbnailUrl: "",
      showFormError: false,
      showPopupEdit: false,
      showAddContent: false,
      showDeletePanel: false,
      showPreviewNull: false,
      errorMsg: "",
      file: "",
      comicTitle: "",
      isChecked: false,
      session: {},
      listPanel: {},
      files: [],
      imageUrls: [],
      dataPanel: [],
      slicePanel: 5,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.session = storage.getLocalStorage();
    const { data } = await contentController.DetailEpisode(id);
    this.getListPanel(id);
    this.episodes = data[0];
    this.comicid = data[0].comic_id;
    this.episodeid = id;
    this.getComicDetail();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_EDIT_CHAPTER_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Edit Chapter Comic",
      page_path: "/update-episode",
      firebase_screen_class: "UpdateEpisode",
      screen_class: "UpdateEpisode",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_EDIT_CHAPTER_COMIC,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Edit Chapter Comic",
      page_path: "/update-episode",
      firebase_screen_class: "UpdateEpisode",
      screen_class: "UpdateEpisode",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    openDeletePanel(id) {
      this.panelid = id;
      this.showDeletePanel = true;
    },
    closeDeletePanel() {
      this.showDeletePanel = false;
    },
    openPreviewNull() {
      this.showPreviewNull = true;
    },
    closePreviewNull() {
      this.showPreviewNull = false;
    },
    async loadMorePanel() {
      this.slicePanel = this.slicePanel + 5;
      this.getListPanel(this.episodeid);
    },
    openAddContent() {
      this.showAddContent = true;
    },
    closeAddContent() {
      this.showAddContent = false;
      this.getListPanel(this.episodeid);
    },
    async getComicDetail() {
      const { data } = await contentController.DetailComic(this.comicid);
      this.comicTitle = data.DATA.title;
    },
    async getListPanel(id) {
      const { data } = await contentController.GetListPanel(id, this.session.usertoken);
      this.listPanel = data;
    },
    handleFileChange() {
      const newFiles = Array.from(this.$refs.fileInput.files);
      this.files.push(...newFiles);
      this.previewImages();
    },
    previewImages() {
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        file.caption = "";
        const reader = new FileReader();

        reader.onload = (e) => {
          this.files[i].url = e.target.result;
          this.files = [...this.files];
        };

        reader.readAsDataURL(file);
      }
    },
    async uploadFiles() {
      const { data } = await imageController.PostImageEps(this.files, this.files.length);
      this.imageUrls = data.DATA;
      for (let i = 0; i < this.imageUrls.length; i++) {
        const imageData = this.imageUrls[i];
      }
      const captionData = this.files.map((file) => ({ caption: file.caption }));
      const isCaptionEmpty = captionData.some((item) => !item.caption);
      if (!isCaptionEmpty) {
        const { data } = await contentController.PostChapterMulti(this.episodeid, this.imageUrls, captionData);
        if (data.STATUS === 200) {
          this.getListPanel(this.episodeid);
          this.files = [];
          this.imageUrls = [];
          // this.$router.push({ name: 'detail-comic', params: { id: this.comicid } });
        }
      } else {
        this.showFormError = true;
        this.errorMsg = "Page tidak boleh kosong!";
      }
    },
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        alert("something wrong");
      }
    },
    async handleUpdate() {
      if (this.episodes.title === "" || this.episodes.episode_number === "") {
        this.showFormError = true;
        this.errorMsg = "Judul dan Episode tidak boleh kosong";
        // } else if (!this.isChecked) {
        //     this.showFormError = true
        //     this.errorMsg = "Anda belum menyetujui terms and condition klaklik!"
      } else {
        if (this.file === "") {
          await contentController.EditEpisode(this.episodeid, this.episodes.title, this.episodes.episode_number, this.file);
          if (this.files.length > 0) {
            this.uploadFiles();
          }
          this.$router.push({
            name: "detail-comic",
            params: { id: this.comicid },
          });
        } else {
          const images = await this.StoreImage();
          await contentController.EditEpisode(this.episodeid, this.episodes.title, this.episodes.episode_number, images.DATA);
          if (this.files.length > 0) {
            this.uploadFiles();
          }
          this.$router.push({
            name: "detail-comic",
            params: { id: this.comicid },
          });
        }
      }
    },
    closeModal() {
      this.showFormError = false;
    },
    async deletePanel(id) {
      await contentController.DeletePanelComic(id);
      this.getListPanel(this.episodeid, this.session.usertoken);
      this.closeDeletePanel();
    },
    async openPopupPanel(id) {
      const { data } = await contentController.GetDetailPanel(id);
      this.dataPanel = data[0];
      this.showPopupEdit = true;
    },
    closePopupPanel() {
      this.showPopupEdit = false;
    },
    async removeImage(index) {
      this.files.splice(index, 1);
    },
    redirectPreviewPanel() {
      if (this.listPanel.length > 0) {
        this.$router.push({
          name: "list-panel-eps",
          params: { id: this.episodeid },
        });
      } else {
        this.openPreviewNull();
        this.errorMsg = "Panel episode masih kosong! Tambahkan Konten Episode terlebih dahulu!";
      }
    },
  },
  components: {
    ModalFormError,
    PopupEditPanel,
    AddContentComic,
    DeletePanel,
    PreviewNull,
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #8eb5db;
  color: #ffffff;
}

th {
  padding: 10px;
}

td {
  border-bottom: 2px solid #e4e4e4;
}

th,
td {
  text-align: center;
  padding: 10px;
}

td > img {
  width: 40px;
}

#fileInput {
  display: none;
}

@import url("../Comic/css/update-episode.css");
</style>

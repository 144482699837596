<template>
  <div class="navheadercs">
    <span class="nhc-cancelback" @click="$router.push('/summary')">Kembali</span>
    <label for="" class="nhc-title">Karya Chat Story</label>
    <div style="width: 20%"></div>
  </div>
  <div class="list-content-section">
    <div class="c__head">
      <div class="c__head-title">
        <h4>{{ res_summary.total_views_content }}</h4>
        <p>Total Viewers</p>
      </div>
      <div class="c__head-line"></div>
      <p class="c__head-desc">{{ dateTime }}</p>
    </div>

    <div class="data-tables-section">
      <div class="data__title">
        <p>List Karya ChatStory</p>
      </div>
      <div class="head-table-section">
        <div class="lc_date-filter">
          <form @submit.prevent="">
            <p class="label-date">Urutkan berdasarkan</p>
            <select v-model="rangeDay">
              <option value="1">Terbaru</option>
              <option value="0">Terlama</option>
            </select>
          </form>
        </div>
        <div class="input-search-table">
          <p class="label-search">Cari</p>
          <input type="text" placeholder="search here.." v-model="searchInput" />
        </div>
      </div>
      <div class="lc__table-section">
        <div class="c__table-body">
          <!-- List Novel Data Table -->
          <table>
            <thead>
              <tr>
                <th>Last Update</th>
                <th>Thumbnail</th>
                <th>Judul</th>
                <th>Chapters</th>
                <th>Status</th>
                <!-- <th>Boost</th> -->
                <th>Details</th>
              </tr>
            </thead>
            <tbody v-if="filteredNovelList == 0">
              <tr>
                <td colspan="6" style="text-align: center">No records found.</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="items in filteredNovelList" :key="items.id">
                <td>{{ changeFormatDate(items.created_at) }}</td>
                <td><img :src="items.thumbnail" alt="thumbnail" /></td>
                <td>{{ items.title }}</td>
                <td>{{ items.total_chapter }}</td>
                <td style="text-transform: capitalize">{{ items.approve == 1 ? "Publish" : items.approve == 3 ? "Draft" : "Warning" }}</td>
                <!-- <td>
                  <div class="boost-expired" v-if="items.date_expired_boosting != ''">
                    <span>{{ calculateExpiredBoost(items.date_expired_boosting) }}</span>
                  </div>
                  <div class="boost-status" :class="items.total_chapter < 8 ? 'disabled-boost' : ''" v-else>
                    <img src="@/assets/boosting/rocket.svg" alt="" v-if="items.total_chapter >= 8" />
                    <img src="@/assets/boosting/rocket-off.svg" alt="" v-else />
                    <span>Boost</span>
                  </div>
                </td> -->
                <td>
                  <router-link :to="{ name: 'detail-chat-story', params: { id: items.id } }" class="detail-content"> Detail </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pages-section" >
          <p class="pages">{{ curentPage }} dari {{ totalPages }} halaman</p>
          <div class="button-page">
            <button :class="curentPage === 1 ? 'disabled-prev' : 'prev'" @click="prevPage" :disabled="curentPage === 1">&lt; Sebelumnya</button>
            <button
              :class="curentPage === totalPages ? 'disabled-next' : 'next'"
              @click="nextPage"
              :disabled="curentPage === totalPages || filteredNovelList == 0"
            >
              Selanjutnya &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import storage from "@/controllers/Localstorage";
import chatstoryController from "@/controllers/chatstory";

export default {
  setup() {
    useMeta({ title: "List Karya ChatStory" });
  },
  data() {
    return {
      session: {},
      rangeDay: 1,
      typeContent: "",
      listChatStory: [],
      searchInput: "",
      offset: 0,
      curentPage: 1,
      totalData: 0,
    };
  },
  async mounted() {
    this.session = storage.getLocalStorage();

    if (this.session.memberId) {
      await this.fetchSummary({
        member_id: this.session.memberId,
        usertoken: this.session.usertoken,
      });

      this.getListChatStory();
    } else {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    async getListChatStory() {
      const { data } = await chatstoryController.ListChatStoryMember(this.session.memberId,this.rangeDay, this.offset);
      this.listChatStory = data.DATA;
      this.totalData = data.TOTAL;
    },

    ...mapActions({
      fetchSummary: "summary/fetchSummary",
    }),

    changeFormatDate(item) {
      const date = new Date(item);
      const options = { day: "numeric", month: "long", year: "numeric" };
      return date.toLocaleDateString("id-ID", options);
    },

    async nextPage() {
      this.offset += 5;
      if (this.searchInput != "") {
        const { data } = await chatstoryController.ListChatStorySearch(this.session.memberId, this.searchInput, 5, this.offset);
        this.listChatStory = data.DATA;
        this.totalData = data.TOTAL;
        this.curentPage++;
      } else {
        this.getListChatStory();
        this.curentPage++;
      }
    },
    async prevPage() {
      this.offset -= 5;
      if (this.searchInput != "") {
        const { data } = await chatstoryController.ListChatStorySearch(this.session.memberId, this.searchInput, 5, this.offset);
        this.listChatStory = data.DATA;
        this.totalData = data.TOTAL;
        this.curentPage--;
      } else {
        this.getListChatStory();
        this.curentPage--;
      }
    },
  },
  watch: {
    rangeDay(newVal, oldVal) {
        this.getListChatStory();
        this.curentPage = 1;

    },
    searchInput() {
      this.searchContent();
    },
  },
  computed: {
    filteredNovelList() {
      return this.listChatStory.filter((item) => item.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    },

    totalPages() {
      return Math.ceil(this.totalData / 5);
    },

    ...mapGetters("summary", {
      res_summary: "get_res_summary",
    }),
    ...mapState({
      rangeDay: (state) => state.rangeDay,
    }),
    dateTime() {
      const now = new Date();
      const dateOptions = { month: "long", day: "numeric", year: "numeric" };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      };

      const dateString = now.toLocaleDateString("id-ID", dateOptions);
      const timeString = now.toLocaleTimeString("id-ID", timeOptions);

      return `per ${dateString} | ${timeString}`;
    },
  },
};
</script>

<style></style>

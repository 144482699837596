<template>
  <div class="edit-comic-nav">
    <div class="nav-edit-comic">
      <router-link :to="{ name: 'detail-comic', params: { id: comicid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Comic</p>
      <div class="divide">/</div>
      <p>Detail and Episode</p>
      <div class="divide">/</div>
      <p>Edit Details</p>
    </div>
  </div>
  <div class="edit-comic-section">
    <div class="left-edit-comic">
      <img v-if="thumbnailUrl === ''" :src="analytics_comic.thumbnail" alt="analytics-thumbnail" />
      <img :src="thumbnailUrl" v-else />
      <div class="edit-novel-thumbnail">
        <input type="file" ref="fileInput" @change="uploadThumbnail" />
      </div>
      <p class="edit-title">{{ comic_title }}</p>
      <button @click="openModal" class="delete-comic-btn">Delete <img src="@/assets/trash.png" alt="" /></button>
    </div>
    <div class="right-edit-comic">
      <form @submit.prevent="openModalEdit" class="form-section">
        <div class="form-input">
          <label for="">Title</label>
          <input type="text" v-model="analytics_comic.title" />
        </div>
        <div class="form-input">
          <label for="">Genre</label>
          <select v-model="selectedGenre">
            <option v-for="genre in genresList" :value="genre.id" :key="genre.id">
              {{ genre.name }}
            </option>
          </select>
        </div>
        <div class="form-input">
          <label for="">Event(Optional)</label>
          <select>
            <option value="">Pilih Kategori</option>
          </select>
        </div>
        <div class="form-input">
          <label for="">About</label>
          <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="analytics_comic.desc" placeholder="masukkan teks ..." />
        </div>
        <button>Submit</button>
      </form>
    </div>
    <ModalEdit :handleUpdate="handleUpdate" :showEditModal="showEditModal" @close="closeModal"></ModalEdit>
    <ModalComic :show-modal="showModal" @close="closeModal" v-if="analytics_comic.approve === 1"></ModalComic>
    <DeleteModal :comicid="comicid" :show-modal="showModal" @close="closeModal" v-else></DeleteModal>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import imageController from "@/controllers/images";
import ModalComic from "@/components/comic/ModalComic.vue";
import DeleteModal from "@/components/comic/DeleteModal.vue";
import ModalEdit from "@/components/ModalEdit.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: this.analytics_comic.title ? `${this.analytics_comic.title} | Edit Comic` : "Dashboard",
    };
  },
  data() {
    return {
      comicid: 0,
      analytics_comic: [],
      genresList: [],
      selectedGenre: "",
      comic_title: "",
      thumbnailUrl: "",
      file: "",
      showModal: false,
      showEditModal: false,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.comicid = id;
    this.fetchDetail();
    this.fetchGenre();

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_COMIC_DETAIL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Comic",
      page_path: "/edit-comic",
      firebase_screen_class: "EditComic",
      screen_class: "EditComic",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_COMIC_DETAIL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Comic",
      page_path: "/edit-comic",
      firebase_screen_class: "EditComic",
      screen_class: "EditComic",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    uploadThumbnail() {
      const fileReader = this.$refs.fileInput.files[0];
      this.file = fileReader;
      const reader = new FileReader();
      reader.readAsDataURL(fileReader);
      reader.onload = () => {
        this.thumbnailUrl = reader.result;
      };
    },
    async StoreImage() {
      if (this.file != "" || this.file != undefined || this.file != null) {
        const { status, data } = await imageController.PostImage(this.file);
        return data;
      } else {
        // change alert wiht something
        alert("something wrong");
      }
    },
    async fetchDetail() {
      const { data } = await contentController.DetailComic(this.comicid);
      this.analytics_comic = data.DATA;
      this.comic_title = this.analytics_comic.title;
      this.selectedGenre = this.analytics_comic.genres[0].id;
    },
    async fetchGenre() {
      const { data } = await contentController.GenreComic();
      this.genresList = data.DATA;
    },
    async handleUpdate(approve) {
      if (this.file == "") {
        const title = this.analytics_comic.title;
        const genre = this.selectedGenre;
        const desc = this.analytics_comic.desc;

        const { data } = await contentController.EditComic(this.comicid, title, genre, desc, this.file, approve);
        this.$router.push({
          name: "detail-comic",
          params: { id: this.comicid },
        });
      } else {
        const images = await this.StoreImage();
        const title = this.analytics_comic.title;
        const genre = this.selectedGenre;
        const desc = this.analytics_comic.desc;

        const { data } = await contentController.EditComic(this.comicid, title, genre, desc, images.DATA, approve);
        this.$router.push({
          name: "detail-comic",
          params: { id: this.comicid },
        });
      }
    },
    // async handleUpdate() {
    //     const title = this.analytics_comic.title
    //     const genre = this.selectedGenre
    //     const desc = this.analytics_comic.desc

    //     const { data } = await contentController.EditComic(this.comicid, title, genre, desc)
    //     this.$router.push({ name: 'detail-comic', params: { id: this.comicid } });
    // },
    openModal() {
      this.showModal = true;
    },
    openModalEdit() {
      this.showEditModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.showEditModal = false;
    },
  },
  components: {
    ModalComic,
    ModalEdit,
    DeleteModal,
    QuillEditor,
  },
};
</script>

<style>
@import url("../css/edit-comic.css");
</style>

<template>
  <div v-if="isMobile">
    <edit-chapter-mobile></edit-chapter-mobile>
  </div>
  <div v-else>
    <div class="n__store-nav">
      <div class="nav-store-novel">
        <router-link :to="{ name: 'detail-chat-story', params: { id: chatstoryid } }" style="display: flex; align-items: center">
          <img src="@/assets/arrow-back.png" alt="" class="arrow-back" style="width: 25px" />
        </router-link>
        <p>Edit Chapter Chat Story</p>
      </div>
    </div>
    <div class="store-chapter-cs">
      <div class="scs-left">
        <label for="" style="text-align: center">Preview</label>
        <div class="scs-boxchat" ref="boxChat" @scroll="debouncedHandleScroll">
          <div class="cs-item" v-for="item in listChatContent" :key="item.id">
            <div
              :class="item.status_receiver == '0' ? 'right' : 'left'"
              v-if="(item.status_receiver != '0' && item.character_id != 0) || (item.status_receiver != '1' && item.character_id != 0)"
            >
              <div class="chat-profileimg">
                <img :src="item.avatar" alt="" class="chat-profileimg" />
              </div>
              <div class="cs-item-wrap">
                <label
                  for=""
                  class="cs-item-name"
                  :style="item.status_receiver == '0' ? 'align-self: self-end; padding-right: 6px' : 'padding-left: 6px'"
                  >{{ item.nama_karakter }}</label
                >
                <div :class="item.status_receiver == '0' ? 'icon-right' : 'icon-left'">
                  <p :class="item.status_receiver == '0' ? 'cs-item-sender' : 'cs-item-other'" v-if="item.image == ''">{{ item.desc }}</p>
                  <div v-else :class="item.status_receiver == '0' ? 'cs-item-sender' : 'cs-item-other'">
                    <img :src="item.image" alt="" class="cs-item-img" />
                    <label for="">{{ item.desc }}</label>
                  </div>
                  <div class="cs-options">
                    <img src="@/assets/pencil-comic-icon.png" alt="" @click="openModalChar('editMsg', item.id)" />
                    <img src="@/assets/chatstory/trash-red.png" alt="" @click="deleteMessage(item.id)" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="cs-item-narator">
              {{ item.desc }}
              <img src="@/assets/pencil-comic-icon.png" alt="" class="cs-icon-narator" />
              <img src="@/assets/chatstory/trash-red.png" alt="" @click="deleteMessage(item.id)" class="cs-icon-delnar" />
            </div>
          </div>
          <div class="loader-chat" style="margin: auto" v-if="isLoading">Loading ...</div>
        </div>
      </div>
      <div class="scs-right">
        <div class="scs-title-story">
          <label for="">Judul Chapter </label>
          <input type="text" v-model="epsTitle" class="input-title-editcs" />
        </div>
        <div class="scs-add-character">
          <label for="">Pilih Karakter</label>
          <div class="scs-box-char">
            <div class="scs-list-char">
              <div class="char-item" v-for="(item, index) in character" :key="item.id" :class="item.isSelected ? 'selected-char' : ''">
                <div class="char-item-profile">
                  <div class="char-item-img">
                    <img :src="item.avatar" alt="" />
                    <img src="@/assets/chatstory/orange-edit.png" alt="" style="cursor: pointer" @click="openModalChar('edit', item.id)" />
                  </div>
                  <div @click="toggleItem(item, index)" class="char-item-descinfo">
                    <div class="char-item-name">{{ item.nama_karakter }}</div>
                    <div class="char-item-desc">{{ item.status_receiver == 0 ? "Pengirim" : "Penerima" }}</div>
                  </div>
                </div>
                <img src="@/assets/chatstory/switch-icon.png" alt="" class="char-switch" @click="openModalSwitch(item.id, item.status_receiver)" />
              </div>
              <div class="char-nara" @click="addNarator" :class="naratorSelected ? 'selected-char' : ''">
                <div class="char-nara-profile">
                  <img src="@/assets/chatstory/narator.png" alt="" />
                  <div class="char-nara-name">Narator</div>
                  <div class="char-nara-desc">Narasi</div>
                </div>
              </div>
            </div>
            <button class="btn-add-cs" @click="openModalChar('add')"><img src="@/assets/chatstory/plus-white.png" alt="" />Tambah Karakter</button>
          </div>
        </div>
        <div class="scs-msg">
          <label for="">Kirim Pesan</label>
          <div class="wrapper-input-msg">
            <textarea name="" id="" cols="30" rows="9" placeholder="Tulis Pesan" style="resize: none" v-model="message"> </textarea>
            <img src="@/assets/chatstory/plus-upload.png" alt="" @click="openModalChar('addImg')" />
            <img src="@/assets/chatstory/send-msg.png" alt="" @click="sendMessage" />
            <!-- <div class="bubble-section">
              <div class="bubble-head">
                <label for="">Bubble Mood</label>
                <img src="@/assets/chatstory/mdi_gear.png" alt="" />
              </div>

              <div class="list-bubble-mood">
                <div
                  class="bubble-item"
                  v-for="(bubble, index) in listBubble"
                  :key="bubble.id"
                  :class="bubble.isSelected ? '' : ''"
                  @click="toggleMood(bubble, index)"
                >
                  <img src="@/assets/chatstory/selected.png" alt="" v-if="bubble.isSelected" class="selected-iconbubble" />
                  <img :src="bubble.image" alt="" class="img-bubble" />
                  <label for="" :style="bubble.isSelected ? 'color: #63D5CE; font-weight: bold' : ''">{{ bubble.nama }}</label>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="scs-chapter-price">
          <label for="">Harga Chapter</label>
          <div class="scs-list-price">
            <span class="wrapper-price">
              <img src="@/assets/coin.png" alt="" class="KPoin-img" />
              <input class="kpoin-price-input" type="text" id="price" name="price-koin"
            /></span>
            <span class="wrapper-free">
              <p>Gratiskan Chapter</p>
              <input class="kpoin-free-check" type="checkbox" id="free" name="free-chapter" value="Gratis" @change="disableInputPrice" />
            </span>
          </div>
        </div> -->
        <div class="scs-submit-chapter">
          <label for="">Publikasikan Karya</label>
          <div class="scs-list-submit">
            <button @click="submitEpisode('publish')">
              <img src="@/assets/chatstory/send-white.png" alt="" class="send-img" /> <span>Publikasikan</span>
            </button>
            <button @click="submitEpisode('draft')">
              <img src="@/assets/chatstory/draft-icon.png" alt="" class="draft-img" /> <span>Simpan Draft</span>
            </button>
          </div>
        </div>
      </div>

      <add-char :showAddChar="showAddChar" @close="closeModal" :chatstoryid="chatstoryid" :getListChar="getListChar"></add-char>
      <edit-char
        :showEditChar="showEditChar"
        @close="closeModal"
        :charid="charid"
        :getListChar="getListChar"
        :charData="charData"
        :chatstoryid="chatstoryid"
      ></edit-char>
      <edit-msg :showEditMsg="showEditMsg" @close="closeModal" :detailContentChat="detailContentChat"></edit-msg>
      <add-img :showAddImg="showAddImg" @close="closeModal" :sendImageMessage="sendImageMessage"></add-img>
      <switch-modal :showSwitchModal="showSwitchModal" @close="closeModal"></switch-modal>
    </div>
  </div>
</template>

<script>
import AddChar from "./components/AddChar.vue";
import listBubble from "./listBubble";
import EditChar from "./components/EditChar.vue";
import EditMsg from "./components/EditMsg.vue";
import AddImg from "./components/AddImg.vue";
import SwitchModal from "./components/SwitchModal.vue";
import chatstoryController from "@/controllers/chatstory";
import { debounce } from "lodash";
import EditChapterMobile from "./EditChapterMobile.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      //Modal State
      showAddChar: false,
      showEditChar: false,
      showEditMsg: false,
      showAddImg: false,
      showSwitchModal: false,

      //Data
      chatstoryid: 0,
      epsid: 0,
      charid: 0,
      session: {},
      character: [],
      listChar: [],
      charData: {},
      listBubble: [],
      message: "",
      epsTitle: "",
      chat_image: "",
      detailContentChat: {},
      isLoading: false,
      isMobile: false,

      //Character Selected
      selectedItems: null,
      selectedItemIndex: null,
      naratorSelected: false,

      // Mood Bubble Selected
      selectedMood: null,
      selectedMoodIndex: null,
    };
  },
  async mounted() {
    if (window.innerWidth <= 720) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
      const id = this.$route.params.id;
      this.chatstoryid = id.split("-")[0];
      this.epsid = id.split("-")[1];

      this.getListChar();
      this.getListChatContent();
      this.getDetailEps();

      // Mood Section
      const newListBubble = listBubble.map((item) => ({
        ...item,
        isSelected: this.selectedMoodIndex === item.id,
      }));
      this.listBubble.push(...newListBubble);
      this.selectedMood = this.listBubble[0];
      this.selectedMood.isSelected = true;
    }
  },
  destroyed() {
    // Menghapus event listener pada saat komponen dihancurkan
    window.removeEventListener("scroll", this.debouncedHandleScroll);
  },
  methods: {
    disableInputPrice() {
      if (document.getElementById("free").checked) {
        document.getElementById("price").classList.add("disable_section");
      } else {
        document.getElementById("price").classList.remove("disable_section");
      }
    },
    async getListChatContent() {
      let data_req = {
        eps_id: this.epsid,
        limit: this.limit,
        offset: 0,
      };
      await this.fetchListChat(data_req);
    },

    async handleStoreUnscrolled() {
      let data_req = {
        eps_id: this.epsid,
        limit: 0,
        offset: 0,
      };
      const res = await this.handleFetchChatUnscrolled(data_req);
      if (res.data.STATUS == 200) {
        this.message = "";

        this.$nextTick(() => {
          const boxChat = this.$refs.boxChat;
          boxChat.scrollTo({
            top: boxChat.scrollHeight,
            behavior: "smooth",
          });
        });
      }
    },

    async getListChar() {
      const { data } = await chatstoryController.ListChar(this.chatstoryid);
      this.listChar = data.DATA;

      if (this.listChar.length > 0) {
        const newListChar = this.listChar.map((item) => ({
          ...item,
          isSelected: this.selectedItemIndex === item.id,
        }));
        this.character = [];
        this.character.push(...newListChar);
        this.selectedItems = this.character[0];
        this.selectedItems.isSelected = true;
        this.naratorSelected = false;
      }
    },

    async getDetailChar() {
      const { data } = await chatstoryController.DetailChar(this.charid);
      if (data.STATUS == 200) {
        this.charData = data.DATA;
      }
    },
    async changeReceiver(id, status_receiver) {
      const { data } = await chatstoryController.EditSender(id, status_receiver);
      if (data.STATUS == 200) {
        this.getListChar();
      }
    },

    async getDetailEps() {
      const { data } = await chatstoryController.DetailEpisode(this.epsid);
      this.epsTitle = data.DATA.title;
    },

    async getDetailChatContent(msgid) {},

    async sendMessage() {
      if (this.naratorSelected) {
        if (this.listChatContent.length < this.totalData) {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            0,
            this.selectedMood.id,
            this.message,
            0,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
              character_id: data.DATA.character_id,
            };

            this.handleStoreUnscrolled(newMessage);
          }
        } else {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            0,
            this.selectedMood.id,
            this.message,
            0,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
              character_id: data.DATA.character_id,
            };

            this.$store.commit("chatstory/addMessage", newMessage);
            this.message = "";
            this.$nextTick(() => {
              const boxChat = this.$refs.boxChat;
              boxChat.scrollTo({
                top: boxChat.scrollHeight,
                behavior: "smooth",
              });
            });
          }
        }
      } else {
        if (this.listChatContent.length < this.totalData) {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            this.selectedItems.id,
            this.selectedMood.id,
            this.message,
            this.selectedItems.status_receiver,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
            };

            this.handleStoreUnscrolled(newMessage);
          }
        } else {
          const { data } = await chatstoryController.StoreChatCS(
            this.chatstoryid,
            this.epsid,
            this.selectedItems.id,
            this.selectedMood.id,
            this.message,
            this.selectedItems.status_receiver,
            "" //image
          );
          if (data.STATUS == 201) {
            const newMessage = {
              avatar: data.DATA.avatar,
              created_at: data.DATA.Created_at,
              desc: data.DATA.desc,
              id: data.DATA.id,
              image: data.DATA.image,
              nama_karakter: data.DATA.nama_karakter,
              status_receiver: data.DATA.status_receiver,
            };

            this.$store.commit("chatstory/addMessage", newMessage);
            this.message = "";
            this.$nextTick(() => {
              const boxChat = this.$refs.boxChat;
              boxChat.scrollTo({
                top: boxChat.scrollHeight,
                behavior: "smooth",
              });
            });
          }
        }
      }
    },

    async sendImageMessage(item) {
      const { data } = await chatstoryController.StoreChatCS(
        this.chatstoryid,
        this.epsid,
        this.selectedItems.id,
        this.selectedMood.id,
        item.captionMsg,
        this.selectedItems.status_receiver,
        item.msgImage //image
      );
      if (data.STATUS == 201) {
        this.getListChatContent();
      }
    },

    async deleteMessage(msgid) {
      const { data } = await chatstoryController.DeleteChatCS(msgid);
      if (data.STATUS == 200) {
        this.$store.commit("chatstory/removeMessage", msgid);
      }
    },

    toggleItem(item, index) {
      this.selectedItemIndex = index;
      this.selectedItems = item;
      this.naratorSelected = false;

      this.character.forEach((listItem, i) => {
        listItem.isSelected = this.selectedItemIndex === i;
      });
    },

    toggleMood(item, index) {
      this.selectedMoodIndex = index;
      this.selectedMood = item;
      // this.naratorSelected = false;

      this.listBubble.forEach((listItem, i) => {
        listItem.isSelected = this.selectedMoodIndex === i;
      });
    },

    addNarator() {
      this.naratorSelected = true;
      this.selectedItemIndex = null;
      this.selectedItems = null;

      this.character.forEach((listItem, i) => {
        listItem.isSelected = this.selectedItemIndex === i;
      });
    },

    async openModalChar(type, id) {
      if (type == "add") {
        this.showAddChar = true;
      } else if (type == "edit") {
        this.charid = id;
        this.getDetailChar();
        this.showEditChar = true;
      } else if (type == "editMsg") {
        const { data } = await chatstoryController.DetailChatCS(id);
        if (data.STATUS == 200) {
          this.detailContentChat = data.DATA;
          this.showEditMsg = true;
        }
      } else if (type == "addImg") {
        this.showAddImg = true;
      }
    },

    openModalSwitch(id, status_receiver) {
      console.log(id);
      console.log(status_receiver);
      this.changeReceiver(id, status_receiver);
      // this.showSwitchModal = true;
    },

    closeModal() {
      this.showAddChar = false;
      this.showEditChar = false;
      this.showEditMsg = false;
      this.showAddImg = false;
      this.showSwitchModal = false;
    },

    debouncedHandleScroll: debounce(function () {
      if (screen.width > 600) {
        const boxChat = this.$refs.boxChat;
        const scrollTop = boxChat.scrollTop;
        const scrollHeight = boxChat.scrollHeight;
        const clientHeight = boxChat.clientHeight;

        // Calculate the percentage of scrolled height
        const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

        if (scrolledPercentage >= 95 && this.offset < this.totalData) {
          this.loadMoreData();
        } else {
          return;
        }
      } else {
        return;
      }
    }, 200), // Nilai debounce (dalam milidetik)

    async loadMoreData() {
      if (this.listChatContent.length == this.totalData) {
        return;
      } else {
        try {
          this.isLoading = true;
          const newOffset = this.offset + this.limit;
          this.updateOffset(newOffset);
          let data_req = {
            eps_id: this.epsid,
            limit: this.limit,
            offset: newOffset,
          };
          this.loadMoreChat(data_req);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.error("Error fetching more data:", error);
        }
      }
    },

    async submitEpisode(type) {
      if (type == "publish") {
        const { data } = await chatstoryController.EditEpisode(this.chatstoryid, this.epsid, 1, this.epsTitle);
        if (data.STATUS == 200) {
          this.$router.push("/detail-chat-story/" + this.chatstoryid);
        }
      } else {
        const { data } = await chatstoryController.EditEpisode(this.chatstoryid, this.epsid, 3, this.epsTitle);
        if (data.STATUS == 200) {
          this.$router.push("/detail-chat-story/" + this.chatstoryid);
        }
      }
    },

    ...mapActions({
      fetchListChat: "chatstory/fetchListChat",
      handleFetchChatUnscrolled: "chatstory/handleFetchChatUnscrolled",
      loadMoreChat: "chatstory/loadMoreChat",
      resetOffset: "chatstory/resetOffset",
    }),

    ...mapActions("chatstory", ["updateLimit", "updateOffset"]),
  },
  computed: {
    ...mapGetters("chatstory", {
      listChatContent: "get_res_chatstory",
      totalData: "get_totalData",
    }),
    ...mapState("chatstory", ["limit", "offset"]),
  },
  components: {
    AddChar,
    EditChar,
    EditMsg,
    AddImg,
    SwitchModal,
    EditChapterMobile,
  },
};
</script>

<style>
.input-title-editcs {
  border: none;
  padding-left: 5px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

.input-title-editcs:focus {
  outline: none;
}
.disable_section {
  pointer-events: none;
  background-color: #d7d7d7;
  opacity: 0.4;
}

@import url("./css/create-chapter.css");
</style>

<template>
    <div class="modal" v-if="showCompleteModal">
        <div class="modal-mark-novel">
            <div class="modal-mark-head">
                <p>Apakah kamu yakin ingin menandai novel telah selesai?</p>
            </div>
            <div class="modal-mark-body">
                <button @click="$emit('close')">Batal</button>
                <button @click.prevent="fetchMarkValue">Submit</button>
            </div>
        </div>
    </div>
</template>
    
<script>

export default {
    name: "show-barcode",
    props: {
        showCompleteModal: {
            type: Boolean,
            default: false
        },
        fetchMarkValue: {
            type: Function,
            required: true
        }
    },
}
</script>
    
<style>
@import url('./css/markmodal.css');
</style>
  
<template>
  <div class="modal-option" v-if="showOptionBoost">
    <div class="option-popup-section">
      <div class="ops-head">
        <div></div>
        <div class="ops-title">Pilih Halaman Booster</div>
        <div @click="$emit('close')" v-if="!isMobile" class="ops-close">X</div>
      </div>
      <div class="ops-desc">Pilih halaman yang ingin kamu gunakan untuk boost karyamu</div>
      <div class="ops-list-page">
        <div class="ops-home" @click="redirectBoost(1)">
          <img src="../../assets/boosting/Home.png" alt="" />
          <label for="">Boost di Home</label>
        </div>
        <div class="ops-novel" @click="redirectBoost(2)">
          <img src="../../assets/boosting/Novel.png" alt="" />
          <label for="">Boost di Novel</label>
        </div>
      </div>
      <div class="ops-cancel" v-if="isMobile">
        <span @click="$emit('close')">Batal</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showOptionBoost: {
      type: Boolean,
      default: false,
    },
    redirectBoost: {
      type: Function,
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  async mounted() {
    if (window.innerWidth < 500) {
      this.isMobile = true;
    }
  },
};
</script>

<style>
@import url("./css/option-boost.css");
</style>

<template>
  <div class="modal" v-if="showPopupBoost">
    <div class="popup-boost-section">
      <div class="pbs-head">
        <div></div>
        <div class="pbs-title">{{ dataOrder.antrian > 0 ? "Nomor Antrianmu" : "Boosting Karya" }}</div>
        <div></div>
        <!-- <div @click="$emit('close')" class="pbs-close">X</div> -->
      </div>
      <div class="pbs-number" v-if="dataOrder.antrian > 0">
        <span>{{ dataOrder.antrian }}</span>
        <div class="pbs-boost-date">
          <label for="">Karya kamu akan di-boost pada tanggal</label>
          <span>{{ estimasi }}</span>
        </div>
      </div>

      <div class="pbs-include">
        <label for="">Boosting sudah termasuk</label>
        <div class="pbs-include-benefit" v-html="dataOrder.benefit"></div>
      </div>
      <div class="pbs-total">
        <label for="">Total Booster</label>
        <div class="pbs-total-paket">
          <label for="">Paket</label>
          <span style="text-transform: capitalize">{{ dataOrder.paketName }}</span>
        </div>
        <div class="pbs-mycoin">
          <label for=""> Jumlah coin kamu </label>
          <div class="pbs-item-coin">
            <img src="../../assets/boosting/coin.svg" alt="" />
            <span>{{ dataOrder.coin }}</span>
          </div>
        </div>
        <div class="pbs-price-total">
          <label for=""> Harga Paket </label>
          <div class="pbs-item-coin">
            <img src="../../assets/boosting/coin.svg" alt="" />
            <span>{{ dataOrder.price }}</span>
          </div>
        </div>
        <div class="pbs-myleftcoin">
          <label for=""> Sisa coin kamu </label>
          <div class="pbs-item-coin">
            <img src="../../assets/boosting/coin.svg" alt="" />
            <span style="font-weight: 700">{{ coinLeft }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="pbs-kpoin">
        <div class="pbs-kpoin-head">
          <div class="line"></div>
          <span style="text-align: center">Atau</span>
          <div class="line"></div>
        </div>
        <div class="pbs-toogle">
          <div class="pbs-toogle-kpoin">
            <label class="sn-switch">
              <input type="checkbox" />
              <div class="sn-slider round"></div>
            </label>
            <p>Bayar dengan KPoin</p>
          </div>
          <div class="pbs-mypoin">
            <img src="@/assets/boosting/poin-icon.png" alt="" />
            <label for="">80</label>
          </div>
        </div>
      </div> -->
      <div class="pbs-info">
        <ul>
          <li>Koin kamu akan <span>dipotong secara otomatis</span> setelah melanjutkan</li>
          <li>Tanggal boosting <span>dapat dimajukan</span> dan akan diberitahukan melalui notifikasi dan email</li>
          <li>Bila Kamu membayar <span>menggunakan KPoin, maka Koin kamu tidak terpotong</span> selama saldo KPoin cukup</li>
          <li><span>Pembayaran tidak dapat dicampur</span> antara KPoin dengan Koin</li>
        </ul>
      </div>
      <div class="pbs-policy">
        <p>Dengan Melanjutkan saya menyetujui <span class="pbs-policy-link">Syarat & Ketentuan</span> yang berlaku</p>
      </div>
      <div class="pbs-btn-section">
        <label for="">Pembayaran bisa menggunakan KPoin atau Koin</label>
        <div class="pbs-button">
          <button :class="disableKpoin ? 'disabled-kpoin' : 'pbs-btn-kpoin'" @click="handleOrderPoin">
            <label for=""> KPoin </label>

            <div class="btn-kpoin-info">
              <img src="../../assets/boosting/disabled-poin.png" alt="" v-if="disableKpoin" />
              <img src="../../assets/boosting/poin-icon.png" alt="" v-else />
              <span>{{ dataOrder.price_poin }}</span>
            </div>
          </button>
          <button @click="HandleOrderCoin" :class="disableCoin ? 'disabled-kpoin' : 'pbs-btn-koin'">
            <label for=""> Coin </label>

            <div class="btn-koin-info">
              <img src="../../assets/boosting/coin.svg" alt="" />
              <span>{{ dataOrder.price }}</span>
            </div>
          </button>
        </div>
      </div>
      <div class="pbs-close-btn">
        <span @click="$emit('close')">Batal</span>
      </div>
    </div>
  </div>
</template>

<script>
import BoostingController from "../../controllers/boosting";

export default {
  props: {
    showPopupBoost: {
      type: Boolean,
      default: false,
    },
    dataOrder: {
      type: Object,
      default: {},
    },
    coinLeft: {
      type: Number,
    },
    estimasi: {
      type: String,
    },
  },
  data() {
    return {
      myPoin: this.dataOrder.mypoin,
    };
  },
  methods: {
    async HandleOrderCoin() {
      if (this.disableCoin) {
        return;
      } else {
        if (this.dataOrder.antrian > 0) {
          const { data } = await BoostingController.OrderBoostAntrian(this.dataOrder);
          if (data.STATUS == 201) {
            this.$router.push("/list-boost-karya");
          }
        } else {
          const { data } = await BoostingController.OrderBoost(this.dataOrder);
          if (data.STATUS == 201) {
            this.$router.push("/list-boost-karya");
          }
        }
      }
    },

    async handleOrderPoin() {
      if (this.disableKpoin) {
        return;
      } else {
        if (this.dataOrder.antrian > 0) {
          const { data } = await BoostingController.OrderBoostAntrianKpoin(this.dataOrder);
          if (data.STATUS == 201) {
            this.$router.push("/list-boost-karya");
          }
        } else {
          const { data } = await BoostingController.OrderBoostKpoin(this.dataOrder);
          if (data.STATUS == 201) {
            this.$router.push("/list-boost-karya");
          }
        }
      }
    },
  },
  computed: {
    disableKpoin() {
      return this.dataOrder.mypoin < this.dataOrder.price_poin || this.dataOrder.price_poin == 0;
    },
    disableCoin() {
      return this.coinLeft <= 0;
    },
  },
};
</script>

<style>
@import url("./css/popup-boost.css");
</style>

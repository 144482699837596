<template>
    <div class="modal" v-if="showModal">
        <div class="modal-content">
            <div class="modal-head">
                <img src="@/assets/warn-modal.png" alt="">
                <p>Warning</p>
                <span class="closing" @click="$emit('close')">&times;</span>
            </div>
            <div class="modal-body">
                <p>Maaf kamu sudah tidak dapat menghapus comic yang sudah dipublikasikan. Jika Ingin menghapus, harap
                    hubungi
                    admin.</p>
                <h4>Hubungi Admin!</h4>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "show-barcode",
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {

        }
    },
}
</script>
    
<style>
@import url('./css/modalcomic.css');
</style>
  
<template>
  <div class="detail-novel-nav">
    <div class="nav-detail-novel">
      <router-link to="/table-content">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-novel-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Novel</p>
      <div class="divide">/</div>
      <p>Detail and Chapters</p>
    </div>
  </div>
  <div class="detail-novel-section">
    <div class="left-detail-novel">
      <img :src="noveldetail.images_novel" alt="thumbnail" />
      <p class="detail-novel-title">{{ noveldetail.title }}</p>
      <div class="box-info-hiatus-n" v-if="isHiatus">
        <div class="bihn-head">
          <img src="../../../assets/hiatus/jam-pasir.svg" alt="" />
          <label for="">Status hiatus diaktifkan!</label>
        </div>
        <div class="bihn-line"></div>
        <div class="bihn-body">
          <p>
            Upload chapter sebelum tanggal <span>{{ dateArsip }}</span> sebelum karya kamu diarsipkan
          </p>
        </div>
      </div>
      <div class="box-info-archive-n" v-if="isArchive">
        <div class="bian-head">
          <img src="../../../assets/hiatus/folder-icon.svg" alt="" />
          <label for="">Karya kamu di arsipkan !</label>
        </div>
        <div class="bian-line"></div>
        <div class="bian-body">
          <p>Karya kamu tidak ditampilkan di Klaklik, karena kamu tidak aktif selama 2 bulan. Upload chapter baru untuk menampilkan kembali</p>
        </div>
      </div>
      <div class="box-novel-action">
        <img src="../../../assets/hiatus/pencil-white.svg" alt="" class="n-pencil-hiatus-off" v-if="isFinish || isHiatus || isArchive" />
        <img src="../../../assets/hiatus/pencil-white.svg" alt="" class="n-pencil-hiatus" @click="redirectEditNovel(novelid)" v-else />
        <img src="../../../assets/hiatus/stats-white.svg" alt="" class="n-stats-hiatus" @click="redirectAnalytic(novelid)" />
        <img src="../../../assets/boosting/rocket.svg" alt="" class="n-boost-novel" @click="openOptionBoost" v-if="canBoost" />
        <img src="../../../assets/boosting/rocket.svg" alt="" class="n-boostoff-novel" v-else />
      </div>
      <div class="mark-novel-section">
        <span>Tandai Judul</span>
        <img src="../../../assets//hiatus/information.svg" alt="" @click="openAboutHiatus" />
      </div>
      <div class="mark-list-novel-option" :style="isArchive || isNovelDraft ? { opacity: '0.4' } : {}">
        <div class="mlno-hiatus" :style="isFinish ? { opacity: '0.4' } : {}">
          <div :class="isFinish ? 'mlnoh-wrap-off' : isHiatus ? 'mlnoh-wrap-hiatus' : 'mlnoh-wrap'">
            <img src="../../../assets/hiatus/hiatus-white.png" alt="" v-if="isHiatus" />
            <img src="../../../assets/hiatus/hiatus.svg" alt="" v-else />
            <span>Hiatus</span>
          </div>
          <div class="switch" @click.prevent="!isFinish && !isArchive && !isNovelDraft ? hiatusSwitch() : null">
            <input type="checkbox" id="switch" v-model="isHiatus" />
            <label for="switch" class="slider" :style="isFinish ? { opacity: '0.2' } : {}"></label>
          </div>
        </div>
        <div class="mlno-divider"></div>
        <div class="mlno-selesai">
          <div :class="isFinish ? 'mlnos-wrap-off' : 'mlnos-wrap'">
            <img src="../../../assets/hiatus/flag-white.svg" alt="" v-if="isFinish" />
            <img src="../../../assets/hiatus/flag.svg" alt="" v-else />
            <span>Selesai</span>
          </div>
          <div class="switch" @click.prevent="!isFinish && !isArchive && !isNovelDraft ? finishSwitch() : null">
            <input type="checkbox" id="switch" v-model="isFinish" />
            <label for="switch" class="slider" :style="isFinish ? { opacity: '0.4' } : {}"></label>
          </div>
        </div>
      </div>
    </div>
    <div class="right-detail-novel">
      <div class="head-right">
        <div class="head-right-marks">
          <p class="right-ndetail-title">List Chapter</p>
        </div>
        <div class="right-novel-line"></div>
        <div class="create-chapter-btn" @click="isFinish ? null : openPreChapter()" :style="isFinish ? { opacity: '0.2' } : {}">
          + Tambah Chapter Baru
        </div>
      </div>
      <!-- If chapter empty -->
      <div class="detail-novel-empty" v-if="list_chapter.length < 1">
        <img src="@/assets/content-null.png" alt="" />
        <h4>Kamu belum ada chapter nih</h4>
        <p>Upload Chapter untuk karya kamu!</p>
      </div>
      <!-- Else -->
      <div v-else class="chapter-list-section" @scroll="handleScroll">
        <div v-for="(listchapter, index) in list_chapter" :key="listchapter.id" class="box-chapter-novel">
          <div class="box-left-chapter">
            <h3>{{ listchapter.title }}</h3>
            <div class="update-views">
              <p>{{ listchapter.created_at }}</p>
              -
              <span>{{ listchapter.total_views }} views</span>
            </div>
            <div class="box-line"></div>
            <div class="n-box-icons">
              <div class="n-box-icons-edit" @click="!isFinish && !isHiatus && !isArchive ? redirectEditChapter(listchapter.id) : null">
                <img
                  src="@/assets/edit-icon.png"
                  alt="edit"
                  class="edit-icon-novel"
                  :style="isFinish || isHiatus || isArchive ? { opacity: '0.4' } : {}"
                />
              </div>
              <div
                v-if="listchapter.approve != 'publish' && listchapter.approve != 'schedule'"
                class="delete-icon-novel"
                @click="openDeleteChapter(listchapter.id)"
              >
                <img src="@/assets/delete-icon-chapter.png" alt="" />
              </div>
              <div class="n-box-icons-preview" @click="$router.push('/preview-chapter/' + listchapter.id)">
                <img src="../../../assets/eye-preview.svg" alt="preview-chapter" />
              </div>
              <p :style="listchapter.approve === 'schedule' ? { fontSize: '13px', backgroundColor: '#58728B' } : {}">
                {{ listchapter.approve === "schedule" ? `Scheduled ${listchapter.schedule_time.slice(0, 16)}` : listchapter.approve }}
              </p>
              <div class="order-novel" v-if="listchapter.price > 0">
                <div class="order-novel-img">
                  <img src="@/assets/order-icon.png" alt="edit" class="order-icon-novel" />
                </div>
                <div class="order-novel-desc">
                  {{ listchapter.total_order }}
                </div>
              </div>
              <!-- <img src="@/assets/order-icon.png" alt="edit" class="order-icon-novel"> -->
            </div>
          </div>
          <div class="box-right-chapter">
            <p>{{ index + 1 }}</p>
          </div>
        </div>
        <div v-if="list_chapter.length < totalChapter" class="loadmore-chapter" @click="fetchMoreChapter">Show More</div>
      </div>
    </div>
    <MarkCompleteModal :showCompleteModal="showCompleteModal" :fetchMarkValue="fetchMarkValue" @close="closeModal"> </MarkCompleteModal>
    <PreChapterModal :redirectStoreChapter="redirectStoreChapter" :showPreChapter="showPreChapter" @close="closeModal"> </PreChapterModal>
    <ModalDeleteChapter
      :handleDelete="handleDelete"
      :chapterid="chapterid"
      :showDeleteChapter="showDeleteChapter"
      @close="closeModal"
      :warnMsg="warnMsg"
    ></ModalDeleteChapter>
    <AboutHiatus :showAboutHiatus="showAboutHiatus" @close="closeModal" />
    <FinishPopup :showFinishPopup="showFinishPopup" @close="closeModal" :submitFinish="fetchMarkValue" />
    <HiatusPopup :showHiatusPopup="showHiatusPopup" @close="closeModal" :submitHiatus="submitHiatus" />
    <WarnHiatus :showWarnHiatus="showWarnHiatus" @close="closeModal" :msgHiatus="msgHiatus" />
    <option-boost :showOptionBoost="showOptionBoost" @close="closeModal" :redirectBoost="redirectBoost"></option-boost>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import PreChapterModal from "@/components/novel/PreChapterModal.vue";
import storage from "@/controllers/Localstorage";
import MarkCompleteModal from "@/components/novel/MarkCompleteModal.vue";
import ModalDeleteChapter from "@/components/ModalDeleteChapter.vue";
import AboutHiatus from "@/components/hiatus/AboutHiatus.vue";
import FinishPopup from "@/components/hiatus/FinishPopup.vue";
import HiatusPopup from "@/components/hiatus/HiatusPopup.vue";
import WarnHiatus from "@/components/hiatus/WarnHiatus.vue";
import OptionBoost from "@/components/boosting/OptionBoost.vue";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: this.noveldetail.title ? `${this.noveldetail.title} | Detail Novel` : "Dashboard",
    };
  },
  data() {
    return {
      noveldetail: {},
      novelid: 0,
      chapterid: 0,
      offset: 0,
      list_chapter: {},
      showPreChapter: false,
      showCompleteModal: false,
      showDeleteChapter: false,
      showAboutHiatus: false,
      showFinishPopup: false,
      showHiatusPopup: false,
      showWarnHiatus: false,

      showOptionBoost: false,
      session: {},
      memberId: 0,
      isHiatus: false,
      isFinish: false,
      isArchive: false,
      isNovelDraft: false,
      warnMsg: "",
      totalChapter: 0,
      isAllPublish: false,
      msgHiatus: "",
      dateArsip: "",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.session = storage.getLocalStorage();
    const { data } = await contentController.DetailNovel(id);
    this.memberId = data.DATA.novel_detail.member_id;

    if (parseInt(this.memberId) !== parseInt(this.session.memberId)) {
      this.$router.push("/");
    } else {
      this.noveldetail = data.DATA.novel_detail;
      this.novelid = data.DATA.novel_detail.id;
      this.dateArsip = data.DATA.novel_detail.date_arsip;
      this.fetchListChapter();

      if (this.noveldetail.approve == 5) {
        this.isFinish = true;
      } else if (this.noveldetail.approve == 7) {
        this.isHiatus = true;
      } else if (this.noveldetail.approve == 8) {
        this.isArchive = true;
      } else if (this.noveldetail.approve == 3) {
        this.isNovelDraft = true;
      }
    }

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_DETAIL_CONTENT_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Novel",
      page_path: "/detail-novel",
      firebase_screen_class: "DetailNovel",
      screen_class: "DetailNovel",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_DETAIL_CONTENT_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Detail Novel",
      page_path: "/detail-novel",
      firebase_screen_class: "DetailNovel",
      screen_class: "DetailNovel",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async fetchListChapter() {
      const { data } = await contentController.ListChapter(this.novelid, 4, this.offset);
      this.list_chapter = data.DATA.chapter;
      this.totalChapter = data.DATA.total_chapter;
      // this.chapterid = this.list_chapter[0].id;

      this.isAllChapterPublished = this.list_chapter.every((chapter) => chapter.approve === "publish");
    },
    async fetchMoreChapter() {
      this.offset += 4;
      const { data } = await contentController.ListChapter(this.novelid, 4, this.offset);
      this.list_chapter.push(...data.DATA.chapter);
    },

    async handleDelete(id) {
      await contentController.DeleteChapter(id);
      this.offset = 0;
      this.fetchListChapter();
      this.closeModal();
    },
    async fetchMarkValue() {
      const { data } = await contentController.MarkNovelComplete(this.novelid);
      this.isFinish = true;
      this.isHiatus = false;
      this.closeModal();
      await contentController.DetailNovel(this.novelid);
    },
    handleScroll() {
      const element = document.querySelector(".chapter-list-section");
      if (this.noveldetail.value_button_markcomplete === 0) {
        if (Math.ceil(element && element.scrollTop + element.clientHeight) >= element.scrollHeight) {
          // this.fetchMoreChapter();
        }
      } else {
        if (Math.ceil((element && element.scrollTop + element.clientHeight) + 1) >= element.scrollHeight) {
          // this.fetchMoreChapter();
        }
      }

      // this.fetchMoreChapter();
      // console.log("scroltop", element.scrollTop)
      // console.log("scrollclientHeight", element.clientHeight)
      // this.fetchMoreChapter();
      // console.log("scroll + css", Math.round((element && element.scrollTop + element.clientHeight)))
      // this.fetchMoreChapter();
      // if (element.scrollHeight === 564 || element.scrollHeight * 2) {
      //     this.fetchMoreChapter();
      // }

      let element1 = Math.ceil((element && element.scrollTop + element.clientHeight) + 1);
      // let element1 = element && element.scrollTop + element.clientHeight
      // if (Math.ceil((element && element.scrollTop + element.clientHeight)) >= element.scrollHeight) {
      //     this.fetchMoreChapter();
      // }
    },
    openPreChapter() {
      this.showPreChapter = true;
    },
    openMarkComplete() {
      // this.switchValue = !this.switchValue;
      // this.showCompleteModal = true;
    },
    hiatusSwitch() {
      if (this.list_chapter.length > 0) {
        if (this.isHiatus) {
          this.msgHiatus = "Kamu harus menambahkan chapter baru untuk menonaktifkan hiatus";
          this.showWarnHiatus = true;
        } else {
          if (this.list_chapter.every((listchapter) => listchapter.approve === "publish")) {
            this.openHiatusPopup();
          } else if (this.list_chapter.some((listchapter) => listchapter.approve === "draft")) {
            this.msgHiatus = "Kamu masih memiliki chapter yang masih draft. Harap publish semua chaptermu untuk hiatuskan judul.";
            this.showWarnHiatus = true;
          } else if (this.list_chapter.some((listchapter) => listchapter.approve === "schedule")) {
            this.msgHiatus = "Kamu tidak dapat hiatuskan judul karena masih ada chapter yang kamu jadwalkan.";
            this.showWarnHiatus = true;
          }
        }
      } else {
        this.msgHiatus = "Kamu setidaknya harus memiliki 1 chapter untuk hiatuskan judul.";
        this.showWarnHiatus = true;
      }
    },
    finishSwitch() {
      if (this.list_chapter.length > 0) {
        if (this.list_chapter.every((listchapter) => listchapter.approve === "publish")) {
          this.openFinishPopup();
        } else if (this.list_chapter.some((listchapter) => listchapter.approve === "draft")) {
          this.msgHiatus = "Kamu masih memiliki chapter yang masih draft. Harap publish semua chaptermu untuk menyelesaikan judul.";
          this.showWarnHiatus = true;
        } else if (this.list_chapter.some((listchapter) => listchapter.approve === "schedule")) {
          this.msgHiatus = "Kamu tidak dapat menyelesaikan judul karena masih ada chapter yang kamu jadwalkan.";
          this.showWarnHiatus = true;
        }
      } else {
        this.msgHiatus = "Kamu setidaknya harus memiliki 1 chapter untuk menyelesaikan judul.";
        this.showWarnHiatus = true;
      }
    },

    async submitHiatus() {
      const { data } = await contentController.HiatusNovel(this.novelid);
      if (data.STATUS == 200) {
        const { data: novelData } = await contentController.DetailNovel(this.novelid);
        if (novelData.STATUS == 200) {
          this.dateArsip = novelData.DATA.novel_detail.date_arsip;
          this.isHiatus = true;
          this.closeModal();
        }
      }
    },
    openDeleteChapter(id) {
      this.chapterid = id;
      this.showDeleteChapter = true;
      this.warnMsg = "Apakah kamu yakin ingin menghapus chapter?";
    },
    closeModal() {
      this.showPreChapter = false;
      this.showCompleteModal = false;
      this.showDeleteChapter = false;
      this.showAboutHiatus = false;
      this.showFinishPopup = false;
      this.showHiatusPopup = false;
      this.showWarnHiatus = false;
      this.showOptionBoost = false;
    },
    redirectStoreChapter() {
      this.$router.push({ name: "create-chapter", params: { id: this.novelid } });
    },

    redirectEditNovel() {
      this.$router.push({ name: "edit-novel", params: { id: this.novelid } });
    },

    redirectAnalytic() {
      this.$router.push({ name: "analytic-novel", params: { id: this.novelid } });
    },

    openAboutHiatus() {
      this.showAboutHiatus = true;
    },

    openFinishPopup() {
      this.showFinishPopup = true;
    },

    openHiatusPopup() {
      this.showHiatusPopup = true;
    },

    redirectEditChapter(id) {
      this.$router.push({ name: "edit-chapter", params: { id: id } });
    },

    openOptionBoost() {
      this.showOptionBoost = true;
    },

    redirectBoost(target) {
      this.$router.push("/boost-karya/novel" + "-" + this.novelid + "-" + target + "-" + this.noveldetail.title);
    },
  },
  components: {
    PreChapterModal,
    MarkCompleteModal,
    ModalDeleteChapter,
    AboutHiatus,
    FinishPopup,
    HiatusPopup,
    WarnHiatus,
    OptionBoost,
  },
  computed: {
    canBoost() {
      if (this.noveldetail.date_expired_boosting == "" && this.totalChapter >= 10) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import url("../css/detail-novel.css");
</style>

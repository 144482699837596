import chatStoryController from "@/controllers/chatstory";

export default {
  namespaced: true,
  state: {
    res_chatstory: {},
    limit: 10,
    offset: 0,
    totalData: 0,
  },

  getters: {
    get_res_chatstory: (state) => state.res_chatstory,
    get_limit: (state) => state.limit,
    get_offset: (state) => state.offset,
    get_totalData: (state) => state.totalData,
  },

  mutations: {
    setChatStory(state, res_chatstory) {
      state.res_chatstory = res_chatstory;
    },
    setLimit(state, newLimit) {
      state.limit = newLimit;
    },
    setOffset(state, newOffset) {
      state.offset = newOffset;
    },
    setTotalData(state, totalData) {
      state.totalData = totalData;
    },
    addMessage(state, newMessage) {
      state.res_chatstory.push(newMessage);
    },
    removeMessage(state, msgid) {
      state.res_chatstory = state.res_chatstory.filter((message) => message.id !== msgid);
    },
    updateMessage(state, updatedMessage) {
      const index = state.res_chatstory.findIndex((message) => message.id === updatedMessage.id);
      if (index !== -1) {
        state.res_chatstory.splice(index, 1, updatedMessage);
      }
    },
  },
  actions: {
    async fetchListChat({ commit }, data_req) {
      const res = await chatStoryController.ListChatCS(data_req.eps_id, data_req.limit, data_req.offset);
      commit("setChatStory", res.data.DATA);
      commit("setTotalData", res.data.TOTAL);
    },

    async handleFetchChatUnscrolled({ commit }, data_req) {
      commit("setChatStory", []);
      const res = await chatStoryController.ListChatCS(data_req.eps_id, data_req.limit, data_req.offset);
      commit("setChatStory", res.data.DATA);
      commit("setTotalData", res.data.TOTAL);
      return res;
    },

    async loadMoreChat({ commit, state }, data_req) {
      const res = await chatStoryController.ListChatCS(data_req.eps_id, data_req.limit, data_req.offset);
      const updatedResChat = [...state.res_chatstory, ...res.data.DATA];
      commit("setChatStory", updatedResChat);
    },

    updateLimit({ commit }, newLimit) {
      commit("setLimit", newLimit);
    },
    updateOffset({ commit }, newOffset) {
      commit("setOffset", newOffset);
    },
    resetOffset({ commit }) {
      commit("setOffset", 0);
    },
  },
};

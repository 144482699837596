<template>
  <div class="modal-content-comic" v-if="showAddContent">
    <div class="mcc-content">
      <!-- <div class="mcc-left">
                Preview
            </div> -->
      <div class="mcc-right">
        <div class="mcc-close-btn" @click="closePopup">X</div>
        <h4>Tambah Konten</h4>
        <div class="mcc-right-line"></div>
        <div class="btn-mcc-panel">
          <img src="@/assets/plus-white-icon.png" alt="" />
          <span onclick="document.getElementById('fileContent').click()"
            >Tambah Panel</span
          >
          <input
            type="file"
            id="fileContent"
            ref="fileContent"
            @change="handleFileChange"
            multiple
          />
        </div>
        <div class="warn-add-panel" v-if="files.length > 0">
          Tambahkan Urutan Halaman Panel!
        </div>
        <div class="list-comic-panel" v-if="files.length > 0">
          <div class="panel-item" v-for="(file, index) in files" :key="index">
            <div class="panel-item-left">
              <input
                type="text"
                v-model="file.caption"
                required
                placeholder="0"
              />
              <div class="panel-item-desc">
                <img :src="file.url" alt="" />
                <p>{{ file.name }}</p>
              </div>
            </div>
            <div class="panel-item-right" @click="removeImage(index)">X</div>
          </div>
        </div>
        <div
          class="upload-panel-btn"
          v-if="files.length > 0"
          @click="uploadPanel"
        >
          <div class="process-panel-btn" v-if="isLoading">
            <div class="spinner"></div>
          </div>
          <div class="save-panel-btn" v-else>Simpan</div>
        </div>
      </div>
    </div>
    <ModalFormError
      :errorMsg="errorMsg"
      :showFormError="showFormError"
      @close="closeModal"
    ></ModalFormError>
  </div>
</template>

<script>
import imageController from "@/controllers/images";
import contentController from "@/controllers/content";
import storage from "@/controllers/Localstorage";
import ModalFormError from "@/components/ModalFormError.vue";

export default {
  props: {
    showAddContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listPanel: {},
      episodeid: 0,
      files: [],
      showFormError: false,
      isLoading: false,
      imageUrls: [],
      session: {},
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.episodeid = id;
    this.session = storage.getLocalStorage();
    this.getListPanel(id);
  },
  methods: {
    async getListPanel(id) {
      const { data } = await contentController.GetListPanel(
        id,
        this.session.usertoken
      );
      this.listPanel = data;
    },
    handleFileChange() {
      const newFiles = Array.from(this.$refs.fileContent.files);
      this.files.push(...newFiles);
      this.previewImages();
    },
    previewImages() {
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        file.caption = "";
        const reader = new FileReader();

        reader.onload = (e) => {
          this.files[i].url = e.target.result;
          this.files = [...this.files];
        };

        reader.readAsDataURL(file);
      }
    },
    async uploadPanel() {
      this.isLoading = true;
      const { data } = await imageController.PostImageEps(
        this.files,
        this.files.length
      );
      this.imageUrls = data.DATA;
      for (let i = 0; i < this.imageUrls.length; i++) {
        const imageData = this.imageUrls[i];
      }
      const captionData = this.files.map((file) => ({ caption: file.caption }));
      const isCaptionEmpty = captionData.some((item) => !item.caption);
      if (!isCaptionEmpty) {
        const { data } = await contentController.PostChapterMulti(
          this.episodeid,
          this.imageUrls,
          captionData
        );
        if (data.STATUS === 200) {
          this.files = [];
          this.imageUrls = [];
          this.isLoading = false;
          this.$emit("close");
        }
      } else {
        this.showFormError = true;
        this.errorMsg = "Halaman tidak boleh kosong!";
      }
    },
    closePopup() {
      this.files = [];
      this.$emit("close");
    },
    closeModal() {
      this.showFormError = false;
    },
    async removeImage(index) {
      this.files.splice(index, 1);
    },
  },
  components: {
    ModalFormError,
  },
};
</script>

<style>
#fileContent {
  display: none;
}

@import url("./css/add-content-comic.css");
</style>

<template>
  <div class="w-monthly">
    <div class="w-monthly-head">Pendapatan Perbulan</div>
    <div class="w-monthly-nav">
      <div class="wmn-item" :class="selectedNav == 'android' ? 'wmn-item-active' : ''" @click="changeNav('android')">
        <label for="">Android</label>
        <img src="../../../assets/wallet/ceklis.png" alt="" v-if="selectedNav == 'android'" />
      </div>
      <div class="wmn-item" :class="selectedNav == 'ios' ? 'wmn-item-active' : ''" @click="changeNav('ios')">
        <label for="">Ios</label>
        <img src="../../../assets/wallet/ceklis.png" alt="" v-if="selectedNav == 'ios'" />
      </div>
      <div class="wmn-item" :class="selectedNav == 'web' ? 'wmn-item-active' : ''" @click="changeNav('web')">
        <label for="">Website</label>
        <img src="../../../assets/wallet/ceklis.png" alt="" v-if="selectedNav == 'web'" />
      </div>
    </div>
    <div class="w-monthly-table">
      <table class="w-table">
        <tr>
          <th>Bulan</th>
          <th>Pendapatan</th>
          <th>Status</th>
        </tr>
        <tr>
          <td>Mei</td>
          <td>Rp. 15.000</td>
          <td><span class="w-status-wait" style="float: right">Waiting</span></td>
        </tr>
        <tr>
          <td>Mei</td>
          <td>Rp. 15.000</td>
          <td><span class="w-status-paid" style="float: right">Paid</span></td>
        </tr>
        <tr>
          <td>Mei</td>
          <td>Rp. 15.000</td>
          <td><span class="w-status-wait" style="float: right">Waiting</span></td>
        </tr>
        <tr>
          <td>Mei</td>
          <td>Rp. 15.000</td>
          <td><span class="w-status-paid" style="float: right">Paid</span></td>
        </tr>
      </table>
    </div>
    <div class="wm-showmore"><span @click="redirectHistory">Lihat Semua</span></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedNav: "android",
    };
  },
  methods: {
    changeNav(target) {
      this.selectedNav = target;
    },
    redirectHistory() {
      this.$router.push("/history-earning");
    },
  },
};
</script>

<style scoped>
@import url("./css/monthly.css");
.w-table {
  border-collapse: collapse;
  width: 100%;
  color: #577a95;
  overflow: hidden; /* Mengatasi overflow untuk sudut yang lebih besar */
}

.w-table th {
  text-align: left;
  padding: 15px 25px;
  font-size: 18px;
}

.w-table td {
  background-color: #f0f6ff;
  text-align: left;
  padding: 15px;
}

.w-table tr {
  border-bottom: 1px solid #b6cde9;
}

.w-table tr:first-child {
  border: none;
}

.w-table tr:last-child {
  border: none;
}

.w-table td:first-child {
  font-weight: 600;
}

.w-table th:nth-child(2) {
  text-align: center;
}

.w-table th:nth-child(3) {
  text-align: right;
}

.w-table td:nth-child(2) {
  text-align: center;
}

.w-table td:nth-child(3) {
  text-align: right;
}

.w-table tr:nth-child(2) td:first-child {
  border-top-left-radius: 10px;
}

.w-table tr:nth-child(2) td:last-child {
  border-top-right-radius: 10px;
}

.w-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.w-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
</style>

<template>
  <div class="nav-back-section">
    <div class="nav-edit-chapter">
      <router-link :to="{ name: 'detail-novel', params: { id: novelid } }">
        <img src="@/assets/arrow-back.png" alt="" class="arrow-back" />
      </router-link>
      <p>Content</p>
      <div class="divide">/</div>
      <p>List Karya</p>
      <div class="divide">/</div>
      <p>Novel</p>
      <div class="divide">/</div>
      <p>Detail and Chapters</p>
    </div>
  </div>
  <div class="edit-chapter-section">
    <h1>{{ noveltitle }}</h1>
    <form @submit.prevent="handleUpdate">
      <div class="form-input-chapter">
        <label for="judulChapter">Judul Chapter</label>
        <input type="text" v-model="res_detail.title" id="judulChapter" placeholder="masukkan judul chapter" />
      </div>
      <div class="form-input-chapter">
        <label for="isiChapter">Isi Chapter</label>
        <QuillEditor theme="snow" toolbar="essential" content-type="html" v-model:content="res_detail.desc" placeholder="masukkan teks ..." />
      </div>
      <div class="terms-section">
        <label>
          <input type="checkbox" v-model="isChecked" />
          Saya Menyetujui <span class="span-terms" @click="redirectTerms">Terms and Conditions</span> Klaklik
        </label>
      </div>
      <button type="submit" @click.prevent="openCheckModal" class="btn-chapter-publish">Publish</button>
      <!-- <button v-if="approve === 3" type="submit" @click.prevent="simpanDraft(3)" class="btn-chapter-draft">Simpan ke
                draft</button> -->
    </form>
    <EditScheduleNovel
      :showSchedule="showSchedule"
      :title="noveltitle"
      :chapterTitle="res_detail.title"
      @close="closeModal"
      :simpanChapter="simpanChapter"
    />
    <CheckModal :simpanChapter="simpanChapter" :showCheckModal="showCheckModal" @close="closeModal"></CheckModal>
    <ChapterModal :errorMsg="errorMsg" :show-modal="showModal" @close="closeModal"></ChapterModal>
  </div>
</template>

<script>
import contentController from "@/controllers/content";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ChapterModal from "@/components/novel/ChapterModal.vue";
import CheckModal from "@/components/novel/CheckModal.vue";
import storage from "@/controllers/Localstorage";
import EditScheduleNovel from "@/components/novel/EditScheduleNovel.vue";

import firebaseconfig from "@/config/firebaseconfig";
import analytic from "@/controllers/analytic";
import { logEvent, setUserId } from "firebase/analytics";

export default {
  metaInfo() {
    return {
      title: this.res_detail.title ? `${this.res_detail.title} | Edit Chapter` : "Dasbboard",
    };
  },
  data() {
    return {
      novelid: 0,
      chapterid: 0,
      approve: 0,
      noveltitle: "",
      res_detail: {},
      session: {},
      isChecked: false,
      showModal: false,
      showCheckModal: false,
      showSchedule: false,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.session = storage.getLocalStorage();
    const { data } = await contentController.DetailChapter(id);
    this.approve = data.DATA.novel_content.approve;
    this.novelid = data.DATA.novel_content.novel_id;
    this.chapterid = data.DATA.novel_content.id;

    const resNovel = await contentController.DetailNovel(this.novelid);
    this.noveltitle = resNovel.data.DATA.novel_detail.title;
    this.memberId = resNovel.data.DATA.novel_detail.member_id;

    if (parseInt(this.memberId) !== parseInt(this.session.memberId)) {
      this.$router.push("/");
    }
    this.res_detail = data.DATA.novel_content;

    logEvent(firebaseconfig.analytics, "page_title", {
      app_event_name: analytic.event_name.VIEW_EDIT_CHAPTER_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Edit Chapter Novel",
      page_path: "/edit-chapter",
      firebase_screen_class: "EditChapter",
      screen_class: "EditChapter",
    });

    logEvent(firebaseconfig.analytics, "page_view", {
      app_event_name: analytic.event_name.VIEW_EDIT_CHAPTER_NOVEL,
      section_type: analytic.section_name.DASHBOARD,
      page_title: "Klaklik Dashboard Edit Chapter Novel",
      page_path: "/edit-chapter",
      firebase_screen_class: "EditChapter",
      screen_class: "EditChapter",
    });

    if (localStorage.getItem("memberId") != "") {
      setUserId(firebaseconfig.analytics, localStorage.getItem("memberId"));
    } else {
      setUserId(firebaseconfig.analytics, Vuecookies.get("userklaklik"));
    }
  },
  methods: {
    async simpanDraft(approve) {
      if (this.res_detail.title == "" || this.res_detail.desc == "") {
        this.errorMsg = "Judul chapter atau Deskripsi tidak boleh kosong";
        this.showModal = true;
      } else {
        await contentController.EditChapter(this.chapterid, this.res_detail.title, this.res_detail.desc, approve);
        this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
      }
    },
    async simpanChapter(approve, response) {
      await contentController.EditChapter(this.chapterid, this.res_detail.title, this.res_detail.desc, approve, response);
      this.$router.push({ name: "detail-novel", params: { id: this.novelid } });
    },
    openCheckModal() {
      if (this.approve === 1 || this.approve === 4) {
        if (this.res_detail.title == "" || this.res_detail.desc == "") {
          this.errorMsg = "Deskripsi kurang dari 250 karakter";
          this.showModal = true;
        } else if (this.res_detail.desc.length < 250) {
          this.errorMsg = "Deskripsi kurang dari 250 karakter";
          this.showModal = true;
        } else if (!this.isChecked) {
          this.errorMsg = "Anda harus menyetujui syarat dan ketentuan kami untuk melanjutkan.";
          this.showModal = true;
        } else {
          this.showCheckModal = true;
        }
      } else if (this.approve === 6 || this.approve === 3) {
        if (this.res_detail.title == "" || this.res_detail.desc == "") {
          this.errorMsg = "Judul chapter atau Deskripsi tidak boleh kosong";
          this.showModal = true;
        } else if (this.res_detail.desc.length < 250) {
          this.errorMsg = "Deskripsi kurang dari 250 karakter";
          this.showModal = true;
        } else if (!this.isChecked) {
          this.errorMsg = "Anda harus menyetujui syarat dan ketentuan kami untuk melanjutkan.";
          this.showModal = true;
        } else {
          this.showSchedule = true;
        }
      }
    },
    closeModal() {
      this.showModal = false;
      this.showCheckModal = false;
      this.showSchedule = false;
    },
    redirectTerms() {
      window.open(process.env.VUE_APP_TERMSOFUSE, "_blank");
    },
  },
  components: {
    QuillEditor,
    ChapterModal,
    CheckModal,
    EditScheduleNovel,
  },
};
</script>

<style>
@import url("./css/edit-chapter.css");
</style>
